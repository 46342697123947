import { useState, useEffect } from "react";
import axios from "axios";
import commaNumber from "comma-number";
import Chart from "react-apexcharts";
import $ from "jquery";

const Dashboard = () => {

  var colors = {
    primary: "#6571ff",
    secondary: "#7987a1",
    success: "#05a34a",
    info: "#66d1d1",
    warning: "#fbbc06",
    danger: "#ff3366",
    light: "#e9ecef",
    dark: "#060c17",
    muted: "#7987a1",
    gridBorder: "rgba(77, 138, 240, .15)",
    bodyColor: "#000",
    cardBg: "#fff"
  }

  var fontFamily = "sam"

  const [stats, setStats] = useState({})
  const [opReturn, setOpReturn] = useState({})
  const [seReturn, setSeReturn] = useState([])
  const getStats = async () => {
    const { data } = await axios.get("/statistics");
    setStats(data)
    setOpReturn({
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
        fontSize: "12px",
        fontFamily: fontFamily,
        fontWeight: 400,
      },
      title: {
        text: "ئاماری بەرهەمهاتوو",
        align: "center",
        margin: 20,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontFamily: fontFamily,
          fontWeight: 600,
          fontSize: '12px',
        },
      },
      labels: ["پشکی جوتیار", "پشکی کۆمپانیا", "کۆی گشتی"],
      tooltip: {
        y: {
          formatter: function (val) {
            return `${commaNumber(val)} طن `
          }
        }
      },
      noData: { text: "هیچ ئامارێک نییە..." }
    })
    setSeReturn([data.return.farmer_qty ? data.return.farmer_qty : 0, data.return.company_qty ? data.return.company_qty : 0, data.return.qty ? data.return.qty : 0])

  };

  const [opRturnLog, setOpRturnLog] = useState({})
  const [seRturnLog, setSeRturnLog] = useState([])
  const getRturnLog = async () => {
    const { data } = await axios.get("/return/logs");
    setOpRturnLog({
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight"
      },
      title: {
        style: {
          fontFamily: fontFamily,
          fontWeight: 600,
          fontSize: '12px',
        },
        text: 'هێلکاریی بەرهەمهێنان',
        align: 'center'
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100]
        }
      },
      xaxis: {
        categories: data.map((item) => new Date(item.created ? item.created : 0).toLocaleDateString('ar-en', { month: 'short', day: 'numeric' })),
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return `${commaNumber(val)} طن `
          }
        }
      },
      noData: { text: "هیچ ئامارێک نییە..." }
    })
    setSeRturnLog([{
      name: 'بەرهەمی وەرگیراو',
      data: data.map((item) => item.qty ? item.qty : 0)
    }])
  }

  const [option, setOption] = useState({})
  const [series, setSeries] = useState([])
  const [auth, setAuth] = useState(true);
  useEffect(() => {
    axios.get("/auth").then((res) => {
      if (res.data !== "err") {
        if (res.data == "چاودێر") {
          $(".auth").remove();
          setAuth(false);
        } else {
          setAuth(true);
        }
      }
    });
    getStats();
    getRturnLog();
    setSeries([{
      name: '',
      data: [3844, 3855, 3841, 3867, 3822, 3843, 3821, 3841, 3856, 3827, 3843]
    }])
    setOption({
      chart: {
        type: "line",
        sparkline: {
          enabled: !0
        },
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
      },
      xaxis: {
        type: 'datetime',
        categories: ["Jan 01 2022", "Jan 02 2022", "Jan 03 2022", "Jan 04 2022", "Jan 05 2022", "Jan 06 2022", "Jan 07 2022", "Jan 08 2022", "Jan 09 2022", "Jan 10 2022", "Jan 11 2022",],
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
      },
      yaxis: {
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
      },

      grid: {
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      stroke: {
        width: 2,
        curve: "smooth"
      },
      markers: {
        size: 0
      },
      colors: [colors.primary],
    }
    )
  }, []);

  return (
    auth ? (
      <div className="page-content">
        <div className="row">
          <div className="col-12 col-xl-12 stretch-card">
            <div className="row flex-grow-1">
              <div className="col-md-4 grid-margin stretch-card" style={{ 'height': '155px' }}>
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-baseline">
                      <h6 className="card-title mb-0">هاوردەکردن</h6>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-12 col-xl-5">
                        <h4 className="mb-2 m-1">{stats.order ? commaNumber(stats.order.qty) : 0} طن</h4>
                        <div className="d-flex align-items-baseline">
                          <p className="text-success">
                            <span>{stats.order ? commaNumber(stats.order.total) : 0} $</span>
                          </p>
                        </div>
                      </div>
                      <div className="col-6 col-md-12 col-xl-7">
                        <Chart options={option} series={series} height={75} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 grid-margin stretch-card" style={{ 'height': '155px' }}>
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-baseline">
                      <h6 className="card-title mb-0">فرۆشتن</h6>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-12 col-xl-5">
                        <h4 className="mb-2 m-1">{stats.sale ? commaNumber(stats.sale.qty) : 0} طن</h4>
                        <div className="d-flex align-items-baseline">
                          <p className="text-success">
                            <span>{stats.sale ? commaNumber(stats.sale.total) : 0} $</span>
                          </p>
                        </div>
                      </div>
                      <div className="col-6 col-md-12 col-xl-7">
                        <Chart options={option} series={series} height={75} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 grid-margin stretch-card" style={{ 'height': '155px' }}>
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-baseline">
                      <h6 className="card-title mb-0">چاندن</h6>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-12 col-xl-5">
                        <h4 className="mb-2 m-1">{stats.production ? commaNumber(stats.production.qty) : 0} طن</h4>
                        <div className="d-flex align-items-baseline">
                          <p className="text-success">
                            <span>{stats.production ? commaNumber(stats.production.total) : 0} $</span>
                          </p>
                        </div>
                      </div>
                      <div className="col-6 col-md-12 col-xl-7">
                        <Chart options={option} series={series} height={75} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 grid-margin stretch-card" style={{ 'height': '155px' }}>
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-baseline">
                      <h6 className="card-title mb-0">وەرگرتنەوەی بەرهەم</h6>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-12 col-xl-5">
                        <h4 className="mb-2 m-1">{stats.return ? commaNumber(stats.return.qty) : 0} طن</h4>
                      </div>
                      <div className="col-6 col-md-12 col-xl-7">
                        <Chart options={option} series={series} height={75} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 grid-margin stretch-card" style={{ 'height': '155px' }}>
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-baseline">
                      <h6 className="card-title mb-0">تۆی بەردەست</h6>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-12 col-xl-5">
                        <h4 className="mb-2 m-1">{stats.tom ? commaNumber(stats.tom.qty) : 0} طن</h4>
                        <div className="d-flex align-items-baseline">
                        </div>
                      </div>
                      <div className="col-6 col-md-12 col-xl-7">
                        <Chart options={option} series={series} height={75} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 grid-margin stretch-card" style={{ 'height': '155px' }}>
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-baseline">
                      <h6 className="card-title mb-0">قەرزی کۆمپانیا</h6>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-12 col-xl-5">
                        <h4 className="mb-2 m-1">{stats.order ? commaNumber(stats.order.amount_unpaid) : 0} $</h4>
                        <div className="d-flex align-items-baseline">
                          <p className="text-success">
                            <span>لەکۆی {stats.order ? commaNumber(stats.order.total) : 0} $ </span>
                          </p>
                        </div>
                      </div>
                      <div className="col-6 col-md-12 col-xl-7">
                        <Chart options={option} series={series} height={75} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 grid-margin stretch-card" style={{ 'height': '155px' }}>
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-baseline">
                      <h6 className="card-title mb-0">قەرزی جوتیاران</h6>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-12 col-xl-5">
                        <h4 className="mb-2 m-1">{stats.sale ? commaNumber(stats.sale.amount_unpaid + stats.production.amount_unpaid) : 0} $</h4>
                        <div className="d-flex align-items-baseline">
                          <p className="text-success">
                            <span>لەکۆی {stats.sale ? commaNumber(stats.sale.total + stats.production.total) : 0} $</span>
                          </p>
                        </div>
                      </div>
                      <div className="col-6 col-md-12 col-xl-7">
                        <Chart options={option} series={series} height={75} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 grid-margin stretch-card" style={{ 'height': '155px' }}>
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-baseline">
                      <h6 className="card-title mb-0">ژمارەی کۆگاکان</h6>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-12 col-xl-5">
                        <h4 className="mb-2 m-1">{stats.store ? commaNumber(stats.store.count) : 0}</h4>
                        <div className="d-flex align-items-baseline">
                          <p className="text-success">
                            <span>کۆی کەرتەکان ({stats.section ? commaNumber(stats.section.count) : 0})</span>
                          </p>
                        </div>
                      </div>
                      <div className="col-6 col-md-12 col-xl-7">
                        <Chart options={option} series={series} height={75} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="card">
              <div className="card-body">
                <Chart options={opRturnLog} series={seRturnLog} type='area' height={330} />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <Chart options={opReturn} series={seReturn} type="pie" />
              </div>
            </div>
          </div>
        </div>
      </div>) : (<div className="page-content">
        <div className="d-flex justify-content-between align-items-center flex-wrap grid-margin">
          <div>
            <h4 className="mb-3 mb-md-0">  بەخێربێیت چاودێری بەڕێز بۆ بەڕێوەبردنی جوتیار!</h4>
          </div>
        </div>
      </div>)

  );
}

export default Dashboard;
