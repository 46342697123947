import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import $ from "jquery";
import Swal from "sweetalert2";

const Price = () => {

    const [points, setPoints] = useState([]);
    const getPoints = async () => {
        const { data } = await axios.get("/point");
        setPoints(data);
    };

    const [items, setItems] = useState([]);
    const getItems = async () => {
        const { data } = await axios.get("/item");
        setItems(data);
    };

    const [price, setPrice] = useState([]);
    const getPrice = async () => {
        const { data } = await axios.get("/price");
        setPrice(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [1]
                                },
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [2]
                                },
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [3]
                                },
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [4]
                                },
                                {
                                    searchPanes: {
                                        show: false
                                    },
                                    targets: [5]
                                },
                            ]
                        })
                        $('div.dataTables_wrapper div.dataTables_filter').css({
                            'float': 'left',
                            'position': 'relative',
                            'top': '-15px'
                        })
                        $('div.dataTables_wrapper div.dataTables_length label').css({
                            'position': 'relative',
                            'top': '20px'
                        })
                    }
                    var api = window.$('.dt-tbl').DataTable();
                    new window.$.fn.dataTable.FixedHeader(api);
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    };

    useEffect(() => {
        getItems();
        getPrice();
        getPoints();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);
    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">دەستپێک</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">نرخەکان</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title"> نرخەکان</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-light mx-2" type="button" data-bs-toggle="modal" data-bs-target="#pointSetting">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2" /><polyline points="2 17 12 22 22 17" /><polyline points="2 12 12 17 22 12" /></svg>
                                        <span className="ms-2">دەرەجەی پەتاتە</span>
                                    </button>
                                    <div className="modal fade" id="pointSetting" tabIndex={-1} aria-labelledby="pointSetting" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">زیادکردنی درجە </h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                </div>
                                                <form className="form-sample">
                                                    <div className="modal-body">
                                                        <div className="mb-3">
                                                            <table className="table table-striped table-sm">
                                                                <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>دەرجە</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {points && points.map((item, index) => (
                                                                        <tr key={item.id}>
                                                                            <td>{index + 1}</td>
                                                                            <td>
                                                                                <gname onDoubleClick={(e) => { $(e.target).hide(); $(e.target).next().show(); }} data-bs-toggle="tooltip" data-bs-placement="top" title="دەبل کلیک بۆ دەستکاریکردن"> {item.item_point} </gname>
                                                                                <ginput style={{ display: "none" }}>
                                                                                    <input type="text" className="form-control" defaultValue={item.item_point}
                                                                                        onKeyPress={(e) => {
                                                                                            if (e.key === 'Enter') {
                                                                                                e.preventDefault();
                                                                                                axios.put(`/point/${item.id}`, { item_point: e.target.value }).then(res => {
                                                                                                    if (res.data !== 'done') {
                                                                                                        toast.error(res.data, {
                                                                                                            position: "top-left",
                                                                                                            autoClose: 3000,
                                                                                                            hideProgressBar: false,
                                                                                                            closeOnClick: true,
                                                                                                            pauseOnHover: true,
                                                                                                            draggable: true,
                                                                                                            progress: undefined,
                                                                                                        });
                                                                                                    } else {
                                                                                                        $(e.target).parent().hide();
                                                                                                        $(e.target).parent().prev().show();
                                                                                                        getPoints([]);
                                                                                                        getPoints();
                                                                                                    }
                                                                                                });
                                                                                            }
                                                                                        }} data-bs-toggle="tooltip" data-bs-placement="top" title="ئینتەر داگرە بۆ پاشەکەوت کردن" />
                                                                                </ginput>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="row mt-5">
                                                            <div className="mb-3">
                                                                <label htmlFor="item_point" className="form-label">دەرەجەیەکی نوێ</label>
                                                                <input type="text" className="form-control" id="item_point" name="item_point" placeholder="دەرەجە" />
                                                            </div>
                                                            <div className="mb-3">
                                                                <button type="button" className="btn btn-primary" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    axios.post('/point', { item_point: $('#item_point').val() }).then(res => {
                                                                        if (res.data !== 'done') {
                                                                            toast.error(res.data, {
                                                                                position: "top-left",
                                                                                autoClose: 3000,
                                                                                hideProgressBar: false,
                                                                                closeOnClick: true,
                                                                                pauseOnHover: true,
                                                                                draggable: true,
                                                                                progress: undefined,
                                                                            });
                                                                        } else {
                                                                            getPoints([]);
                                                                            getPoints();
                                                                        }
                                                                    });
                                                                }}>
                                                                    زیادکردن
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#new">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus"><line x1={12} y1={5} x2={12} y2={19} /><line x1={5} y1={12} x2={19} y2={12} /></svg>
                                        <span className="ms-2">نرخێکی نوێ</span>
                                    </button>
                                    <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">س
                                                    <h5 className="modal-title"> نرخێکی نوێ </h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                </div>
                                                <form className="form-sample" onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post(`/price`, data).then(res => {
                                                        if (res.data !== 'done') {
                                                            toast.error(res.data, {
                                                                position: "top-left",
                                                                autoClose: 3000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true,
                                                                progress: undefined,
                                                            });
                                                        } else {
                                                            getPrice();
                                                            e.target.reset();
                                                            window.$('#new').modal('hide');
                                                        }
                                                    });
                                                }}>
                                                    <div className="modal-body">
                                                        <div className="mb-3">
                                                            <label htmlFor="item_list_id" className="form-label">جۆری پەتاتە <span className="text-danger">*</span></label>
                                                            <select name="item_list_id" className="form-select" required>
                                                                <option value="">.......</option>
                                                                {items.map((item, index) => (
                                                                    <option key={index} value={item.id}>{item.item_name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="item_point_id" className="form-label">دەرەجە <span className="text-danger">*</span></label>
                                                            <select name="item_point_id" className="form-select" required>
                                                                <option value="">......</option>
                                                                {points.map((item, index) => (
                                                                    <option key={index} value={item.id}>{item.item_point}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="return_price" className="form-label"> نرخی وەرگرتن <span className="text-danger">*</span></label>
                                                            <div className="input-group flex-nowrap">
                                                                <span className="input-group-text" id="addon-wrapping"><span className="mx-1">1 کگم</span></span>
                                                                <input type="number" name="return_price" className="form-control" placeholder="0.00" required />
                                                                <span className="input-group-text" id="addon-wrapping"><span className="mx-1">د.ع</span></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                        <button type="submit" className="btn btn-primary"> زیادکردن</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>بەرهەم</th>
                                        <th>جۆر</th>
                                        <th>دەرەجە</th>
                                        <th>نرخی وەرگرتن</th>
                                        <th>تۆمارکردن</th>
                                        <th>بژاردە</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {price && price.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td>{item.item_name}</td>
                                            <th>خشن</th>
                                            <td>{item.item_point == 0 ? '-' : item.item_point}</td>
                                            <td>{item.return_price} {item.currency}</td>
                                            <td>{new Date(item.created).toLocaleDateString()}</td>
                                            <td>
                                                <span type="button" className="badge bg-warning mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                                                </span>
                                                <span type="button" className="badge bg-danger"
                                                    onClick={() => {
                                                        Swal.fire({
                                                            title: 'ئاگاداری!',
                                                            text: "ئایا دڵنیایت لە سڕینەوە ؟",
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#3085d6',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: 'بەڵێ',
                                                            cancelButtonText: 'نەخێر'
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                axios.delete(`/price/${item.id}`).then(res => {
                                                                    res.data === 'done' ? getPrice() : toast.error(res.data);
                                                                })
                                                            }
                                                        })
                                                    }} >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash"><polyline points="3 6 5 6 21 6" /><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" /></svg>
                                                </span>
                                            </td>
                                            <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-lg">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title"> دەستکاریکردنی کۆگا </h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                        </div>
                                                        <form className="form-sample" onSubmit={(e) => {
                                                            e.preventDefault();
                                                            const formData = new FormData(e.target);
                                                            const data = Object.fromEntries(formData);
                                                            axios.put(`/price/${item.id}`, data).then(res => {
                                                                if (res.data !== 'done') {
                                                                    toast.error(res.data, {
                                                                        position: "top-left",
                                                                        autoClose: 3000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                    });
                                                                } else {
                                                                    getPrice();
                                                                    e.target.reset();
                                                                    window.$(`#edit${item.id}`).modal('hide');
                                                                }
                                                            });
                                                        }}>
                                                            <div className="modal-body">
                                                                <div className="mb-3">
                                                                    <label htmlFor="item_list_id" className="form-label">جۆری پەتاتە <span className="text-danger">*</span></label>
                                                                    <select name="item_list_id" className="form-select" defaultValue={item.item_id} required>
                                                                        {items.map((obj, x) => (
                                                                            <option key={x} value={obj.id}>{obj.item_name}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="item_point_id" className="form-label">دەرەجە <span className="text-danger">*</span></label>
                                                                    <select name="item_point_id" className="form-select" defaultValue={item.item_point_id} required>
                                                                        {points.map((obj, x) => (
                                                                            <option key={x} value={obj.id}>{obj.item_point}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="return_price" className="form-label"> نرخی وەرگرتن <span className="text-danger">*</span></label>
                                                                    <div className="input-group flex-nowrap">
                                                                        <span className="input-group-text" id="addon-wrapping"><span className="mx-1">1 کگم</span></span>
                                                                        <input type="number" name="return_price" className="form-control" defaultValue={item.return_price} required />
                                                                        <span className="input-group-text" id="addon-wrapping"><span className="mx-1">د.ع</span></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                                <button type="submit" className="btn btn-primary"> نوێکردنەوە</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Price;