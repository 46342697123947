import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import commaNumber from "comma-number";

const Expenses = () => {

    const [expenses, setoutcome] = useState([]);
    const getExpenses = async () => {
        const { data } = await axios.get("/expenses");
        setoutcome(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                        });
                    }
                    var api = window.$('.dt-tbl').DataTable();
                    new window.$.fn.dataTable.FixedHeader(api);
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    };

    const [account, setaccounts] = useState([]);
    const getAccounts = async () => {
        const res = await axios.get("/account");
        setaccounts(res.data);
    };

    const [currency, setcurrency] = useState([]);
    const getCurrency = async () => {
        const res = await axios.get("/currency");
        setcurrency(res.data);
    };


    const upload = () => {
        $('<input type="file" accept="image/*" />').click().on('change', async (e) => {
            var formData = new FormData();
            formData.append('file', e.target.files[0]);
            await axios.post('/upload', formData).then(res => {
                if (res.data !== "err") {
                    $("#attachment").val(res.data);
                    $(`#upattachment`).append(`<img src="../../../${res.data}" class="viewImg" alt=${res.data} />`)
                }
                if (res.data === "limited") {
                    toast.error('قەبارەی رسمەکە زۆر گەورەیە پێویستە لە 700 کێلۆ بایت کەمتربێت!')
                    return
                }
                $('.viewImg').click(function () {
                    $(this).remove()
                    axios.delete(`/upload/${$(this).attr('alt')}`).then(res => {
                        if (res.data !== "err") {
                            $("#attachment").val('');
                        } else {
                            toast.error('هەڵەیەک هەیە');
                        }
                    })
                })
            })
        })
    }


    const uploadModify = (id) => {
        $(`.viewImg_${id}`).remove()
        $('<input type="file"  />').click().on('change', async (e) => {
            var formData = new FormData();
            formData.append('file', e.target.files[0]);
            await axios.post('/upload', formData).then(res => {
                if (res.data !== "err") {
                    $(`#attachment_${id}`).val(res.data);
                    $(`#upattachment_${id}`).append(`<img src="../../../${res.data}" class="img-thumbnail viewImg" alt=${res.data} />`)
                }
                if (res.data === "limited") {
                    toast.error('قەبارەی رسمەکە زۆر گەورەیە پێویستە لە 700 کێلۆ بایت کەمتربێت!')
                    return
                }
                $(`.viewImg_${id}`).click(function () {
                    $(this).remove()
                    axios.delete(`/upload/${$(this).attr('alt')}`).then(res => {
                        if (res.data !== "err") {
                            $("#attachment").val('');
                        } else {
                            toast.error('هەڵەیەک هەیە');
                        }
                    })
                })
            })
        })
    }

    useEffect(() => {
        getExpenses();
        getAccounts();
        getCurrency();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">ژمێریاری</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">خەرجیە گشتیەکان</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">خەرجیە گشتیەکان</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#newItem">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus"><line x1={12} y1={5} x2={12} y2={19} /><line x1={5} y1={12} x2={19} y2={12} /></svg>
                                        <span className="ms-2">زیادکردنی خەرجیە گشتیەکان</span>
                                    </button>
                                    <div className="modal fade" id="newItem" tabIndex={-1} aria-labelledby="newItem" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title"> </h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                </div>
                                                <form className="form-sample" onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post('/expenses', data).then(res => {
                                                        if (res.data !== 'done') {
                                                            toast.error(res.data, {
                                                                position: "top-left",
                                                                autoClose: 3000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true,
                                                                progress: undefined,
                                                            });
                                                        } else {
                                                            e.target.reset();
                                                            getExpenses();
                                                        }
                                                    });
                                                }}>
                                                    <div className="modal-body">
                                                        <div className="row mb-3">
                                                            <div className="col-md-12">
                                                                <label htmlFor="code" className="form-label">تایتڵ</label>
                                                                <input type="text" className="form-control" name="title" placeholder="تایتڵ" />
                                                            </div>
                                                        </div>

                                                        <div className="row mb-3">
                                                            <div className="col-md-12">
                                                                <label htmlFor="code" className="form-label">ڕوونکردنەوە</label>
                                                                <input type="text" className="form-control" name="description" placeholder="ڕوونکردنەوە" />
                                                            </div>
                                                        </div>

                                                        <div className="row mb-3">
                                                            <div className="col-md-12">
                                                                <label htmlFor="code" className="form-label">کۆی خەرجی</label>
                                                                <input type="text" className="form-control" name="amount" placeholder="کۆی خەرجی" />
                                                            </div>
                                                        </div>

                                                        <div className="row mb-3">
                                                            <div className="col-md-12">
                                                                <label htmlFor="name_ku" className="form-label">هەژمارەکان</label>
                                                                <select className="form-select" name="account_id">
                                                                    {account.map((accounts, index) => (
                                                                        <option value={accounts.id}>{accounts.account_name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="col-md-12">
                                                                <label htmlFor="name_ku" className="form-label">دراو</label>
                                                                <select className="form-select" name="currency_id" id="currency_id">
                                                                    {currency.map((currency, index) => (
                                                                        <option value={currency.id}>{currency.currency}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label> هاوپێچ</label>
                                                            <input type="hidden" name="attachment" id="attachment" />
                                                            <div className="form-control text-center" onClick={upload}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                                                            </div>
                                                            <br />
                                                            <div align="center" id="upattachment"></div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                        <button type="submit" className="btn btn-primary" data-bs-dismiss="modal"> زیادکردن</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>تایتڵ</th>
                                        <th>ڕوونکردنەوە</th>
                                        <th>کۆی پارە</th>
                                        <th>هەژمار</th>
                                        <th>هاوپێچ</th>
                                        <th>بەکارهێنەر</th>
                                        <th>بەروار</th>
                                        <th>بژاردە</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {expenses && expenses.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.id}</td>
                                            <td>{item.title}</td>
                                            <td>
                                                <div className="text-wrap" style={{ maxWidth: "300px" }}>
                                                    {item.description ? item.description : '-'}
                                                </div>
                                            </td>
                                            <td>{commaNumber(item.amount)} {item.currency}</td>
                                            <td>{item.account_name}</td>
                                            <td>{
                                                item.attachment ? (
                                                    <a href={`../../../${item.attachment}`} target="_blank">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="text-dark feather feather-paperclip"><path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" /></svg>
                                                    </a>
                                                ) : (
                                                    <span className="badge bg-danger">نییە</span>
                                                )
                                            }
                                            </td>
                                            <td>{item.accountant_name}</td>
                                            <td>{new Date(item.created).toLocaleDateString()}</td>
                                            <td>
                                                <span type="button" className="badge bg-warning" data-bs-toggle="modal" data-bs-target={`#updateproject${item.id}`}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                                                </span>
                                            </td>
                                            <div className="modal fade" id={`updateproject${item.id}`} tabIndex={-1} aria-labelledby="updateproject" aria-hidden="true">
                                                <div className="modal-dialog modal-lg">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title"> دەستکاریکردنی پرۆژە </h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                        </div>
                                                        <form class='form-sample'
                                                            onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                const data = Object.fromEntries(formData);
                                                                axios.put(`/expenses/${item.id}`, data).then(res => {
                                                                    if (res.data !== 'done') {
                                                                        toast.error(res.data, {
                                                                            position: "top-left",
                                                                            autoClose: 3000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                        });
                                                                    } else {
                                                                        e.target.reset();
                                                                        getExpenses();
                                                                    }
                                                                });
                                                            }}
                                                        >
                                                            <div className="modal-body">

                                                                <div className="row mb-3">
                                                                    <div className="col-md-12">
                                                                        <label htmlFor="code" className="form-label">تایتڵ</label>
                                                                        <input type="text" className="form-control" defaultValue={item.title} name="title" placeholder="تایتڵ" />
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-3">
                                                                    <div className="col-md-12">
                                                                        <label htmlFor="code" className="form-label">ڕوونکردنەوە</label>
                                                                        <input type="text" className="form-control" defaultValue={item.description} name="description" placeholder="ڕوونکردنەوە" />
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-3">
                                                                    <div className="col-md-12">
                                                                        <label htmlFor="code" className="form-label">کۆی خەرجی</label>
                                                                        <input type="text" className="form-control" defaultValue={item.amount} name="amount" placeholder="کۆی خەرجی" />
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-3">
                                                                    <div className="col-md-12">
                                                                        <label htmlFor="name_ku" className="form-label">هەژمارەکان</label>
                                                                        <select className="form-select" name="account_id" defaultValue={item.account_id} id="account_id">
                                                                            {account.map((accounts) => (
                                                                                <option value={accounts.id}>{accounts.account_name}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-3">
                                                                    <div className="col-md-12">
                                                                        <label htmlFor="name_ku" className="form-label">دراوو</label>
                                                                        <select className="form-select" defaultValue={item.currency_id} name="currency_id" id="currency_id">
                                                                            {currency.map((currency) => (
                                                                                <option value={currency.id}>{currency.currency}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label> Image</label>
                                                                    <input type="hidden" name="attachment" id={`attachment_${item.id}`} />
                                                                    <div className="form-control text-center" onClick={() => { uploadModify(item.id) }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                                                                    </div>
                                                                    <br />
                                                                    <div align="center" id={`upattachment_${item.id}`}>
                                                                        <img className={`img-thumbnail viewImg_${item.id}`} src={item.attachment} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                                <button type="submit" className="btn btn-primary" data-bs-dismiss="modal"> نوێکردنەوە</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Expenses;