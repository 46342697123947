import React, { useEffect, useState } from "react";
import axios from "axios";
import commaNumber from 'comma-number';
import $ from "jquery";
import { Link, useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Select from 'react-select'

const EditProduction = () => {
    const navigate = useNavigate();
    const production_id = useParams().id

    const [productionHeader, setProductionHeader] = useState(0);
    const [sections, setSections] = useState([]);
    const getProductionHeader = async () => {
        const { data } = await axios.get(`/production/header/${production_id}`);
        setProductionHeader(data[0]);
        axios.get(`/section/${data[0].store_id}`).then((res) => {
            setSections(res.data);
        });
    };

    const [productionContent, setProductionContent] = useState([]);
    const getProductionContent = async () => {
        const res = await axios.get(`/production/content/${production_id}`);
        setProductionContent(res.data);
    };

    const [productionTotal, setProductionTotal] = useState(0);
    const getProductionTotal = async () => {
        const res = await axios.get(`/production/total/${production_id}`);
        setProductionTotal(res.data[0]);
    };

    const [items, setItems] = useState([]);

    useEffect(() => {
        getProductionTotal();
        getProductionHeader();
        getProductionContent();
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">دەستپێک</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">دەستکاریکردن</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="container-fluid mt-3">
                                <div className="row">
                                    <div className="col-xl-7">
                                        <div className="form-group">
                                            <label htmlFor="customer_name"><strong>جوتیار: </strong>{productionHeader.farmer_name}</label> <br />
                                            <label htmlFor="customer_address"><strong>ژ.مۆبایل: </strong> {productionHeader.farmer_phone}</label> <br />
                                            <label htmlFor="customer_address"><strong>لە کۆگای: </strong> {productionHeader.store_name}، لە {productionHeader.store_address}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid mt-4">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="table-responsive">
                                            <table className="table table-striped table-sm text-center">
                                                <thead>
                                                    <tr>
                                                        <th>جۆر</th>
                                                        <th>قەبارە</th>
                                                        <th>نرخ</th>
                                                        <th>پێوانە</th>
                                                        <th>بڕ</th>
                                                        <th>پشکی کۆمپانیا</th>
                                                        <th>پشکی جوتیار</th>
                                                        <th>بژاردە</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {productionContent.map((item, index) => (
                                                        <tr key={index}>
                                                            <td> <p className="mt-3">{item.item_name}</p> </td>
                                                            <td> <p className="mt-3">{item.item_size}</p> </td>
                                                            <td>
                                                                <div className="input-group form-control-sm flex-nowrap">
                                                                    <input type="number" className="form-control" name="price" min={0} step="any" defaultValue={item.price} required onChange={(e) => {
                                                                        if (e.target.value == "" || e.target.value < 0) {
                                                                            e.target.value = 0;
                                                                        }
                                                                        axios.put(`/production/content/${item.id}`, { price: e.target.value }).then(() => {
                                                                            getProductionContent();
                                                                            getProductionTotal();
                                                                        });
                                                                    }} />
                                                                    <span className="input-group-text" id="addon-wrapping"><span className="mx-1">{productionHeader.currency}</span></span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <select className="form-select form-select-sm wpx-85 " name="weight_type" onChange={(e) => {
                                                                    axios.put(`/production/content/${item.id}`, { weight_type: e.target.value }).then(() => {
                                                                        getProductionContent();
                                                                        getProductionTotal();
                                                                    });
                                                                }} required>
                                                                    <option value="ton">طن</option>
                                                                    <option value="kg">کیلۆ</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <div className="input-group form-control-sm flex-nowrap">
                                                                    <input type="number" className="form-control" min={0} step="any" name="qty" defaultValue={item.qty} onChange={(e) => {
                                                                        if (e.target.value == "" || e.target.value < 0) {
                                                                            e.target.value = 0;
                                                                        }
                                                                        axios.put(`/production/content/${item.id}?item_id=${item.item_id}&item_size=${item.item_size}&store_id=${item.store_id}&section_id=${item.section_id}`, { qty: e.target.value }).then((res) => {
                                                                            if (res.data !== "done") {
                                                                                toast.warning(res.data);
                                                                                e.target.value = 0;
                                                                            }
                                                                            getProductionContent();
                                                                            getProductionTotal();
                                                                        });
                                                                    }} />
                                                                    <span className="input-group-text" id="addon-wrapping"><span className="mx-1">طن</span></span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="input-group form-control-sm flex-nowrap">
                                                                    <span className="input-group-text" id="addon-wrapping"><span className="mx-1">%</span></span>
                                                                    <input type="number" className="form-control" id="crate" name="company_rate" defaultValue={item.company_rate} required onChange={(e) => {
                                                                        if (e.target.value == "" || e.target.value < 0) {
                                                                            e.target.value = 0;
                                                                        }
                                                                        $('#frate').val(100 - e.target.value);
                                                                        axios.put(`/production/content/${item.id}`, { company_rate: e.target.value, farmer_rate: 100 - e.target.value }).then(() => {
                                                                            getProductionContent();
                                                                            getProductionTotal();
                                                                        });
                                                                    }} />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="input-group form-control-sm flex-nowrap">
                                                                    <span className="input-group-text" id="addon-wrapping"><span className="mx-1">%</span></span>
                                                                    <input type="number" className="form-control" id="frate" name="farmer_rate" defaultValue={item.farmer_rate} required onChange={(e) => {
                                                                        if (e.target.value == "" || e.target.value < 0) {
                                                                            e.target.value = 0;
                                                                        }
                                                                        $('#crate').val(100 - e.target.value);
                                                                        axios.put(`/production/content/${item.id}`, { farmer_rate: e.target.value, company_rate: 100 - e.target.value }).then(() => {
                                                                            getProductionContent();
                                                                            getProductionTotal();
                                                                        });
                                                                    }} />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <span type="button" className="badge bg-danger mt-2" onClick={() => {
                                                                    Swal.fire({
                                                                        title: 'ئاگاداری',
                                                                        text: "ئایا دڵنیایت لە سڕینەوە ؟",
                                                                        icon: 'warning',
                                                                        showCancelButton: true,
                                                                        confirmButtonColor: '#3085d6',
                                                                        cancelButtonColor: '#d33',
                                                                        confirmButtonText: 'بەڵێ',
                                                                        cancelButtonText: 'نەخێر'
                                                                    }).then((result) => {
                                                                        if (result.isConfirmed) {
                                                                            axios.delete(`/production/content/${item.id}`).then(() => {
                                                                                Swal.fire({
                                                                                    title: 'سڕایەوە',
                                                                                    text: "بەسەرکەوتویی سڕایەوە",
                                                                                    icon: 'success',
                                                                                    showConfirmButton: false,
                                                                                    timer: 1000
                                                                                }).then(() => {
                                                                                    getProductionContent();
                                                                                    getProductionTotal();
                                                                                })
                                                                            })
                                                                        }
                                                                    })
                                                                }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash"><polyline points="3 6 5 6 21 6" /><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" /></svg>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <h5 className="card-title">دیارکردنی پەتاتە</h5>
                                        <div className="input-group mb-3">
                                            <div className="col-12">
                                                <select className="form-select" id="section" onChange={(e) => {
                                                    axios.get(`/items/section/${e.target.value}`).then(res => {
                                                        if (res.data.length > 0) {
                                                            setItems(res.data);
                                                        } else {
                                                            toast.warning('ئەم کەرتە بەتالە');
                                                        }
                                                    })
                                                }} required>
                                                    <option value="">کەرت دیاری بکە</option>
                                                    {sections.map(section => (
                                                        <option key={section.id} value={section.id}>{section.section_name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="input-group mb-3">
                                            <div className="col-12">
                                                <Select options={items && items.map((item) => ({ value: item.item_id, size: item.item_size, label: `${item.item_name} - ${item.item_size}` }))}
                                                    onChange={(e) => {
                                                        if (productionContent.length > 0) {
                                                            toast.warning('بۆ هەر فاتورەیەک تەنها یەک جۆرە پەتاتە دەتوانرێت دیارکرێت', { width: '300px' })
                                                            return;
                                                        }
                                                        $('#section').val() == 0 ? toast.warning('کەرت دیاری بکە', { position: "top-center" }) :
                                                            axios.post('/production/content', {
                                                                production_header_id: production_id,
                                                                section_id: $('#section').val(),
                                                                item_id: e.value,
                                                                item_size: e.size
                                                            }).then((res) => {
                                                                if (res.data !== 'done') {
                                                                    res.data !== "exist" ? toast.error(res.data) : toast.warning('پێشتر هاوردەکراوە');
                                                                } else {
                                                                    getProductionContent();
                                                                    getProductionTotal();
                                                                }
                                                            });
                                                    }} placeholder="لیست هەڵبژێرە" required />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid mt-5">
                                <form className="form-sample" onSubmit={(e) => {
                                    e.preventDefault();
                                    axios.put(`/production/submit/${production_id}`, { note: e.target.note.value }).then(res => {
                                        localStorage.removeItem("production");
                                        if (res.data !== 'done') {
                                            toast.error(res.data, {
                                                position: "top-left",
                                                autoClose: 3000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                            });
                                        } else {
                                            navigate('/production/list');
                                        }
                                    });
                                }}>
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="note">تێبینی</label>
                                                <textarea className="form-control" id="note" name="note" rows={3} defaultValue={productionHeader.note} placeholder="تێبینی ..." />
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td>بڕ</td>
                                                            <td className="text-end">{productionTotal ? commaNumber(productionTotal.qty) : 0} طن</td>
                                                        </tr>
                                                        <tr>
                                                            <td>نرخ</td>
                                                            <td className="text-end">
                                                                <span className="mx-1">{productionTotal ? productionTotal.price : 0} {productionHeader.currency}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-bold-800">سەرجەم</td>
                                                            <td className="text-bold-800 text-end">
                                                                {productionTotal ? commaNumber(productionTotal.total) : 0}  <span className="mx-1">{productionHeader.currency}</span>
                                                            </td>
                                                        </tr>
                                                        <tr className="bg-dark text-white">
                                                            <td className="text-bold-800">کۆی گشتی (داشکاندن)</td>
                                                            <td className="text-bold-800 text-end">
                                                                <span className="mx-1">{productionHeader.currency}</span>
                                                                <span id="total_amount_discount">{productionTotal ? commaNumber(productionTotal.total) : 0}</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-xl-12">
                                            <div className="mb-3 float-end">
                                                <button type="button" className="btn btn-danger"
                                                    onClick={() => {
                                                        Swal.fire({
                                                            title: 'ئایا دڵنیایت؟',
                                                            text: "بەیەکجاری دەسڕێتەوە و ناتوانرێت بگەڕێندرێتەوە !",
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#3085d6',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: 'بەڵێ',
                                                            cancelButtonText: 'نەخێر'
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                axios.delete(`/production/submit/${production_id}`).then(res => {
                                                                    if (res.data !== 'done') {
                                                                        toast.error(res.data, {
                                                                            position: "top-left",
                                                                            autoClose: 3000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                        });
                                                                    } else {
                                                                        navigate('/production/list');
                                                                    }
                                                                });
                                                            }
                                                        })
                                                    }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1={18} y1={6} x2={6} y2={18} /><line x1={6} y1={6} x2={18} y2={18} /></svg>
                                                    <span className="mx-2">سڕینەوە</span>
                                                </button>
                                                <button type="submit" className="btn btn-primary mx-2" >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" /><polyline points="7 10 12 15 17 10" /><line x1={12} y1={15} x2={12} y2={3} /></svg>
                                                    <span className="mx-2">نوێکردنەوە</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </div >
    );
}

export default EditProduction;