import React, { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const navigate = useNavigate();
    function _login(e) {
        e.preventDefault();
        let username = e.target.username.value;
        let password = e.target.password.value;
        axios.post('/login', { username, password }).then((res) => {
            if (res.data === "err") {
                toast.error("ناو یان وشەی نهێنی هەڵەیە", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            } else {
                navigate('/dashboard')
            }
        })
    }

    useEffect(() => {
        axios.get("/auth").then((res) => {
            if (res.data !== "err") {
                navigate('/dashboard')
            }
        });
    }, [])

    return (
        <div className="page-content d-flex align-items-center justify-content-center">
            <div className="row w-100 mx-0 auth-page">
                <div className="col-md-8 col-xl-6 mx-auto">
                    <div className="card">
                        <div className="row">
                            <div className="col-md-4 pe-md-0">
                                <div className="auth-side-wrapper">
                                </div>
                            </div>
                            <div className="col-md-8 ps-md-0">
                                <div className="auth-form-wrapper px-4 py-5">
                                    <a href="/" className="noble-ui-logo d-block mb-2">K.H<span> COMPANY</span></a>
                                    <h5 className="text-muted fw-normal mb-4">بەخێربێیت بۆ چوونەژوورەوەی سیستەم.</h5>
                                    <form className="forms-sample" onSubmit={_login}>
                                        <div className="mb-3">
                                            <label htmlFor="userEmail" className="form-label">ناوی بەکارهێنەر</label>
                                            <input type="username" className="form-control" id="username" placeholder="ناوی بەکارهێنەر بنووسە" />
                                            <small className="form-text text-muted"><span className="alphabet-alert" style={{ color: 'red' }}></span></small>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="userPassword" className="form-label">وشەی نهێنی</label>
                                            <input type="password" className="form-control" id="password" autoComplete="current-password" placeholder="وشەی نهێنی بنووسە" />
                                        </div>
                                        <div>
                                            <button type="submit" className="btn btn-outline-primary btn-icon-text mb-2 mb-md-0">
                                                چوونەژوورەوە
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <footer class="footer d-flex flex-column flex-md-row align-items-center justify-content-between px-4 py-3 small ltr">
                                <p class="text-muted mb-1 mb-md-0 tx-13">© 2023 - {new Date().getFullYear()} <a href="/">K.H COMPANY</a> FOR GENERAL TRADING AND AGRICULTURAL INVESTMENT</p>
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;