import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link, useParams } from "react-router-dom";
import commaNumber from "comma-number";

const ReturnLogsBy = () => {
    const returnHeader_id = useParams().id
    const [returnContent, setReturnContent] = useState([]);
    const getReturnContent = async () => {
        const { data } = await axios.get(`/return/logs/${returnHeader_id}`);
        setReturnContent(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [1]
                                },
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [2]
                                },
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [3]
                                },
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [4]
                                },
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [5]
                                },
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [12]
                                },
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [13]
                                },
                            ]
                        })
                        $('div.dataTables_wrapper div.dataTables_filter').css({
                            'float': 'left',
                            'position': 'relative',
                            'top': '-15px'
                        })
                        $('div.dataTables_wrapper div.dataTables_length label').css({
                            'position': 'relative',
                            'top': '20px'
                        })
                    }
                    var api = window.$('.dt-tbl').DataTable();
                    new window.$.fn.dataTable.FixedHeader(api);
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    };
    useEffect(() => {
        getReturnContent();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">دەستپێک</Link></li>
                    <li className="breadcrumb-item active" aria-curobj="page">تۆماری وەرگرتنەوە</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">تۆماری وەرگرتنەوەی بەرهەمەکان</h6>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>ژ.فاتورە</th>
                                        <th>جوتیار</th>
                                        <th>بەرهەم</th>
                                        <th>گروپ</th>
                                        <th>جۆر</th>
                                        <th>دەرەجە</th>
                                        <th>نرخ</th>
                                        <th>بڕ</th>
                                        <th>کۆی گشتی</th>
                                        <th>داشکاندن</th>
                                        <th>بڕ دوای داشکاندن</th>
                                        <th>کۆی گشتی%</th>
                                        <th>شوفێر</th>
                                        <th>کرێی شوفێر</th>
                                        <th>هاوپێچ</th>
                                        <th>وێنەی فەحس</th>
                                        <th>جێبەجێکار</th>
                                        <th>ڕێککەوت</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {returnContent.map((item, index) => (
                                        <tr key={index}>
                                            <td>{`ژم.${item.invoice_no}`}</td>
                                            <td>{item.farmer_name}</td>
                                            <td>{item.item_name}</td>
                                            <td>{item.industrial == 0 ? 'استهلاکی' : 'صناعی'}</td>
                                            <td>{item.item_type}</td>
                                            <td>{item.item_point == 0 ? '-' : item.item_point}</td>
                                            <td>{commaNumber(item.price)} د.ع</td>
                                            <td>{commaNumber(item.qty)} طن</td>
                                            <td>{commaNumber(item.total)} د.ع</td>
                                            <td>{commaNumber(item.discount)}%</td>
                                            <td>{commaNumber(item.disc_qty)} طن</td>
                                            <td>{commaNumber(item.disc_total)} د.ع</td>
                                            <td>{item.driver_name}</td>
                                            <td>{commaNumber(item.driver_rent)} د.ع</td>
                                            <td>{
                                                item.attachment ? (
                                                    <a href={`../../../${item.attachment}`} target="_blank">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="text-dark feather feather-paperclip"><path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" /></svg>
                                                    </a>
                                                ) : (
                                                    <span className="badge bg-danger">نییە</span>
                                                )
                                            }
                                            </td>
                                            <td>{
                                                item.attachment_scan ? (
                                                    <a href={`../../../${item.attachment_scan}`} target="_blank">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="text-dark feather feather-paperclip"><path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" /></svg>
                                                    </a>
                                                ) : (
                                                    <span className="badge bg-danger">نییە</span>
                                                )
                                            }
                                            </td>
                                            <td>{item.accountant_name}</td>
                                            <td>{new Date(item.created).toLocaleDateString()}  <br />
                                                کات: {item.submit_date}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReturnLogsBy;