import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import commaNumber from "comma-number";
import { toast } from "react-toastify";
import Select from 'react-select'

const OnHold = () => {
    const [returnHeader, setReturnHeader] = useState([]);
    const getReturnHeader = async () => {
        const { data } = await axios.get(`/return/header`);
        setReturnHeader(data);
    };

    const [farmerItems, setFarmerItems] = useState([]);
    const [farmers, setFarmers] = useState([]);
    const getFarmers = async () => {
        const res = await axios.get("/farmer");
        setFarmers(res.data);
    };

    const [auth, setAuth] = useState(false);
    useEffect(() => {
        axios.get("/auth").then((res) => {
            if (res.data !== "err") {
                if (res.data == "چاودێر") {
                    $(".auth").remove();
                    setAuth(false);
                } else {
                    setAuth(true);
                }
            }
        });
        getFarmers();
        getReturnHeader();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    const upload = () => {
        $('<input type="file" accept="image/*" />').click().on('change', async (e) => {
            var formData = new FormData();
            formData.append('file', e.target.files[0]);
            await axios.post('/upload', formData).then(res => {
                if (res.data !== "err") {
                    $(`#attachment`).val(res.data);
                    $(`#upattachment`).append(`<img src="../../../${res.data}" class="img-thumbnail viewImg" alt=${res.data} />`)
                }
                if (res.data === "limited") {
                    toast.error('قەبارەی رسمەکە زۆر گەورەیە پێویستە لە 700 کێلۆ بایت کەمتربێت!')
                    return
                }
                $(`.viewImg`).click(function () {
                    $(this).remove()
                    axios.delete(`/upload/${$(this).attr('alt')}`).then(res => {
                        if (res.data !== "err") {
                            $(`#attachment`).val('');
                        } else {
                            toast.error('هەڵەیەک هەیە');
                        }
                    })
                })
            })
        })
    }
    const upload2 = (id) => {
        $('<input type="file" accept="image/*" />').click().on('change', async (e) => {
            var formData = new FormData();
            formData.append('file', e.target.files[0]);
            await axios.post('/upload', formData).then(res => {
                if (res.data !== "err") {
                    $(`#attachment${id}`).val(res.data);
                    $(`#upattachment${id}`).append(`<img src="../../../${res.data}" class="img-thumbnail viewImg${id}" alt=${res.data} />`)
                }
                if (res.data === "limited") {
                    toast.error('قەبارەی رسمەکە زۆر گەورەیە پێویستە لە 700 کێلۆ بایت کەمتربێت!')
                    return
                }
                $(`.viewImg${id}`).click(function () {
                    $(this).remove()
                    axios.delete(`/upload/${$(this).attr('alt')}`).then(res => {
                        if (res.data !== "err") {
                            $(`#attachment${id}`).val('');
                        } else {
                            toast.error('هەڵەیەک هەیە');
                        }
                    })
                })
            })
        })
    }

    useEffect(() => {
        axios.get("/datatable").then((ready) => {
            if (ready.data !== undefined) {
                setTimeout(() => {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({ responsive: true })
                    }
                }, 1000);
            }
        })
    }, [farmerItems]);

    const currentTime = new Date();
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const defaultTime = `${hours}:${minutes}`;

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">دەستپێک</Link></li>
                    <li className="breadcrumb-item active" aria-curobj="page">بارەکان</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">لیستی بارەکان</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark mx-2" data-bs-toggle="modal" data-bs-target="#return">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus"><line x1={12} y1={5} x2={12} y2={19} /><line x1={5} y1={12} x2={19} y2={12} /></svg>
                                        <span className="ms-2">وەرگرتنەوەی پەتاتە</span>
                                    </button>
                                    <div className="modal fade" id="return" tabIndex={-1} aria-labelledby="return" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title"> وەرگرتنەوەی پەتاتە </h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                </div>
                                                <form className="form-sample" onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post(`/return/header`, data).then(res => {
                                                        window.$(`#return`).modal('hide')
                                                        if (auth) {
                                                            window.location.assign(`/return/new/${res.data}`);
                                                        } else {
                                                            getReturnHeader();
                                                            toast.success('بەسەرکەوتویی تۆمارکرا');
                                                        }
                                                    });
                                                }}>
                                                    <div className="modal-body">
                                                        <div className="mb-3">
                                                            <div className="form-group row">
                                                                <label htmlFor="farmer" className="col-12 form-label">جوتیار <span className="text-danger">*</span></label>
                                                                <div className="col-12">
                                                                    <Select options={farmers && farmers.map((item) => (
                                                                        { value: item.id, label: item.full_name }
                                                                    ))} onChange={(e) => {
                                                                        axios.get(`/production/farmer/${e.value}`).then(res => {
                                                                            setFarmerItems(res.data);
                                                                        });
                                                                    }} width="100%" placeholder="جوتیار دیار بکە" required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <div className="form-group row">
                                                                <label htmlFor="item_id" className="col-12 form-label">بەرهەم<span className="text-danger">*</span></label>
                                                                <div className="col-12">
                                                                    <Select options={farmerItems && farmerItems.map((item) => (
                                                                        {
                                                                            value: item.item_id,
                                                                            label: `${item.item_name} - ${item.item_size}`,
                                                                            production_header_id: item.id
                                                                        }
                                                                    ))} onChange={(e) => {
                                                                        $('#production_header_id').val(e.production_header_id);
                                                                    }} name="item_id" width="100%" placeholder="بەرهەمەکە دیاربکە" required />
                                                                </div>
                                                                <input type="hidden" name="production_header_id" id="production_header_id" />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="industrial" className="col-form-label">استهلاکی/صناعی؟</label>
                                                            <select className="form-select" name="industrial" required>
                                                                <option value="0">استهلاکی</option>
                                                                <option value="1">صناعی</option>
                                                            </select>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="industrial" className="col-form-label">بۆ: کۆمپانیا/جوتیار؟</label>
                                                            <select name="to_farmer" className="form-select" required onChange={(e) => {
                                                                const selectedOption = e.target.options[e.target.selectedIndex];
                                                                const customData = selectedOption.getAttribute('data');
                                                                if (customData === 'x') {
                                                                    $("#fast-price").show();
                                                                } else {
                                                                    $("#fast-price").hide();
                                                                }
                                                            }}>
                                                                <option value="0">کۆمپانیا</option>
                                                                <option value="1">جوتیار</option>
                                                                <option data="x" value="0">فرۆشتنی خێرا</option>
                                                            </select>
                                                        </div>
                                                        <div id="fast-price" style={{ display: "none" }} >
                                                            <div className="mb-3" >
                                                                <label htmlFor="fast-price">نرخی فرۆشتن</label>
                                                                <input type="number" className="form-control" name="fast_price" placeholder="0.00" />
                                                            </div>
                                                            <div className="mb-3">
                                                                <label htmlFor="fast_qty"> بڕی بار</label>
                                                                <input type="number" className="form-control" name="fast_qty" placeholder="0.00" />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="item_type" className="col-form-label">خشن/ناعم؟</label>
                                                            <select name="item_type" className="form-select" required >
                                                                <option value="خشن">خشن</option>
                                                                <option value="ناعم">ناعم</option>
                                                            </select>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="sack">ژمارەی کیس ؟</label>
                                                            <input type="number" className="form-control" name="sack" placeholder="چەند کیسە ؟" required />
                                                        </div>
                                                        {auth &&
                                                            <div className="mb-3">
                                                                <label htmlFor="invoice_no">ژ. فاتورە</label>
                                                                <input className="form-control" name="invoice_no" placeholder="ژمارەی فاتورە ؟" required />
                                                            </div>}
                                                        <div className="mb-3">
                                                            <label htmlFor="vehicle_type">جۆری بارهەلگر</label>
                                                            <select className="form-select" name="vehicle_type" required>
                                                                <option value="بەڕاد">بەڕاد</option>
                                                                <option value="کیا">کیا</option>
                                                                <option value="ئەتیکۆ">ئەتیکۆ</option>
                                                                <option value="کنترە">کنترە</option>
                                                                <option value="ترێلە - فلات">ترێلە - فلات</option>
                                                                <option value="قەلابە">قەلابە</option>
                                                                <option value="پیکاب">پیکاب</option>
                                                                <option value="هیتر">هیتر</option>
                                                            </select>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="plate_number">ژ. بارهەلگر</label>
                                                            <input className="form-control" name="plate_number" placeholder="ژمارەی بارهەلگر ؟" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="driver_name">ناوی شوفێر</label>
                                                            <input className="form-control" name="driver_name" placeholder="ناوی شوفێر ؟" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="driver_phone">ژ. شوفێر</label>
                                                            <input className="form-control" name="driver_phone" placeholder="ژ.مۆبایلی شوفێر ؟" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="driver_name">کرێی شوفێر</label>
                                                            <input className="form-control" name="driver_rent" placeholder="0.00" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="driver_name">کاتی ناردن</label>
                                                            <input type="time" className="form-control" name="submit_date" defaultValue={defaultTime} required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="driver_name">ڕێککەوتی ناردن</label>
                                                            <input type="date" className="form-control" name="created" defaultValue={new Date().toISOString().slice(0, 10)} required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="px-2 mb-1" htmlFor="attachment"> هاوپێچ</label>
                                                            <input type="hidden" name="attachment" id="attachment" required />
                                                            <div className="form-control text-center" onClick={upload}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                                                            </div>
                                                            <br />
                                                            <div align="center" id="upattachment"></div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="code_chawder">کۆدی چاودێر ؟</label>
                                                            <input type="text" className="form-control" name="code_chawder" placeholder=" کۆدەی چاودێر ئەگەر هەبوو بنووسە ؟" required />
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                        <button type="submit" className="btn btn-sm btn-dark">
                                                            <span className="ms-2">بەردەوامبوون</span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-left"><line x1={19} y1={12} x2={5} y2={12} /><polyline points="12 19 5 12 12 5" /></svg>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th>ژ.فاتورە</th>
                                        <th>جوتیار</th>
                                        <th>بەرهەم</th>
                                        <th>جۆر</th>
                                        <th>کیس</th>
                                        <th>چاودێر</th>
                                        <th>کۆدی چاودێر</th>
                                        <th>بارهەلگر</th>
                                        <th>ژ.بارهەلگر</th>
                                        <th>شوفێر</th>
                                        <th>ژ.شوفێر</th>
                                        <th>هاوپێچ</th>
                                        <th>ڕێککەوت</th>
                                        <th>کات</th>
                                        <th>بژاردە</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {returnHeader.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.invoice_no ? `ژ.${item.invoice_no}` : <span className="badge bg-danger">نییە</span>}</td>
                                            <td>{item.farmer_name}</td>
                                            <td>{item.item_name}</td>
                                            <td>{item.industrial == 0 ? 'استهلاکی' : 'صناعی'}</td>
                                            <td>{commaNumber(item.sack)}</td>
                                            <td>{item.chawder_name}</td>
                                            <td>{item.chawder_code ? item.chawder_code : '-'}</td>
                                            <td>{item.vehicle_type}</td>
                                            <td>{item.plate_number}</td>
                                            <td>{item.driver_name}</td>
                                            <td>{item.driver_phone}</td>
                                            <td>{
                                                item.attachment ? (
                                                    <a href={`../../../${item.attachment}`} target="_blank">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="text-dark feather feather-paperclip"><path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" /></svg>
                                                    </a>
                                                ) : (
                                                    <span className="badge bg-danger">نییە</span>
                                                )
                                            }
                                            </td>
                                            <td>{new Date(item.created).toLocaleDateString()}</td>
                                            <td>{item.submit_date}</td>
                                            <td>
                                                <span type="button" className="badge bg-warning text-dark mx-1" data-bs-toggle="modal" data-bs-target={`#returnModal${item.id}`}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                                                </span>
                                                {auth && <Link to={`/return/new/${item.id}`} className="badge bg-primary mx-1 auth">
                                                    <span className="mx-1">وەرگرتنەوە</span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-left"><polyline points="15 18 9 12 15 6" /></svg>
                                                </Link>}
                                            </td>
                                            <div className="modal fade" id={`returnModal${item.id}`} tabIndex={-1} aria-labelledby="return" aria-hidden="true">
                                                <div className="modal-dialog modal-lg">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title">دەستکاریکردن</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                        </div>
                                                        <form className="form-sample" onSubmit={(e) => {
                                                            e.preventDefault();
                                                            const formData = new FormData(e.target);
                                                            const data = Object.fromEntries(formData);
                                                            axios.put(`/return/header/${item.id}`, data).then(res => {
                                                                if (res.data !== "done") {
                                                                    toast.error(res.data)
                                                                } else {
                                                                    toast.success('بەسەرکەوتویی دەستکاریکرا')
                                                                    window.$(`#returnModal${item.id}`).modal('hide')
                                                                    getReturnHeader();
                                                                }
                                                            });
                                                        }}>
                                                            <div className="modal-body">
                                                                {auth &&
                                                                    <div className="mb-3">
                                                                        <label htmlFor="invoice_no">ژ. فاتورە</label>
                                                                        <input className="form-control" name="invoice_no" defaultValue={item.invoice_no} />
                                                                    </div>}
                                                                <div className="mb-3">
                                                                    <div className="form-group row">
                                                                        <label htmlFor="farmer" className="col-12 form-label">جوتیار <span className="text-danger">*</span></label>
                                                                        <div className="col-12">
                                                                            <Select options={farmers && farmers.map((x) => (
                                                                                { value: x.id, label: x.full_name }
                                                                            ))} onChange={(e) => {
                                                                                axios.get(`/production/farmer/${e.value}`).then(res => {
                                                                                    setFarmerItems(res.data);
                                                                                });
                                                                            }} defaultValue={{
                                                                                value: item.farmer_id,
                                                                                label: item.farmer_name
                                                                            }} width="100%" required />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <div className="form-group row">
                                                                        <label htmlFor="item_id" className="col-12 form-label">بەرهەم<span className="text-danger">*</span></label>
                                                                        <div className="col-12">
                                                                            <Select options={farmerItems && farmerItems.map((x) => (
                                                                                { value: x.item_id, label: x.item_name, production_header_id: x.id })
                                                                            )} onChange={(e) => {
                                                                                $('#production_header_id').val(e.production_header_id);
                                                                            }} defaultValue={{
                                                                                value: item.item_id,
                                                                                label: `${item.item_name}`,
                                                                                production_header_id: item.production_header_id
                                                                            }} name="item_id" width="100%" required />
                                                                        </div>
                                                                        <input type="hidden" name="production_header_id" id="production_header_id" defaultValue={item.production_header_id} />
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="industrial" className="col-form-label">جۆری بەرهەم</label>
                                                                    <select className="form-select" name="industrial" defaultValue={item.industrial} required>
                                                                        <option value="0">استهلاکی</option>
                                                                        <option value="1">صناعی</option>
                                                                    </select>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="industrial" className="col-form-label">بۆ: کۆمپانیا/جوتیار؟</label>
                                                                    <select name="to_farmer" className="form-select" defaultValue={item.to_farmer} required>
                                                                        <option value={0} selected>کۆمپانیا</option>
                                                                        <option value={1} selected>جوتیار</option>
                                                                    </select>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="item_type" className="col-form-label">خشن/ناعم؟</label>
                                                                    <select name="item_type" className="form-select" defaultValue={item.item_type} required >
                                                                        <option value="خشن">خشن</option>
                                                                        <option value="ناعم">ناعم</option>
                                                                    </select>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="sack">ژمارەی کیس ؟</label>
                                                                    <input type="number" className="form-control" name="sack" defaultValue={item.sack} required />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="vehicle_type">جۆری بارهەلگر</label>
                                                                    <input className="form-control" name="vehicle_type" defaultValue={item.vehicle_type} required />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="plate_number">ژ. بارهەلگر</label>
                                                                    <input className="form-control" name="plate_number" defaultValue={item.plate_number} required />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="driver_name">ناوی شوفێر</label>
                                                                    <input className="form-control" name="driver_name" defaultValue={item.driver_name} required />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="driver_phone">ژ. شوفێر</label>
                                                                    <input className="form-control" name="driver_phone" defaultValue={item.driver_phone} required />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="driver_name">کرێی شوفێر</label>
                                                                    <input className="form-control" name="driver_rent" defaultValue={item.driver_rent} required />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="driver_name">کاتی ناردن</label>
                                                                    <input type="time" className="form-control" name="submit_date" defaultValue={item.submit_date} required />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="driver_name">ڕێککەوتی ناردن</label>
                                                                    <input type="date" className="form-control" name="created" defaultValue={new Date().toISOString(item.created).split('T')[0]} required />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label> هاوپێچ</label>
                                                                    <input type="hidden" name="attachment" id={`attachment${item.id}`} defaultValue={item.attachment} />
                                                                    <div className="form-control text-center" onClick={() => upload2(item.id)}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                                                                    </div>
                                                                    <br />
                                                                    <div align="center" id={`upattachment${item.id}`}>
                                                                        {item.attachment && <img src={`../../../${item.attachment}`} className={`img-thumbnail viewImg${item.id}`} alt={item.attachment} />}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                                <button type="submit" className="btn btn-sm btn-primary"> نوێکردنەوە</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default OnHold;