import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import commaNumber from "comma-number";
import Select from 'react-select'

const Farmers = () => {

    const [chawder, setChawder] = useState([]);
    const getChawder = async () => {
        const { data } = await axios.get("/chawder");
        setChawder(data);
    };

    const [farmers, setFarmers] = useState([]);
    const getFarmers = async () => {
        const { data } = await axios.get("/farmer");
        setFarmers(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [5]
                                },
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [6]
                                },
                            ]
                        })
                        $('div.dataTables_wrapper div.dataTables_filter').css({
                            'float': 'left',
                            'position': 'relative',
                            'top': '-15px'
                        })
                        $('div.dataTables_wrapper div.dataTables_length label').css({
                            'position': 'relative',
                            'top': '20px'
                        })
                    }
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    };

    const upload = () => {
        $('<input type="file" accept="image/*" />').click().on('change', async (e) => {
            var formData = new FormData();
            formData.append('file', e.target.files[0]);
            await axios.post('/upload', formData).then(res => {
                if (res.data !== "err") {
                    $("#image").val(res.data);
                    $(`#upimage`).append(`<img src="../../../${res.data}" class="img-thumbnail viewImg" alt=${res.data} />`)
                }
                if (res.data === "limited") {
                    toast.error('قەبارەی رسمەکە زۆر گەورەیە پێویستە لە 700 کێلۆ بایت کەمتربێت!')
                    return
                }
                $('.viewImg').click(function () {
                    $(this).remove()
                    axios.delete(`/upload/${$(this).attr('alt')}`).then(res => {
                        if (res.data !== "err") {
                            $("#image").val('');
                        } else {
                            toast.error('هەڵەیەک هەیە');
                        }
                    })
                })
            })
        })
    }

    const upload2 = (id) => {
        $('<input type="file" accept="image/*" />').click().on('change', async (e) => {
            var formData = new FormData();
            formData.append('file', e.target.files[0]);
            await axios.post('/upload', formData).then(res => {
                if (res.data !== "err") {
                    $(`#image${id}`).val(res.data);
                    $(`#upimage${id}`).append(`<img src="../../../${res.data}" class="img-thumbnail viewImg${id}" alt=${res.data} />`)
                }
                if (res.data === "limited") {
                    toast.error('قەبارەی رسمەکە زۆر گەورەیە پێویستە لە 700 کێلۆ بایت کەمتربێت!')
                    return
                }
                $(`.viewImg${id}`).click(function () {
                    $(this).remove()
                    axios.delete(`/upload/${$(this).attr('alt')}`).then(res => {
                        if (res.data !== "err") {
                            $(`#image${id}`).val('');
                        }
                    })
                })
            })
        })
    }

    const [farmerItems, setFarmerItems] = useState([]);
    const [farmerItemContent, setFarmerItemContent] = useState([]);
    const getFarmerItems = async (id) => {
        axios.get(`/production/farmer/${id}`).then(res => {
            setFarmerItems(res.data);
        });
        axios.get(`/production/contract-content/${id}`).then(res => {
            setFarmerItemContent(res.data);
        });
    }


    useEffect(() => {
        getChawder();
        getFarmers();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    const [xqty, setxqty] = useState('')
    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">دەستپێک</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">جوتیارەکان</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">لیستی جوتیاران</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#newFarmer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user-plus"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" /><circle cx="8.5" cy={7} r={4} /><line x1={20} y1={8} x2={20} y2={14} /><line x1={23} y1={11} x2={17} y2={11} /></svg>
                                        <span className="ms-2">هەژماری نوێ</span>
                                    </button>
                                    <div className="modal fade" id="newFarmer" tabIndex={-1} aria-labelledby="newFarmer" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">هەژمارێکی نوێ</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                </div>
                                                <form className="form-sample" onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post('/farmer', data).then(res => {
                                                        if (res.data !== 'done') {
                                                            toast.error(res.data, {
                                                                position: "top-left",
                                                                autoClose: 3000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true,
                                                                progress: undefined,
                                                            });
                                                        } else {
                                                            getFarmers();
                                                            e.target.reset();
                                                            window.$('#newFarmer').modal('hide');
                                                        }
                                                    });
                                                }}>
                                                    <div className="modal-body">
                                                        <div className="mb-3">
                                                            <label htmlFor="name" className="form-label">ناوی تەواوی <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="full_name" placeholder="ناوی جوتیار" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="phone" className="form-label">ژ. مۆبایل <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="phone" placeholder="ژمارەی تەلەفۆن" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="address" className="form-label">ناونیشان <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="address" placeholder="ناونیشان" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="farmer_type" className="form-label">جۆر</label>
                                                            <select className="form-select" name="farmer_type">
                                                                <option value="فرۆشتن">فرۆشتن</option>
                                                                <option value="چاندن">چاندن</option>
                                                                <option value="گرێبەستی تایبەت">گرێبەستی تایبەت</option>
                                                            </select>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="chawder_id" className="form-label">چاودێر</label>
                                                            <select className="form-select" name="chawder_id">
                                                                <option value={0}>بێ چاودێر</option>
                                                                {chawder.map((chawder, index) => {
                                                                    return (
                                                                        <option key={index} value={chawder.id}>{chawder.full_name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label> وێنە</label>
                                                            <input type="hidden" name="image" id={`image`} />
                                                            <div className="form-control text-center" onClick={() => upload()}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                                                            </div>
                                                            <br />
                                                            <div align="center" id={`upimage`}>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="prepaid" className="form-label">پارەی پێشوەخت<span className="text-danger">$</span></label>
                                                            <input type="text" className="form-control" name="prepaid" placeholder="پارەی پێشوەخت" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="note" className="form-label">تێبینی </label>
                                                            <textarea className="form-control" name="note" placeholder="تێبینی" />
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                        <button type="submit" className="btn btn-primary"> زیادکردن</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>هاوپێچ</th>
                                        <th>جوتیار</th>
                                        <th>ژ.موبایل</th>
                                        <th>ناونیشان</th>
                                        <th>چاودێر</th>
                                        <th>جۆر</th>
                                        <th>تێبینی</th>
                                        <th>پارەی پێشوەخت</th>
                                        <th>رێککەوت</th>
                                        <th>تۆمارکردن</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {farmers && farmers.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td><img src={`../../${item.image}`} alt={item.title} style={{ 'width': '50px', 'height': '50px', 'borderRadius': '50%' }} /></td>
                                            <td><Link to={`/profile/${item.farmer_type === "گرێبەستی تایبەت" ? 'contract' : item.farmer_type === "چاندن" ? 'farming' : 'sold'}/${item.id}`} >{item.full_name}</Link></td>
                                            <td>{item.phone}</td>
                                            <td>{item.address}</td>
                                            <td>{item.chawder ? item.chawder : 'نییە'}</td>
                                            <td>{item.farmer_type}</td>
                                            <td>{item.note ? item.note : '-'}</td>
                                            <td>{commaNumber(item.prepaid)} $</td>
                                            <td>{new Date(item.created).toLocaleDateString()}</td>
                                            <td>
                                                <span type="button" className="badge bg-warning mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                                                </span>
                                                <span type="button" className="badge bg-danger"
                                                    onClick={() => {
                                                        Swal.fire({
                                                            title: 'ئاگاداری!',
                                                            text: "ئایا دڵنیایت لە سڕینەوەی ئەم هەژمارە ؟",
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#3085d6',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: 'بەڵێ',
                                                            cancelButtonText: 'نەخێر'
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                axios.delete(`/farmer/${item.id}`).then(res => {
                                                                    res.data === 'done' ? getFarmers() : toast.error(res.data);
                                                                })
                                                            }
                                                        })
                                                    }} >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash"><polyline points="3 6 5 6 21 6" /><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" /></svg>
                                                </span>
                                                {item.farmer_type === 'گرێبەستی تایبەت' &&
                                                    <span type="button" className="badge bg-success mx-1" data-bs-toggle="modal" data-bs-target={`#contract${item.id}`} onClick={() => getFarmerItems(item.id)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-git-merge"><circle cx={18} cy={18} r={3} /><circle cx={6} cy={6} r={3} /><path d="M6 21V9a9 9 0 0 0 9 9" /></svg>
                                                    </span>
                                                }
                                            </td>
                                            <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-hidden="true">
                                                <div className="modal-dialog">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title">نوێکردنەوەی هەژمار</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                        </div>
                                                        <form onSubmit={(e) => {
                                                            e.preventDefault();
                                                            const formData = new FormData(e.target);
                                                            const data = Object.fromEntries(formData);
                                                            axios.put(`/farmer/${item.id}`, data).then(res => {
                                                                if (res.data !== 'done') {
                                                                    toast.error(res.data, {
                                                                        position: "top-left",
                                                                        autoClose: 3000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                    });
                                                                } else {
                                                                    e.target.reset();
                                                                    getFarmers();
                                                                    window.$(`#edit${item.id}`).modal('hide');
                                                                }
                                                            });
                                                        }}>
                                                            <div className="modal-body">
                                                                <div className="mb-3">
                                                                    <label htmlFor="full_name" className="form-label">ناوی تەواو <span className="text-danger">*</span></label>
                                                                    <input type="text" className="form-control" name="full_name" defaultValue={item.full_name} required />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="phone" className="form-label">ژ.موبایل <span className="text-danger">*</span></label>
                                                                    <input type="text" className="form-control" name="phone" defaultValue={item.phone} required />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="address" className="form-label">ناونیشان <span className="text-danger">*</span></label>
                                                                    <input type="text" className="form-control" name="address" defaultValue={item.address} required />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="farmer_type" className="form-label">جۆر</label>
                                                                    <select className="form-select" name="farmer_type">
                                                                        {item.farmer_type === 'فرۆشتن' ? (
                                                                            <>
                                                                                <option value="فرۆشتن" selected>فرۆشتن</option>
                                                                                <option value="چاندن">چاندن</option>
                                                                                <option value="گرێبەستی تایبەت">گرێبەستی تایبەت</option>
                                                                            </>
                                                                        ) : item.farmer_type === 'چاندن' ? (
                                                                            <>
                                                                                <option value="فرۆشتن">فرۆشتن</option>
                                                                                <option value="چاندن" selected>چاندن</option>
                                                                                <option value="گرێبەستی تایبەت">گرێبەستی تایبەت</option>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <option value="گرێبەستی تایبەت" selected>گرێبەستی تایبەت</option>
                                                                                <option value="چاندن">چاندن</option>
                                                                                <option value="فرۆشتن">فرۆشتن</option>
                                                                            </>
                                                                        )

                                                                        }
                                                                    </select>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="chawder_id" className="form-label">چاودێر</label>
                                                                    <select className="form-select" name="chawder_id" defaultValue={item.chawder}>
                                                                        <option value={0}>بێ چاودێر</option>
                                                                        {chawder.map((chawder, index) => {
                                                                            return (
                                                                                <option key={index} value={chawder.id}>{chawder.full_name}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label> وێنەی جوتیار</label>
                                                                    <input type="hidden" name="image" id={`image${item.id}`} defaultValue={item.image} />
                                                                    <div className="form-control text-center" onClick={() => upload2(item.id)}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                                                                    </div>
                                                                    <br />
                                                                    <div align="center" id={`upimage${item.id}`}>
                                                                        {item.image && <img src={`../../../${item.image}`} className={`img-thumbnail viewImg${item.id}`} alt={item.image} />}
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="prepaid" className="form-label">پارەی پێشوەخت<span className="text-danger">$</span></label>
                                                                    <input type="text" className="form-control" name="prepaid" defaultValue={item.prepaid} placeholder="پارەی پێشوەخت" required />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="note" className="form-label">تێبینی </label>
                                                                    <textarea className="form-control" name="note" defaultValue={item.note} />
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                                <button type="submit" className="btn btn-primary">نوێکردنەوە</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            {item.farmer_type === 'گرێبەستی تایبەت' &&
                                                <div className="modal fade auth" id={`contract${item.id}`} tabIndex={-1} aria-labelledby="expenses" aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title"> گرێبەستی تایبەت</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                            </div>
                                                            <div className="modal-body">
                                                                <form className="form-sample" onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    const formData = new FormData(e.target);
                                                                    formData.append('farmer_id', item.farmer_id);
                                                                    const data = Object.fromEntries(formData);
                                                                    data.farmer_id = item.id;
                                                                    axios.put(`/production/contract/${item.id}`, data).then(res => {
                                                                        if (res.data !== 'done') {
                                                                            toast.error(res.data, {
                                                                                position: "top-left",
                                                                                autoClose: 3000,
                                                                                hideProgressBar: false,
                                                                                closeOnClick: true,
                                                                                pauseOnHover: true,
                                                                                draggable: true,
                                                                                progress: undefined,
                                                                            });
                                                                        }
                                                                    });
                                                                }}>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="farmer_id" className="col-form-label">جوتیار:</label>
                                                                        <input type="text" className="form-control" defaultValue={item.full_name} required disabled />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="expect_qty" className="col-form-label">بڕی بەرهەمهێنان:</label>
                                                                        <div className="input-group flex-nowrap">
                                                                            <input type="number" className="form-control" name="expect_qty" id="expect_qty" defaultValue={item.expect_qty} required />
                                                                            <span className="input-group-text" id="addon-wrapping">طن</span>
                                                                        </div>
                                                                        <small className="text-muted">بڕی بەرهەمی وەرگرتنەوە دیاربکە بەپێی گرێبەستەکە</small>
                                                                    </div>
                                                                    <button type="submit" className="btn btn-primary">جێبەجێکردن</button>
                                                                </form>
                                                                <div className="per-item">
                                                                    <form className="form-sample" onSubmit={(e) => {
                                                                        e.preventDefault();
                                                                        const formData = new FormData(e.target);
                                                                        formData.append('farmer_id', item.id);
                                                                        const data = Object.fromEntries(formData);
                                                                        data.farmer_id = item.id;
                                                                        axios.post(`/production/contract-content/${item.id}`, data).then(res => {
                                                                            getFarmerItems(item.id)
                                                                            if (res.data !== 'done') {
                                                                                toast.error(res.data, {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            }
                                                                        });
                                                                    }}>
                                                                        <div className="mb-3">
                                                                            <label htmlFor="item_id" className="col-form-label">بەرهەم:</label>
                                                                            <Select options={farmerItems && farmerItems.map((item) => ({ value: item.item_id, label: `${item.item_name} - ${item.item_size}` }))} name="item_id" width="100%" placeholder="بەرهەمەکە دیاربکە" required />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label htmlFor="expect_qty" className="col-form-label">بڕی بەرهەمی دیارکراو:</label>
                                                                            <input type="text" name="expect_qty" className="form-control" placeholder={0.00} required />
                                                                        </div>
                                                                        <button type="submit" className="btn btn-primary">جێبەجێکردن</button>
                                                                    </form>
                                                                    <div className="table-responsive">
                                                                        <table className="table table-striped table-sm">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>#</th>
                                                                                    <th>بەرهەم</th>
                                                                                    <th>بڕ</th>
                                                                                    <th>سڕینەوە</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {farmerItemContent ? farmerItemContent.map((obj, x) => {
                                                                                    return (
                                                                                        <tr key={obj.id}>
                                                                                            <td>{x + 1}</td>
                                                                                            <td className="mt-2">{obj.item_name}</td>
                                                                                            <td>
                                                                                                <div className="input-group">
                                                                                                    <input type="number" className="form-control" id={`perqty${obj.id}`} step={"any"} defaultValue={obj.expect_qty} onChange={((e) => { setxqty(e.target.value) })} />
                                                                                                    <span className="input-group-text">
                                                                                                        <span type="button" className="badge bg-primary" onClick={() => {
                                                                                                            axios.put(`/production/contract-content/${obj.id}`, { expect_qty: xqty }).then(() => {
                                                                                                                toast.success("بەسەرکەوتویی نوێکرایەوە");
                                                                                                            })
                                                                                                        }}>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-square"><polyline points="9 11 12 14 22 4" /><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11" /></svg>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <span type="button" className="badge bg-danger mt-2"
                                                                                                    onClick={() => {
                                                                                                        Swal.fire({
                                                                                                            title: 'ئاگاداری!',
                                                                                                            text: "ئایا دڵنیایت لە سڕینەوەی ئەم هەژمارە ؟",
                                                                                                            icon: 'warning',
                                                                                                            showCancelButton: true,
                                                                                                            confirmButtonColor: '#3085d6',
                                                                                                            cancelButtonColor: '#d33',
                                                                                                            confirmButtonText: 'بەڵێ',
                                                                                                            cancelButtonText: 'نەخێر'
                                                                                                        }).then((result) => {
                                                                                                            if (result.isConfirmed) {
                                                                                                                axios.delete(`/production/contract-content/${obj.id}`).then(res => {
                                                                                                                    res.data === 'done' ? getFarmerItems(item.id) : toast.error(res.data);
                                                                                                                })
                                                                                                            }
                                                                                                        })
                                                                                                    }} >
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash"><polyline points="3 6 5 6 21 6" /><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" /></svg>
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                }) : null}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Farmers;