import Sidebar from './Components/Layout/Sidebar';
import Footer from './Components/Layout/Footer';
import Navbar from "./Components/Layout/Navbar";
const APP = ({ children }) => {
    return (
        <div className="main-wrapper">
            <Sidebar />
            <div className="page-wrapper">
                <Navbar />
                {children}
                <Footer />
            </div>
        </div>
    );
};

export default APP;