import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import commaNumber from "comma-number";

const SaleDebt = () => {

    const [payment, setPayment] = useState([]);
    const getPayment = async () => {
        const res = await axios.get("/sale/debt");
        setPayment(res.data);
        if (res.data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [0]
                                },
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [1]
                                },
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [5]
                                },
                            ]
                        })
                        $('div.dataTables_wrapper div.dataTables_filter').css({
                            'float': 'left',
                            'position': 'relative',
                            'top': '-15px'
                        })
                        $('div.dataTables_wrapper div.dataTables_length label').css({
                            'position': 'relative',
                            'top': '20px'
                        })
                    }

                    var api = window.$('.dt-tbl').DataTable();
                    var amount_unpaid = 0;
                    new window.$.fn.dataTable.FixedHeader(api);
                    var amountunpaid = api.column(2, { search: 'applied' }).data()
                    amountunpaid.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } amount_unpaid += parseFloat(item) })
                    $('#amount_unpaid').html(`${commaNumber(amount_unpaid.toFixed(3))} $`);

                    window.$('.dt-tbl').DataTable().on('search.dt', function () {
                        amount_unpaid = 0;
                        var amountunpaid = api.column(2, { search: 'applied' }).data()
                        amountunpaid.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } amount_unpaid += parseFloat(item) })
                        $('#amount_unpaid').html(`${commaNumber(amount_unpaid.toFixed(3))} $`);
                    });
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    }
    useEffect(() => {
        getPayment();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">دەستپێک</Link></li>
                    <li className="breadcrumb-item active" aria-curobj="page">لیستی قەرزەکان</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title"> قەرزی جوتیار لە کڕینی تۆو</h6>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>فاتورە</th>
                                        <th>جوتیار</th>
                                        <th>بڕی قەرز</th>
                                        <th>لە کۆی</th>
                                        <th>واسل کراوە</th>
                                        <th>جێبەجێکار</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {payment.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{`ژ.${item.sale_header_id}`}</td>
                                            <td>{item.farmer_name}</td>
                                            <td>{commaNumber(item.amount_unpaid)} $</td>
                                            <td>{commaNumber(item.total)} $</td>
                                            <td>{commaNumber(item.amount_paid)} $</td>
                                            <td>{item.accountant_name}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="row d-flex justify-content-end mt-5">
                                <div className="col-md-3">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">کۆی قەرز</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="amount_unpaid"> 0 $ </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SaleDebt;