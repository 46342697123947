import { useRouteError } from "react-router-dom";
export default function ErrorPage() {
  const error = useRouteError();
  return (
    <div className="main-wrapper">
      <div className="page-wrapper full-page">
        <div className="page-content d-flex align-items-center justify-content-center">
          <div className="row w-100 mx-0 auth-page">
            <div className="col-md-8 col-xl-6 mx-auto d-flex flex-column align-items-center">
              <img src="../../../assets/img/404.svg" className="img-fluid mb-2" alt={404} />
              <h1 className="fw-bolder mb-22 mt-2 tx-47 text-muted">بەداخەوە</h1>
              <h4 className="mb-2 mt-1"> ناتوانرێت پەڕە پیشانبدرێت.</h4>
              <h6 className="text-muted mb-3 mt-1 text-center">هۆکارەکە بریتییە لەم هەیەلەیەی خوارەوە <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-corner-left-down"><polyline points="14 15 9 20 4 15" /><path d="M20 4h-7a4 4 0 0 0-4 4v12" /></svg>
              </h6>
              <p><i>{error.statusText || error.message}</i></p>
              <a className="mt-2" href="../../../">گەڕانەوە بۆ دەستپێک</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}