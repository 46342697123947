import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import $ from "jquery";
import commaNumber from "comma-number";

const List = () => {
    const [currencies, setCurrencies] = useState([]);
    const getCurrencies = async () => {
        const { data } = await axios.get("/currency");
        setCurrencies(data);
    };

    useEffect(() => {
        getCurrencies();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
        $('.py-4').hide();
        $('.dt-tbl').show();
    }, []);
    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">دەستپێک</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">دراو</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-7 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-header d-block border-0 pb-3">
                            <div className="row">
                                <div className="col-md-6">
                                    <h5 className="fs-20 text-black"> دراوەکان</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>دراو</th>
                                            <th>نرخ</th>
                                            <th>بژاردە</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currencies && currencies.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.currency}</td>
                                                <td>{commaNumber(item.price)}</td>
                                                <td>
                                                    <span type="button" className="badge bg-warning" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                                                    </span>
                                                </td>
                                                <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title"> نوێکردنەوەی دراو </h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                            </div>
                                                            <form className="form-sample" onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                const data = Object.fromEntries(formData);
                                                                axios.put(`/currency/${item.id}`, data).then(res => {
                                                                    if (res.data !== 'done') {
                                                                        toast.error(res.data, {
                                                                            position: "top-left",
                                                                            autoClose: 3000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                        });
                                                                    } else {
                                                                        getCurrencies();
                                                                        e.target.reset();
                                                                        window.$(`#edit${item.id}`).modal('hide');
                                                                    }
                                                                });
                                                            }}>
                                                                <div className="modal-body">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="price" className="form-label">نرخی دراو</label>
                                                                        <input type="number" name="price" className="form-control" id="price" defaultValue={item.price} required />
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                                    <button type="submit" className="btn btn-primary"> نوێکردنەوە</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-header d-block border-0 pb-3">
                            <h4 className="card-title">گۆڕینەوەی دراو</h4>
                        </div>
                        <div className="card-body">
                            <div className="mb-3">
                                <label htmlFor="usd" className="form-label"> دۆلاری ئەمریکی</label>
                                <input type="number" className="form-control" id="usd" defaultValue={0} onChange={(e) => {
                                    $('#iqd').val((e.target.value * currencies[1].price))
                                }} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="iqd" className="form-label">دیناری عیراقی</label>
                                <input type="number" className="form-control" id="iqd" defaultValue={0} onChange={(e) => {
                                    $('#usd').val((e.target.value / currencies[1].price))
                                }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default List;