import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const Vendors = () => {
    const [vendors, setVendors] = useState([]);
    const getVendors = async () => {
        const { data } = await axios.get("/vendor");
        setVendors(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                        });
                    }
                    var api = window.$('.dt-tbl').DataTable();
                    new window.$.fn.dataTable.FixedHeader(api);
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    };

    useEffect(() => {
        getVendors();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">دەستپێک</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">دابینکەر</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">لیستی دابینکەران</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#newVendor">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user-plus"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" /><circle cx="8.5" cy={7} r={4} /><line x1={20} y1={8} x2={20} y2={14} /><line x1={23} y1={11} x2={17} y2={11} /></svg>
                                        <span className="ms-2">هەژماری نوێ</span>
                                    </button>
                                    <div className="modal fade" id="newVendor" tabIndex={-1} aria-labelledby="newVendor" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">هەژمارێکی نوێ</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                </div>
                                                <form className="form-sample" onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post('/vendor', data).then(res => {
                                                        if (res.data !== 'done') {
                                                            toast.error(res.data, {
                                                                position: "top-left",
                                                                autoClose: 3000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true,
                                                                progress: undefined,
                                                            });
                                                        } else {
                                                            getVendors();
                                                            e.target.reset();
                                                            window.$('#newVendor').modal('hide');
                                                        }
                                                    });
                                                }}>
                                                    <div className="modal-body">
                                                        <div className="mb-3">
                                                            <label htmlFor="name" className="form-label">ناوی تەواوی <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="full_name" placeholder="ناوی جوتیار" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="phone" className="form-label">ژ. مۆبایل <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="phone" placeholder="ژمارەی تەلەفۆن" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="address" className="form-label">ناونیشان <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="address" placeholder="ناونیشان" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="note" className="form-label">تێبینی </label>
                                                            <textarea className="form-control" name="note" placeholder="تێبینی" />
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                        <button type="submit" className="btn btn-primary"> زیادکردن</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>دابینکەر</th>
                                        <th>ژ.موبایل</th>
                                        <th>ناونیشان</th>
                                        <th>تێبینی</th>
                                        <th>رێککەوت</th>
                                        <th>بژاردە</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {vendors && vendors.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td>{item.full_name}</td>
                                            <td>{item.phone}</td>
                                            <td>{item.address}</td>
                                            <td>{item.note ? item.note : '-'}</td>
                                            <td>{new Date(item.created).toLocaleDateString()}</td>
                                            <td>
                                                <span type="button" className="badge bg-warning mx-2" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                                                </span>
                                                <span type="button" className="badge bg-danger"
                                                    onClick={() => {
                                                        Swal.fire({
                                                            title: 'ئاگاداری!',
                                                            text: "ئایا دڵنیایت لە سڕینەوەی ئەم هەژمارە ؟",
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#3085d6',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: 'بەڵێ',
                                                            cancelButtonText: 'نەخێر'
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                axios.delete(`/vendor/${item.id}`).then(res => {
                                                                    res.data === 'done' ? getVendors() : toast.error(res.data);
                                                                })
                                                            }
                                                        })
                                                    }} >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash"><polyline points="3 6 5 6 21 6" /><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" /></svg>
                                                </span>
                                            </td>
                                            <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-hidden="true">
                                                <div className="modal-dialog">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title">نوێکردنەوەی هەژمار</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                        </div>
                                                        <form onSubmit={(e) => {
                                                            e.preventDefault();
                                                            const formData = new FormData(e.target);
                                                            const data = Object.fromEntries(formData);
                                                            axios.put(`/vendor/${item.id}`, data).then(res => {
                                                                if (res.data !== 'done') {
                                                                    toast.error(res.data, {
                                                                        position: "top-left",
                                                                        autoClose: 3000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                    });
                                                                } else {
                                                                    e.target.reset();
                                                                    getVendors();
                                                                    window.$(`#edit${item.id}`).modal('hide');
                                                                }
                                                            });
                                                        }}>
                                                            <div className="modal-body">
                                                                <div className="mb-3">
                                                                    <label htmlFor="full_name" className="form-label">ناوی تەواو <span className="text-danger">*</span></label>
                                                                    <input type="text" className="form-control" name="full_name" defaultValue={item.full_name} required />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="phone" className="form-label">ژ.موبایل <span className="text-danger">*</span></label>
                                                                    <input type="text" className="form-control" name="phone" defaultValue={item.phone} required />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="address" className="form-label">ناونیشان <span className="text-danger">*</span></label>
                                                                    <input type="text" className="form-control" name="address" defaultValue={item.address} required />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="note" className="form-label">تێبینی </label>
                                                                    <textarea className="form-control" name="note" defaultValue={item.note} />
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                                <button type="submit" className="btn btn-primary">نوێکردنەوە</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vendors;