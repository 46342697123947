import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from 'react-select'
import commaNumber from "comma-number";

const OrderList = () => {
    const navigate = useNavigate();
    const [stores, setStores] = useState([]);
    const getStores = async () => {
        const { data } = await axios.get("/store");
        setStores(data);
    };

    const [vendors, setVendors] = useState([]);
    const getVendors = async () => {
        const res = await axios.get("/vendor");
        setVendors(res.data);
    };

    const upload = () => {
        $('<input type="file" accept="image/*" />').click().on('change', async (e) => {
            var formData = new FormData();
            formData.append('file', e.target.files[0]);
            await axios.post('/upload', formData).then(res => {
                if (res.data !== "err") {
                    $("#attachment").val(res.data);
                    $(`#upattachment`).append(`<img src="../../../${res.data}" class="img-thumbnail viewImg" alt=${res.data} />`)
                }
                if (res.data === "limited") {
                    toast.error('قەبارەی رسمەکە زۆر گەورەیە پێویستە لە 700 کێلۆ بایت کەمتربێت!')
                    return
                }
                $('.viewImg').click(function () {
                    $(this).remove()
                    axios.devare(`/upload/${$(this).attr('alt')}`).then(res => {
                        if (res.data !== "err") {
                            $("#attachment").val('');
                        } else {
                            toast.error('هەڵەیەک هەیە');
                        }
                    })
                })
            })
        })
    }

    const [orders, setOrders] = useState([]);
    const getOrders = async () => {
        const { data } = await axios.get("/orders/list");
        setOrders(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [1]
                                },
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [2]
                                },
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [3]
                                },
                            ]
                        })
                        $('div.dataTables_wrapper div.dataTables_filter').css({
                            'float': 'left',
                            'position': 'relative',
                            'top': '-15px'
                        })
                        $('div.dataTables_wrapper div.dataTables_length label').css({
                            'position': 'relative',
                            'top': '20px'
                        })
                    }

                    var api = window.$('.dt-tbl').DataTable();
                    var total_qty = 0;
                    var total_amount = 0;
                    var total_amount_disc = 0;
                    var amount_paid = 0;
                    var amount_unpaid = 0;
                    new window.$.fn.dataTable.FixedHeader(api);

                    var totalqty = api.column(3, { search: 'applied' }).data()
                    totalqty.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_qty += parseFloat(item) })

                    var totalamount = api.column(5, { search: 'applied' }).data()
                    totalamount.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_amount += parseFloat(item) })

                    var totalamountdiscount = api.column(7, { search: 'applied' }).data()
                    totalamountdiscount.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_amount_disc += parseFloat(item) })

                    var amountpaid = api.column(8, { search: 'applied' }).data()
                    amountpaid.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } amount_paid += parseFloat(item) })

                    var amountunpaid = api.column(9, { search: 'applied' }).data()
                    amountunpaid.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } amount_unpaid += parseFloat(item) })

                    $('#total_qty').html(`${commaNumber(total_qty.toFixed(3))} طن`);
                    $('#total_amount').html(`${commaNumber(total_amount.toFixed(3))} $`);
                    $('#total_amount_disc').html(`${commaNumber(total_amount_disc.toFixed(3))} $`);
                    $('#amount_paid').html(`${commaNumber(amount_paid.toFixed(3))} $`);
                    $('#amount_unpaid').html(`${commaNumber(amount_unpaid.toFixed(3))} $`);

                    window.$('.dt-tbl').DataTable().on('search.dt', function () {
                        total_qty = 0;
                        total_amount = 0;
                        total_amount_disc = 0;
                        amount_paid = 0;
                        amount_unpaid = 0;

                        var totalqty = api.column(3, { search: 'applied' }).data()
                        totalqty.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_qty += parseFloat(item) })

                        var totalamount = api.column(5, { search: 'applied' }).data()
                        totalamount.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_amount += parseFloat(item) })

                        var totalamountdiscount = api.column(7, { search: 'applied' }).data()
                        totalamountdiscount.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_amount_disc += parseFloat(item) })

                        var amountpaid = api.column(8, { search: 'applied' }).data()
                        amountpaid.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } amount_paid += parseFloat(item) })

                        var amountunpaid = api.column(9, { search: 'applied' }).data()
                        amountunpaid.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } amount_unpaid += parseFloat(item) })

                        $('#total_qty').html(`${commaNumber(total_qty.toFixed(3))} طن`);
                        $('#total_amount').html(`${commaNumber(total_amount.toFixed(3))} $`);
                        $('#total_amount_disc').html(`${commaNumber(total_amount_disc.toFixed(3))} $`);
                        $('#amount_paid').html(`${commaNumber(amount_paid.toFixed(3))} $`);
                        $('#amount_unpaid').html(`${commaNumber(amount_unpaid.toFixed(3))} $`);
                    });
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    };

    const current = window.location.pathname.split("/").slice(-1)[0].replace(/^\/|\/$/g, '');
    useEffect(() => {
        console.log(current);
        getStores();
        getVendors();
        getOrders();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">دەستپێک</Link></li>
                    <li className="breadcrumb-item active" aria-curobj="page">هاوردەکردن</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">لیستی هاوردەکان</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#order">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus"><line x1={12} y1={5} x2={12} y2={19} /><line x1={5} y1={12} x2={19} y2={12} /></svg>
                                        <span className="ms-2">هاوردەکردن</span>
                                    </button>
                                    <div className="modal fade" id="order" tabIndex={-1} aria-labelledby="order" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title"> هاوردەکردنی پەتاتە </h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                </div>
                                                <form className="form-sample" onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post("/order/header", data).then(res => {
                                                        if (res.data !== 'err') {
                                                            window.$('#order').modal('hide');
                                                            localStorage.setItem("order", JSON.stringify({
                                                                order_id: res.data[0],
                                                            }));
                                                            navigate("/order/new");
                                                        }
                                                    }).catch(err => {
                                                        console.log(err);
                                                    });
                                                }}>
                                                    <div className="modal-body">
                                                        <div className="mb-3">
                                                            <div className="form-group row">
                                                                <label htmlFor="vendor_id" className="col-12 form-label">بازرگان/کۆمپانیا <span className="text-danger">*</span></label>
                                                                <div className="col-12">
                                                                    <Select options={vendors && vendors.map((item) => (
                                                                        { value: item.id, label: item.full_name }
                                                                    ))
                                                                    } name="vendor_id" width="100%" placeholder="بازرگان/کۆمپانیا دیار بکە" required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <div className="form-group row">
                                                                <label htmlFor="store_id" className="col-12 form-label">کۆگا دیار بکە <span className="text-danger">*</span></label>
                                                                <div className="col-12">
                                                                    <Select options={stores && stores.map((item) => (
                                                                        { value: item.id, label: item.store_name }
                                                                    ))
                                                                    } name="store_id" width="100%" placeholder="کۆگا دیار بکە" required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="book_type">بەپێی کیتابی</label>
                                                            <input className="form-control" name="book_type" placeholder="بەپێی چ کیتابێک ؟" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="inovice_no">ژ. فاتورە</label>
                                                            <input className="form-control" name="inovice_no" placeholder="ژمارەی فاتورە ؟" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label> یان هاوپێچ </label>
                                                            <input type="hidden" name="attachment" id="attachment" />
                                                            <div className="form-control text-center" onClick={upload}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                                                            </div>
                                                            <br />
                                                            <div align="center" id="upattachment"></div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                        <button type="submit" className="btn btn-sm btn-dark">
                                                            <span className="ms-2">بەردەوامبوون</span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-left"><line x1={19} y1={12} x2={5} y2={12} /><polyline points="12 19 5 12 12 5" /></svg>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skevaron_wave" />
                                            </th>
                                            <th>
                                                <div className="skevaron_wave" /> </th>
                                            <th><div className="skevaron_wave" /></th>
                                            <th><div className="skevaron_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skevaron_wave rounded" /></th>
                                            <td><div className="skevaron_wave rounded" /></td>
                                            <td><div className="skevaron_wave rounded" /></td>
                                            <td><div className="skevaron_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skevaron_wave rounded" /></th>
                                            <td><div className="skevaron_wave rounded" /></td>
                                            <td><div className="skevaron_wave rounded" /></td>
                                            <td><div className="skevaron_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skevaron_wave rounded" /></th>
                                            <td><div className="skevaron_wave rounded" /></td>
                                            <td><div className="skevaron_wave rounded" /></td>
                                            <td><div className="skevaron_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-striped table-sm dt-tbl nowrap" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>کیتاب</th>
                                        <th>ژ. فاتورە</th>
                                        <th>بڕ</th>
                                        <th>نرخی مامناوەند</th>
                                        <th>کۆی گشتی</th>
                                        <th>داشکاندن</th>
                                        <th>کۆی گشتی%</th>
                                        <th>پارەی واسل</th>
                                        <th>پارەی ماوە</th>
                                        <th>ژمێریار</th>
                                        <th>تۆماربوون</th>
                                        <th>بژاردە</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orders && orders.map((item) => (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.book_type ? item.book_type : <span className="text-danger">دیارنەکراوە</span>}</td>
                                            <td>{item.inovice_no ? item.inovice_no : <span className="text-danger">دیارنەکراوە</span>}</td>
                                            <td>{commaNumber(item.qty)} طن</td>
                                            <td>{commaNumber((item.price))} {item.currency}</td>
                                            <td>{commaNumber(item.total)} {item.currency}</td>
                                            <td>{item.discount}-</td>
                                            <td>{item.total_disc}%</td>
                                            <td>{commaNumber(item.amount_paid)} {item.currency}</td>
                                            <td>{commaNumber(item.amount_unpaid)} {item.currency}</td>
                                            <td>{item.accountant_name}</td>
                                            <td>{new Date(item.updated).toLocaleDateString()}</td>
                                            <td>
                                                <Link to={`/order/invoice/${item.id}`} target={'_blank'} className="badge bg-dark mx-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-printer"><polyline points="6 9 6 2 18 2 18 9" /><path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2" /><rect x={6} y={14} width={12} height={8} /></svg>
                                                </Link>
                                                <span type="button" className="badge bg-primary mx-1" data-bs-toggle="modal" data-bs-target={`#viewModal${item.id}`}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-maximize-2"><polyline points="15 3 21 3 21 9" /><polyline points="9 21 3 21 3 15" /><line x1={21} y1={3} x2={14} y2={10} /><line x1={3} y1={21} x2={10} y2={14} /></svg>
                                                </span>
                                                <Link to={`/order/edit/${item.id}`} type="button" className="badge bg-warning text-dark mx-1" >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                                                </Link>
                                                {item.amount_unpaid > 0 ?
                                                    <span type="button" className="badge bg-success mx-1" data-bs-toggle="modal" data-bs-target={`#paymentModal${item.id}`}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-credit-card"><rect x={1} y={4} width={22} height={16} rx={2} ry={2} /><line x1={1} y1={10} x2={23} y2={10} /></svg>
                                                    </span> :
                                                    <span type="button" className="badge bg-success mx-1" onClick={() => {
                                                        toast.success('هەموو پارەکە دراوە', {
                                                            position: "top-center",
                                                            rtl: true,
                                                            autoClose: 1000,
                                                            hideProgressBar: true,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: true,
                                                            progress: undefined,
                                                            theme: "light",
                                                        });
                                                    }
                                                    }>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12" /></svg>
                                                    </span>
                                                }
                                            </td>
                                            <div className="modal fade" id={`paymentModal${item.id}`} tabIndex={-1} aria-labelledby="payment" aria-hidden="true">
                                                <div className="modal-dialog modal-md">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title"> پارەدان </h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                        </div>
                                                        <form className="form-sample" onSubmit={(e) => {
                                                            e.preventDefault();
                                                            const formData = new FormData(e.target);
                                                            formData.append('accountant_id', item.accountant_id);
                                                            const data = Object.fromEntries(formData);
                                                            axios.post(`/payment/order/${item.id}`, data).then(res => {
                                                                if (res.data !== 'done') {
                                                                    toast.error(res.data, {
                                                                        position: "top-left",
                                                                        autoClose: 3000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                    });
                                                                } else {
                                                                    window.$(`#paymentModal${item.id}`).modal('hide')
                                                                    e.target.reset();
                                                                    getOrders();
                                                                }
                                                            });
                                                        }}>
                                                            <div className="modal-body">
                                                                <div className="mb-3">
                                                                    <label htmlFor="unpaid" className="col-form-label">پارەی ماوە:</label>
                                                                    <input type="number" className="form-control" id={`unpaid${item.id}`} defaultValue={item.amount_unpaid} disabled />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="amount_paid" className="col-form-label">بڕی پارەدان:</label>
                                                                    <input type="number" className="form-control" name="amount_paid" id={`paid${item.id}`} step="any" defaultValue={0} onChange={(e) => {
                                                                        if (e.target.value > item.amount_unpaid) {
                                                                            $(`#paid${item.id}`).val(item.amount_unpaid);
                                                                            $(`#unpaid${item.id}`).val(0);
                                                                        } else {
                                                                            $(`#unpaid${item.id}`).val(item.amount_unpaid - e.target.value);
                                                                        }
                                                                    }} required />
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                                    <button type="submit" className="btn btn-primary">واسل کردن</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal fade" id={`viewModal${item.id}`} tabIndex={-1} aria-labelledby="viewModal" aria-hidden="true">
                                                <div className="modal-dialog modal-md">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title"> وردەکاریی ئۆردەر </h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                        </div>
                                                        <div className="modal-body">
                                                            <table className="table table-striped table-sm" style={{ width: "100%" }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th>بازرگان</th>
                                                                        <td>{item.vendor_name}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>ژ.م. بازرگان</th>
                                                                        <td>{item.vendor_phone}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>بڕ</th>
                                                                        <td>{commaNumber(item.qty)} طن</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>نرخی مامناوەند</th>
                                                                        <td>{commaNumber((item.price))} {item.currency}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>کۆی گشتی</th>
                                                                        <td>{commaNumber(item.total)} {item.currency}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>داشکاندن</th>
                                                                        <td>{item.discount}%</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>پارەی واسل</th>
                                                                        <td>{commaNumber(item.amount_paid)} {item.currency}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>پارەی ماوە</th>
                                                                        <td>{commaNumber(item.amount_unpaid)} {item.currency}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>کۆگا</th>
                                                                        <td>{item.store_name}, {item.store_address}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>ژمێریار</th>
                                                                        <td>{item.accountant_name}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>تێبینی</th>
                                                                        <td>
                                                                            <div className="text-wrap" style={{ maxWidth: "300px" }}>
                                                                                {item.note ? item.note : '-'}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>هاوپێچ</th>
                                                                        <td>{
                                                                            item.attachment ? (
                                                                                <a href={`../../../${item.attachment}`} target="_blank">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="text-dark feather feather-paperclip"><path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" /></svg>
                                                                                </a>
                                                                            ) : (
                                                                                <span className="badge bg-danger">نییە</span>
                                                                            )
                                                                        }
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>تۆماربوون</th>
                                                                        <td>{new Date(item.updated).toLocaleDateString()}</td>
                                                                    </tr>
                                                                </thead>
                                                            </table>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="row mt-4">
                                <div className="col-md-2">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">بڕی گشتی</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="total_qty"> 0 طن </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">کۆی گشتی</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="total_amount"> 0 $ </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">کۆی گشتی دوای داشکاندن</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="total_amount_disc"> 0 $ </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">واسلەکان</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="amount_paid"> 0 $ </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">قەرزەکان</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="amount_unpaid"> 0 $ </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default OrderList;
