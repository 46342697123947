import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import commaNumber from "comma-number";

const SalesLog = () => {
    const [saleContent, setSaleContent] = useState([]);
    const getSaleContent = async () => {
        const { data } = await axios.get(`/sale/logs`);
        setSaleContent(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [1]
                                },
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [2]
                                },
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [3]
                                },
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [5]
                                },
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [6]
                                }
                            ]
                        })
                        $('div.dataTables_wrapper div.dataTables_filter').css({
                            'float': 'left',
                            'position': 'relative',
                            'top': '-15px'
                        })
                        $('div.dataTables_wrapper div.dataTables_length label').css({
                            'position': 'relative',
                            'top': '20px'
                        })
                    }

                    var api = window.$('.dt-tbl').DataTable();
                    var total_qty = 0;
                    var total_amount = 0;
                    new window.$.fn.dataTable.FixedHeader(api);
                    var totalqty = api.column(5, { search: 'applied' }).data()
                    totalqty.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_qty += parseFloat(item) })
                    var totalamount = api.column(6, { search: 'applied' }).data()
                    totalamount.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_amount += parseFloat(item) })

                    $('#total_qty').html(`${commaNumber(total_qty.toFixed(3))} طن`);
                    $('#total_amount').html(`${commaNumber(total_amount.toFixed(3))} $`);

                    window.$('.dt-tbl').DataTable().on('search.dt', function () {
                        total_qty = 0;
                        total_amount = 0;
                        var totalqty = api.column(5, { search: 'applied' }).data()
                        totalqty.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_qty += parseFloat(item) })
                        var totalamount = api.column(6, { search: 'applied' }).data()
                        totalamount.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_amount += parseFloat(item) })

                        $('#total_qty').html(`${commaNumber(total_qty.toFixed(3))} طن`);
                        $('#total_amount').html(`${commaNumber(total_amount.toFixed(3))} $`);
                    });
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    };
    useEffect(() => {
        getSaleContent();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">دەستپێک</Link></li>
                    <li className="breadcrumb-item active" aria-curobj="page">تۆماری فرۆشەکان</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">تۆماری فرۆشەکان</h6>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>جوتیار</th>
                                        <th>جۆر</th>
                                        <th>قەبارە</th>
                                        <th>نرخ</th>
                                        <th>بڕ</th>
                                        <th>سەرجەم</th>
                                        <th>جێبەجێکار</th>
                                        <th>ڕێککەوت</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {saleContent.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.farmer_name}</td>
                                            <td>{item.item_name}</td>
                                            <td>{item.item_size}</td>
                                            <td>{commaNumber(item.price)} $</td>
                                            <td>{commaNumber(item.qty)} طن</td>
                                            <td>{commaNumber(item.total)} $</td>
                                            <td>{item.accountant_name}</td>
                                            <td>{new Date(item.created).toLocaleDateString()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="row mt-4">
                                <div className="col-md-3">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">بڕی گشتی</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="total_qty"> 0 طن </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">کۆی گشتی</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="total_amount"> 0 $ </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SalesLog;