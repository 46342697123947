import { createBrowserRouter } from "react-router-dom";
import APP from './App';
import ErrorPage from './Components/Pages/404';
import Dashboard from './Components/Pages/Dashboard';
import Login from "./Components/Pages/Login";
import Accountants from "./Components/Accounts/Accountants";
import Farmers from "./Components/Accounts/Farmers";
import Vendors from "./Components/Accounts/Vendors";
import CurrenciesList from "./Components/Currencies/List";
import ItemsList from "./Components/Items/List";
import Price from "./Components/Items/Price";
import SectionsList from "./Components/Sections/List";
import StoresList from "./Components/Stores/List";
import OrderList from "./Components/Orders/List";
import NewOrder from "./Components/Orders/New";
import EditOrder from "./Components/Orders/Edit";
import OrdersLog from "./Components/Logs/Orders";
import SaleList from "./Components/Sales/List";
import NewSale from "./Components/Sales/New";
import EditSale from "./Components/Sales/Edit";
import ProductionList from "./Components/Productions/List";
import NewProduction from "./Components/Productions/New";
import EditProduction from "./Components/Productions/Edit";
import ProductionsLog from "./Components/Logs/Productions";
import ExpenseByFarmer from "./Components/Productions/Expenses/Single";
import SalesLog from "./Components/Logs/Sales";
import ExpenseList from "./Components/Productions/Expenses/List";
import Accounts from "./Components/Finance/Accounts";
import Expenses from "./Components/Finance/Expenses";
import Voucher from "./Components/Finance/Voucher";
import ReportVoucher from "./Components/Finance/ReportVouchers";
import Employee from "./Components/Finance/Employees";
import ReportItemBalance from "./Components/Orders/ReportItemBalance";
import NewReturn from "./Components/Productions/Return/New";
import ReturnLogs from "./Components/Logs/Returns";
import ReturnList from "./Components/Productions/Return/List";
import ReturnLogsBy from "./Components/Logs/ReturnBy";
import ReturnListBy from "./Components/Productions/Return/ListHiD";
import ReturnStatus from "./Components/Productions/Return/ListPiD";
import ProfielFarmer from "./Components/Profile/Farmer";
import OrderPay from "./Components/Payments/OrderPay";
import SalePay from "./Components/Payments/SalePay";
import ProductionPay from "./Components/Payments/ProductionPay";
import OrderPayBy from "./Components/Payments/OrderPayBy";
import SalePayBy from "./Components/Payments/SalePayBy";
import ProductionPayBy from "./Components/Payments/ProductionPayBy";
import Modify from "./Components/Accounts/Modify";
import StoresLogs from "./Components/Logs/Stores";
import ProductsLogs from "./Components/Logs/Products";
import OnHold from "./Components/Productions/Return/onHold";
import OrderInvoice from "./Components/Orders/Invoice";
import SaleInvoice from "./Components/Sales/Invoice";
import ProductionInvoice from "./Components/Productions/Invoice";
import ProductionDebt from "./Components/Payments/Debts/ProductionDebt";
import OrderDebt from "./Components/Payments/Debts/OrderDebt";
import SaleDebt from "./Components/Payments/Debts/SaleDebt";
import EditReturn from "./Components/Productions/Return/Edit";
import ProfileContract from "./Components/Profile/Contract";
import ProfileFarming from "./Components/Profile/Farming";
import ProfileSold from "./Components/Profile/Sold";

const router = createBrowserRouter(
    [
        {
            path: "/", element:
                <div className="main-wrapper">
                    <div className="page-wrapper full-page">
                        <video autoPlay muted loop id="myVideo">
                            <source src="../../assets/kh.mp4" type="video/mp4" />
                        </video>
                        <Login />
                    </div>
                </div>, errorElement: <ErrorPage />,
        },
        {
            path: "/dashboard", element:
                <APP>
                    <Dashboard />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/accounts/accountants", element:
                <APP>
                    <Accountants />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/accounts/farmers", element:
                <APP>
                    <Farmers />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/accounts/vendors", element:
                <APP>
                    <Vendors />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/currencies/list", element:
                <APP>
                    <CurrenciesList />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/items/list", element:
                <APP>
                    <ItemsList />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/items/price", element:
                <APP>
                    <Price />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/sections/list", element:
                <APP>
                    <SectionsList />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/stores/list", element:
                <APP>
                    <StoresList />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/order/invoice/:id", element:
                <APP>
                    <OrderInvoice />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/order/list", element:
                <APP>
                    <OrderList />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/order/new", element:
                <APP>
                    <NewOrder />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/order/edit/:id", element:
                <APP>
                    <EditOrder />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/logs/order/", element:
                <APP>
                    <OrdersLog />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "//report/items/balance", element:
                <APP>
                    <ReportItemBalance />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/sale/invoice/:id", element:
                <APP>
                    <SaleInvoice />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/sale/list", element:
                <APP>
                    <SaleList />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/sale/new", element:
                <APP>
                    <NewSale />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/sale/edit/:id", element:
                <APP>
                    <EditSale />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/logs/sale/", element:
                <APP>
                    <SalesLog />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/production/invoice/:id", element:
                <APP>
                    <ProductionInvoice />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/production/list", element:
                <APP>
                    <ProductionList />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/production/new", element:
                <APP>
                    <NewProduction />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/production/edit/:id", element:
                <APP>
                    <EditProduction />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/logs/production/", element:
                <APP>
                    <ProductionsLog />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/production/expense/:id", element:
                <APP>
                    <ExpenseByFarmer />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/production/expense", element:
                <APP>
                    <ExpenseList />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/finance/accounts", element:
                <APP>
                    <Accounts />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/finance/expenses", element:
                <APP>
                    <Expenses />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/finance/voucher", element:
                <APP>
                    <Voucher />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/finance/employees", element:
                <APP>
                    <Employee />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/report/vouchers", element:
                <APP>
                    <ReportVoucher />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/return/new/:id", element:
                <APP>
                    <NewReturn />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/return/edit/:id", element:
                <APP>
                    <EditReturn />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/returns/log", element:
                <APP>
                    <ReturnLogs />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/stores/log", element:
                <APP>
                    <StoresLogs />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/product/log", element:
                <APP>
                    <ProductsLogs />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/returns/log/:id", element:
                <APP>
                    <ReturnLogsBy />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/returns/list", element:
                <APP>
                    <ReturnList />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/returns/list/:id", element:
                <APP>
                    <ReturnListBy />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/return/status/:id", element:
                <APP>
                    <ReturnStatus />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/payments/order", element:
                <APP>
                    <OrderPay />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/payments/order/:id", element:
                <APP>
                    <OrderPayBy />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/payments/sale", element:
                <APP>
                    <SalePay />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/payments/sale/:id", element:
                <APP>
                    <SalePayBy />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/payments/production", element:
                <APP>
                    <ProductionPay />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/payments/production/:id", element:
                <APP>
                    <ProductionPayBy />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/account/modify", element:
                <APP>
                    <Modify />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/onhold", element:
                <APP>
                    <OnHold />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/debt/order", element:
                <APP>
                    <OrderDebt />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/debt/sale", element:
                <APP>
                    <SaleDebt />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/debt/production", element:
                <APP>
                    <ProductionDebt />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/profile/farming/:id", element:
                <APP>
                    <ProfileFarming />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/profile/contract/:id", element:
                <APP>
                    <ProfileContract />
                </APP>, errorElement: <ErrorPage />,
        },
        {
            path: "/profile/sold/:id", element:
                <APP>
                    <ProfileSold />
                </APP>, errorElement: <ErrorPage />,
        },
    ]
);

export default router;