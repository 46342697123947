import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const NewReturn = () => {
    const returnHeader_id = useParams().id
    const navigate = useNavigate();
    const [stores, setStores] = useState([]);
    const [sections, setSections] = useState([]);
    const getStores = async () => {
        const { data } = await axios.get("/store");
        setStores(data);
    };

    const [itemPoint, setItemPoint] = useState([])
    const getItemPoint = async () => {
        const { data } = await axios.get(`/point`)
        setItemPoint(data)
    }
    const [returnHeader, setReturnHeader] = useState([])
    const getReturnHeader = async () => {
        const { data } = await axios.get(`/return/header/${returnHeader_id}`)
        setReturnHeader(data[0])
    }


    const upload = () => {
        $('<input type="file" accept="image/*" />').click().on('change', async (e) => {
            var formData = new FormData();
            formData.append('file', e.target.files[0]);
            await axios.post('/upload', formData).then(res => {
                if (res.data !== "err") {
                    $(`#attachment`).val(res.data);
                    $(`#upattachment`).append(`<img src="../../../${res.data}" class="img-thumbnail viewImg" alt=${res.data} />`)
                }
                if (res.data === "limited") {
                    toast.error('قەبارەی رسمەکە زۆر گەورەیە پێویستە لە 700 کێلۆ بایت کەمتربێت!')
                    return
                }
                $(`.viewImg`).click(function () {
                    $(this).remove()
                    axios.delete(`/upload/${$(this).attr('alt')}`).then(res => {
                        if (res.data !== "err") {
                            $(`#attachment`).val('');
                        } else {
                            toast.error('هەڵەیەک هەیە');
                        }
                    })
                })
            })
        })
    }

    useEffect(() => {
        getStores()
        getItemPoint()
        getReturnHeader()
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">دەستپێک</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">وەرگرتنەوەی بەرهەم</li>
                </ol>
            </nav>
            <div className="row inbox-wrapper">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-3 border-end-lg">
                                    <div className="aside-content">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <button className="navbar-toggle btn btn-icon border d-block d-lg-none" data-bs-target=".email-aside-nav" data-bs-toggle="collapse" type="button">
                                                <span className="icon"><i data-feather="chevron-down" /></span>
                                            </button>
                                            <div className="order-first">
                                                <h5>وەرگرتنەوەی بەرهەم</h5>
                                            </div>
                                        </div>
                                        <div className="email-aside-nav collapse">
                                            <p className="text-muted tx-12 fw-bolder text-uppercase mb-2 mt-4"> بەرهەم</p>
                                            <ul className="nav flex-column">
                                                <li className="nav-item">
                                                    <p className="nav-link d-flex align-items-center"> <strong>بەرهەم:</strong> <span>&nbsp; {returnHeader.item_name}</span>
                                                    </p>
                                                </li>
                                                <li className="nav-item">
                                                    <p className="nav-link d-flex align-items-center"> <strong>ژ.فاتورە:</strong>  <span>&nbsp; {returnHeader.invoice_no}</span>
                                                    </p>
                                                </li>
                                                <li className="nav-item">
                                                    <p className="nav-link d-flex align-items-center"> <strong>جۆر:</strong> <span>&nbsp; {returnHeader.industrial == '0' ? 'استهلاکی' : 'صناعی'}</span>
                                                    </p>
                                                </li>
                                            </ul>
                                            <p className="text-muted tx-12 fw-bolder text-uppercase mb-2 mt-4"> جوتیار</p>
                                            <ul className="nav flex-column">
                                                <li className="nav-item">
                                                    <p className="nav-link d-flex align-items-center"> <strong>ناو:</strong> <span>&nbsp; {returnHeader.farmer_name}</span>
                                                    </p>
                                                </li>
                                                <li className="nav-item">
                                                    <p className="nav-link d-flex align-items-center"> <strong>ژ. مۆبایل:</strong> <span>&nbsp; {returnHeader.farmer_phone}</span>
                                                    </p>
                                                </li>
                                            </ul>
                                            <p className="text-muted tx-12 fw-bolder text-uppercase mb-2 mt-4">زانیاریی گواستنەوە</p>
                                            <ul className="nav flex-column">
                                                <li className="nav-item">
                                                    <p className="nav-link d-flex align-items-center"> <strong>شوفێر:</strong> <span>&nbsp; {returnHeader.driver_name}</span>
                                                    </p>
                                                </li>
                                                <li className="nav-item">
                                                    <p className="nav-link d-flex align-items-center"> <strong>ژ.مۆبایل:</strong> <span>&nbsp; {returnHeader.driver_phone}</span>
                                                    </p>
                                                </li>
                                                <li className="nav-item">
                                                    <p className="nav-link d-flex align-items-center"> <strong>جۆری بارهەلگر:</strong> <span>&nbsp; {returnHeader.vehicle_type}</span>
                                                    </p>
                                                </li>
                                                <li className="nav-item">
                                                    <p className="nav-link d-flex align-items-center"> <strong>ژ. تابلۆ:</strong> <span>&nbsp; {returnHeader.plate_number}</span>
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-9">
                                    <div className="row mt-4">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="mb-3">
                                                        <div className="form-group row">
                                                            <label htmlFor="to_farmer" className="col-12 form-label">بۆ<span className="text-danger">*</span></label>
                                                            <div className="col-12">
                                                                <input type="text" id="to_farmer" className="form-control" value={returnHeader.to_farmer == 0 ? "کۆمپانیا" : "جوتیار"}
                                                                    onClick={() => {
                                                                        $('#to_farmer').hide();
                                                                        $('#to_farmer_disp').show();
                                                                    }} />
                                                                <select id="to_farmer_disp" className="form-select" required style={{ display: 'none' }}
                                                                    onChange={(e) => {
                                                                        if (e.target.value === '0') {
                                                                            document.getElementById('store_id').disabled = false
                                                                            document.getElementById('section_id').disabled = false
                                                                        } else {
                                                                            document.getElementById('store_id').disabled = true
                                                                            document.getElementById('section_id').disabled = true
                                                                        }
                                                                        axios.put(`/return/header/${returnHeader.id}`, { to_farmer: e.target.value }).then(() => {
                                                                            getReturnHeader()
                                                                            $('#to_farmer').show();
                                                                            $('#to_farmer_disp').hide();
                                                                        })
                                                                    }}>
                                                                    <option value="">وەرگر هەڵبژێرە</option>
                                                                    <option value="0">کۆمپانیا</option>
                                                                    <option value="1">جوتیار</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="mb-3">
                                                        <div className="form-group row">
                                                            <label htmlFor="item_type" className="col-12 form-label">جۆر<span className="text-danger">*</span></label>
                                                            <div className="col-12">
                                                                <input type="text" id="item_type" className="form-control" value={returnHeader.item_type}
                                                                    onClick={() => {
                                                                        $('#item_type').hide();
                                                                        $('#item_type_disp').show();
                                                                    }} />
                                                                <select id="item_type_disp" className="form-select" required style={{ display: 'none' }}
                                                                    onChange={(e) => {
                                                                        if (e.target.value === 'ناعم') {
                                                                            document.getElementById('item_point').disabled = true
                                                                        } else {
                                                                            document.getElementById('item_point').disabled = false
                                                                        }
                                                                        axios.put(`/return/header/${returnHeader.id}`, { item_type: e.target.value }).then(() => {
                                                                            getReturnHeader()
                                                                            $('#item_type').show();
                                                                            $('#item_type_disp').hide();
                                                                        })
                                                                    }}>
                                                                    <option value={returnHeader.item_type}>جۆرەکەی دیاربکە</option>
                                                                    <option value="خشن">خشن</option>
                                                                    <option value="ناعم">ناعم</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="mb-3">
                                                        <label className="form-label">دەرەجە</label>
                                                        <select id="item_point" className="form-select mt-2 form-select-sm" required onChange={(e) => {
                                                            axios.get(`/point/price/${returnHeader.item_id}/${e.target.value}`).then((res) => {
                                                                if (res.data.length !== 0) {
                                                                    document.getElementById('price').value = res.data[0].return_price
                                                                } else {
                                                                    document.getElementById('price').value = 0
                                                                }
                                                            })
                                                        }}>
                                                            {returnHeader ? returnHeader.item_type == "ناعم" ?
                                                                <option value={0}>-</option> :
                                                                <>
                                                                    <option value="">0</option>
                                                                    {itemPoint.map((item, index) => {
                                                                        return (<option key={index} value={item.item_point_id}>{item.item_point}</option>)
                                                                    })}
                                                                </>
                                                                : null}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="mb-3">
                                                        <label className="form-label">نرخ <span className="text-danger">*</span></label>
                                                        <div className="input-group form-control-sm flex-nowrap">
                                                            <span className="input-group-text" id="addon-wrapping"><span className="mx-1">د.ع</span></span>
                                                            <input type="number" className="form-control" id="price" defaultValue={returnHeader.price} placeholder="0.00" required />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="mb-3">
                                                        <label className="form-label">بڕ <span className="text-danger">*</span></label>
                                                        <div className="input-group form-control-sm flex-nowrap">
                                                            <input type="number" className="form-control" id="qty" defaultValue="0.00" required />
                                                            <span className="input-group-text" id="addon-wrapping"><span className="mx-1">طن</span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="mb-3">
                                                        <label className="form-label">داشکاندن</label>
                                                        <div className="input-group form-control-sm flex-nowrap">
                                                            <span className="input-group-text" id="addon-wrapping"><span className="mx-1">%</span></span>
                                                            <input type="number" className="form-control" id="discount" placeholder="0.00" required />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="mb-3">
                                                        <label className="form-label">کۆگا</label>
                                                        <select id="store_id" className="form-select" onChange={(e) => { axios.get(`/section/${e.target.value}`).then(res => { setSections(res.data); }); }} >
                                                            {returnHeader ? returnHeader.to_farmer == 1 ?
                                                                <option value={0} disabled>-</option> :
                                                                <>
                                                                    <option value="">کۆگایەک دیاربکە</option>
                                                                    {stores && stores.map((obj) => (
                                                                        <option key={obj.id} value={obj.id}>{obj.store_name}</option>
                                                                    ))}
                                                                </>
                                                                : null}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="mb-3">
                                                        <label className="form-label">کەرت</label>
                                                        <select id="section_id" className="form-select">
                                                            {sections && sections.map((obj) => (
                                                                <option key={obj.id} value={obj.id}>{obj.section_name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="mb-3">
                                                        <label className="px-2 mb-1" htmlFor="attachment"> وێنەی فەحس</label>
                                                        <input type="hidden" name="attachment_scan" id="attachment" />
                                                        <div className="form-control text-center" onClick={upload}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                                                        </div>
                                                        <br />
                                                        <div align="center" id="upattachment"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {returnHeader.fast_price > 0 &&
                                                <>
                                                    <div className="col-md-6">
                                                        <div className="alert alert-warning" role="alert">
                                                            فرۆشتنی خێرا بە نرخی ({returnHeader.fast_price})
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="alert alert-info" role="alert">
                                                            بڕی بارەکە  ({returnHeader.fast_qty}) طن
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            <div className="col-md-3">
                                                <div className="mt-5">
                                                    <label htmlFor="formFile" className="form-label">گۆڕینی رێککەوتی وەرگرتنەوە</label>
                                                    <input className="form-control" type="date" id="return_date" name="return_date" defaultValue={returnHeader.created}
                                                        onChange={(e) => {
                                                            axios.put(`/return/header/${returnHeader_id}`, { created: e.target.value }).then(res => {
                                                                if (res.data == 'done') {
                                                                    toast.success('رێککەوتی وەرگرتنەوە گۆڕدرا');
                                                                } else {
                                                                    toast.error('هەڵەیەک هەیە');
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-5">
                                                    <label htmlFor="formFile" className="form-label">گۆڕینی کاتی وەرگرتنەوە</label>
                                                    <input className="form-control" type="time" id="submit_date" name="submit_date" defaultValue={returnHeader.submit_date}
                                                        onChange={(e) => {
                                                            axios.put(`/return/header/${returnHeader_id}`, { submit_date: e.target.value }).then(res => {
                                                                if (res.data == 'done') {
                                                                    toast.success('کاتی وەرگرتنەوە گۆڕدرا');
                                                                } else {
                                                                    toast.error('هەڵەیەک هەیە');
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-5">
                                                    <label htmlFor="formFile" className="form-label">گۆڕینی کرێی شوفێر </label>
                                                    <input type="number" className="form-control mx-2" defaultValue={returnHeader.driver_rent}
                                                        onChange={(e) => axios.put(`/return/header/${returnHeader.id}`, { driver_rent: e.target.value }).then(res => {
                                                            if (res.data == 'done') {
                                                                getReturnHeader();
                                                                toast.success('کرێی شوفێر گۆڕدرا');
                                                            } else {
                                                                toast.error('هەڵەیەک هەیە');
                                                            }
                                                        })} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-5">
                                                    <label htmlFor="formFile" className="form-label">گۆڕینی ژ.فاتورە </label>
                                                    <input type="number" className="form-control mx-2" defaultValue={returnHeader.invoice_no}
                                                        onChange={(e) => axios.put(`/return/header/${returnHeader.id}`, { invoice_no: e.target.value }).then(res => {
                                                            if (res.data == 'done') {
                                                                getReturnHeader();
                                                                toast.success('ژ.فاتورە گۆڕدرا');
                                                            } else {
                                                                toast.error('هەڵەیەک هەیە');
                                                            }
                                                        })} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-5">
                                                    <label htmlFor="formFile" className="form-label"> کۆدی چاودێر </label>
                                                    <input className="form-control" defaultValue={returnHeader.code_chawder} disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="mb-3 float-end">
                                        <button type="button" className="btn btn-danger"
                                            onClick={() => {
                                                Swal.fire({
                                                    title: 'ئایا دڵنیایت؟',
                                                    text: "بەیەکجاری دەسڕێتەوە و ناتوانرێت بگەڕێندرێتەوە !",
                                                    icon: 'warning',
                                                    showCancelButton: true,
                                                    confirmButtonColor: '#3085d6',
                                                    cancelButtonColor: '#d33',
                                                    confirmButtonText: 'بەڵێ',
                                                    cancelButtonText: 'نەخێر'
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        axios.delete(`/return/submit/${returnHeader_id}`).then(res => {
                                                            if (res.data !== 'done') {
                                                                toast.error(res.data, {
                                                                    position: "top-left",
                                                                    autoClose: 3000,
                                                                    hideProgressBar: false,
                                                                    closeOnClick: true,
                                                                    pauseOnHover: true,
                                                                    draggable: true,
                                                                    progress: undefined,
                                                                });
                                                            } else {
                                                                navigate('/returns/list');
                                                            }
                                                        });
                                                    }
                                                })
                                            }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1={18} y1={6} x2={6} y2={18} /><line x1={6} y1={6} x2={18} y2={18} /></svg>
                                            <span className="mx-2">سڕینەوە</span>
                                        </button>
                                        <button className="btn btn-primary mx-2" onClick={() => {
                                            const to_farmer = $('#to_farmer').val(),
                                                item_type = $('#item_type').val(),
                                                price = $('#price').val(),
                                                qty = $('#qty').val(),
                                                discount = $('#discount').val(),
                                                attachment_scan = $("#attachment").val();
                                            let store_id, section_id, item_point;
                                            if (to_farmer === 'کۆمپانیا') {
                                                store_id = $('#store_id').val()
                                                section_id = $('#section_id').val()
                                            } else {
                                                store_id = 0
                                                section_id = 0
                                            }
                                            if (item_type === 'خشن') {
                                                item_point = $('#item_point').val()
                                            } else {
                                                item_point = 0
                                            }
                                            if (item_type === 'جۆر') {
                                                toast.error('تکایە جۆری بەرهەم دیار بکە');
                                                return;
                                            }
                                            if (item_point === '' && item_type === 'خشن') {
                                                toast.error('تکایە درجةی بەرهەم دیار بکە');
                                                return;
                                            }
                                            if (price === '') {
                                                toast.warning('نرخت لەبیرچوو');
                                                return;
                                            }
                                            if (qty === '') {
                                                toast.warning('بڕی بەرهەمت لەبیرچوو');
                                                return;
                                            }
                                            axios.post('/return/content', {
                                                attachment_scan: attachment_scan,
                                                return_header_id: returnHeader.id,
                                                item_id: returnHeader.item_id,
                                                store_id,
                                                section_id,
                                                item_point,
                                                price,
                                                qty,
                                                discount
                                            }).then((res) => {
                                                if (res.data !== "done") {
                                                    toast.error(res.data);
                                                    return;
                                                } else {
                                                    axios.put(`/header/status/${returnHeader_id}`).then(res => {
                                                        if (res.data == 'done') {
                                                            toast.success('بەسەرکەوتویی وەرگیرا');
                                                            setTimeout(() => {
                                                                window.location.assign('/onhold');
                                                            }, 500);
                                                        } else {
                                                            toast.error('هەڵەیەک هەیە');
                                                        }
                                                    })
                                                }
                                            })

                                        }} >
                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="mt-1 feather feather-save"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z" /><polyline points="17 21 17 13 7 13 7 21" /><polyline points="7 3 7 8 15 8" /></svg>
                                            <span className="mx-2">جێ بەجێکردن</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default NewReturn;