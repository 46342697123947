import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link, useParams } from "react-router-dom";
import commaNumber from "comma-number";
import { toast } from "react-toastify";

const ProfileSold = () => {
    const farmer_id = useParams().id

    const [farmer, setFarmer] = useState([]);
    const getFarmer = async () => {
        const { data } = await axios.get(`/profile/farmer/${farmer_id}`);
        setFarmer(data[0]);
    };

    const [stats, setStats] = useState([]);
    const getStats = async () => {
        const { data } = await axios.get(`/statistics/${farmer_id}`);
        setStats(data);
    };

    const [sale, setSale] = useState([]);
    const getLogSale = async () => {
        const { data } = await axios.get(`/profile-sale/${farmer_id}`);
        setSale(data);
    }

    const [sale_payment, setSalePayment] = useState([]);
    const getSalePayment = async () => {
        const { data } = await axios.get(`/profile-sale_payment/${farmer_id}`);
        setSalePayment(data);
    }

    const [sale_debt, setSaleDebt] = useState([]);
    const getSaleDebt = async () => {
        const { data } = await axios.get(`/profile-sale_debt/${farmer_id}`);
        setSaleDebt(data);
    }

    const [profileTotal, setProfileTotal] = useState([]);
    const getProfileTotal = async () => {
        const { data } = await axios.get(`/profile-total/${farmer_id}`);
        setProfileTotal(data);
    }

    const [pacc, setpacc] = useState("");
    useEffect(() => {
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
        axios.get('/profile-access').then((res) => {
            setpacc(res.data)
        })
        getFarmer();
        getStats();
        getLogSale();
        getSalePayment();
        getSaleDebt();
        getProfileTotal();
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">دەستپێک</Link></li>
                    <li className="breadcrumb-item active" aria-curobj="page">پرۆفایلی جوتیار</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body dt-tbl">
                            <p className="text-muted text-center mb-4 pb-2">سەرژمێریی گشتی لەنێوان جوتیار {farmer.farmer_name} و کۆمپانیا.</p>
                            <div className="text-center">
                                <img src={`../../../${farmer.image ? farmer.image : 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22200%22%20height%3D%22200%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20200%20200%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_187c93e4aea%20text%20%7B%20fill%3Argba(255%2C255%2C255%2C.75)%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_187c93e4aea%22%3E%3Crect%20width%3D%22200%22%20height%3D%22200%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2274.4296875%22%20y%3D%22104.5%22%3E200x200%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E'}`} className="img-thumbnail mx-auto d-block mb-4" alt="profile" style={{ height: '205px' }} />
                                <h4 className="mb-2 mt-4">{farmer.full_name}</h4>
                                <h4 className="mb-3">{farmer.phone}</h4>
                                <div className="text-center">
                                    <h6 className="card-title mb-0">تێکڕای کرین</h6>
                                </div>
                                <h4 className="mb-2 m-1">{stats.sale ? commaNumber(stats.sale.qty) : 0} طن</h4>
                                <div className="text-center">
                                    <p className="text-primary"><span>کۆی گشتی {stats.sale ? commaNumber(stats.sale.total) : 0} $</span></p>
                                </div>
                                <div className="text-center">
                                    <p className="text-success"><span>کۆی گشتی دوای داشکاندن {stats.sale ? commaNumber(stats.sale.total_disc) : 0} $</span></p>
                                </div>
                            </div>
                            <div className="row mb-5 mt-5">
                                <div className="col-md-12 mb-3 stretch-card grid-margin grid-margin-md-0">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="mt-3 mb-4">لیستی کڕینی تۆو</h4>
                                            <table className="table table-striped table-sm sameer" style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>جۆر</th>
                                                        <th>قەبارە</th>
                                                        <th>بڕ</th>
                                                        <th>نرخی(کیلۆ)</th>
                                                        <th>کۆی گشتی</th>
                                                        <th>داشکاندن</th>
                                                        <th>کۆی گشتی%</th>
                                                        <th>پارەی واسل</th>
                                                        <th>پارەی ماوە</th>
                                                        <th>تۆماربوون</th>
                                                        <th>بژاردە</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {sale.map((item) => (
                                                        <tr key={item.id}>
                                                            <td>{`ژ.${item.id}`}</td>
                                                            <td>{item.item_name}</td>
                                                            <td>{item.item_size}</td>
                                                            <td>{commaNumber(item.qty)} طن</td>
                                                            <td>{commaNumber((item.price))} {item.currency}</td>
                                                            <td>{commaNumber(item.total)} {item.currency}</td>
                                                            <td>{item.discount}</td>
                                                            <td>{commaNumber(item.total_disc)} {item.currency}</td>
                                                            <td>{commaNumber(item.amount_paid)} {item.currency}</td>
                                                            <td>{commaNumber(item.amount_unpaid)} {item.currency}</td>
                                                            <td>{new Date(item.updated).toLocaleDateString()}</td>
                                                            <td>
                                                                <Link to={`/sale/invoice/${item.id}`} target={'_blank'} className="badge bg-dark mx-1">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-printer"><polyline points="6 9 6 2 18 2 18 9" /><path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2" /><rect x={6} y={14} width={12} height={8} /></svg>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-7 mb-3 stretch-card grid-margin grid-margin-md-0">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="mt-3 mb-4">لیستی پارەدانی کڕین</h4>
                                            <table className="table table-striped table-sm sameer" style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>بڕی واسل</th>
                                                        <th>لەلایەن</th>
                                                        <th>لە فاتورەی</th>
                                                        <th>جێبەجێکار</th>
                                                        <th>لەڕێککەوتی</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {sale_payment.map((item, index) => (
                                                        <tr key={item.id}>
                                                            <td>{index + 1}</td>
                                                            <td>{commaNumber(item.amount_paid)} $</td>
                                                            <td>{item.farmer_name}</td>
                                                            <td>{`ژ.${item.sale_header_id}`}</td>
                                                            <td>{item.accountant_name}</td>
                                                            <td>{new Date(item.created).toLocaleDateString()}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 mb-3 stretch-card grid-margin grid-margin-md-0">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="mt-3 mb-4">لیستی قەرزەکانی کڕین</h4>
                                            <table className="table table-striped table-sm sameer" style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th>فاتورە</th>
                                                        <th>بڕی قەرز</th>
                                                        <th>لە کۆی</th>
                                                        <th>واسل کراوە</th>
                                                        <th>جێبەجێکار</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {sale_debt.map((item) => (
                                                        <tr key={item.id}>
                                                            <td>{`ژ.${item.sale_header_id}`}</td>
                                                            <td>{commaNumber(item.amount_unpaid)} $</td>
                                                            <td>{commaNumber(item.total)} $</td>
                                                            <td>{commaNumber(item.amount_paid)} $</td>
                                                            <td>{item.accountant_name}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-5 mt-4">
                                <div className="col-md-3">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">کۆی گشتی </h5>
                                                    <span className="h4 font-weight-bold mb-0" id="total_amount"> {commaNumber(profileTotal.total_sale + profileTotal.total_production - farmer.forgiveness)} $ </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">واصل</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="total_amount"> {commaNumber(profileTotal.total_sale_paid + profileTotal.total_production_paid)} $ </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">ماوە (باقی)</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="amount_paid"> {commaNumber(profileTotal.total_sale_debt + profileTotal.total_production_debt - farmer.forgiveness)} $ </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">پارەی پێشوەختە</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="amount_unpaid"> {commaNumber(profileTotal.prepaid)} $ </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {pacc.role == "ژمێریار" &&
                                    <div className="col-md-6 mt-5">
                                        <div className="card card-stats mb-4 mb-xl-0">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="mb-3">
                                                            <label htmlFor="forgived_by" className="form-label">لەلایەن</label>
                                                            <input type="text" className="form-control" id="forgived_by" name="forgived_by" defaultValue={farmer.forgived_by} />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="forgiveness" className="form-label">بڕی لێخۆشبوون</label>
                                                            <input type="text" className="form-control" id="forgiveness" name="forgiveness" defaultValue={farmer.forgiveness} />
                                                        </div>
                                                        <div className="mb-3">
                                                            <button type="submit" className="btn btn-primary"
                                                                onClick={() => {
                                                                    axios.put(`/forgive/farmer/${farmer.id}`, {
                                                                        forgiveness: document.getElementById('forgiveness').value,
                                                                        forgived_by: document.getElementById('forgived_by').value
                                                                    }).then((res) => {
                                                                        if (res.data !== 'done') {
                                                                            toast.error('هەڵەیەک هەیە');
                                                                        } else {
                                                                            window.location.reload()
                                                                        }
                                                                    })
                                                                }} >پاشەکەوتکردن</button>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="col text-center">
                                                            <h5 className="card-title text-uppercase text-muted mb-1">کۆی گشتی</h5>
                                                            <span className="h4 font-weight-bold mb-0" id="total_qty"> {commaNumber(profileTotal.total_sale + profileTotal.total_production)}$ </span>
                                                        </div>
                                                        <div className="col text-center mt-4">
                                                            <h5 className="card-title text-uppercase text-muted mb-1">لێخۆشبوون</h5>
                                                            <span className="h4 font-weight-bold mb-0" id="total_qty"> {commaNumber(farmer.forgiveness)}$ </span>
                                                            <h6 className="card-title text-uppercase text-muted mb-1">لەلایەن: {farmer.forgived_by ? farmer.forgived_by : '-'}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                        <div className="container-fluid mt-5 mb-5 w-100 text-end">
                            <button className="btn btn-primary" onClick={() => { window.print() }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-printer"><polyline points="6 9 6 2 18 2 18 9" /><path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2" /><rect x={6} y={14} width={12} height={8} /></svg>
                                <span className="mx-2">چاپکردن</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ProfileSold;