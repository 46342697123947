import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import commaNumber from "comma-number";

const ReturnLogs = () => {
    const [returnContent, setReturnContent] = useState([]);
    const getReturnContent = async () => {
        const { data } = await axios.get(`/return/logs`);
        setReturnContent(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [0, 1, 2, 3, 4, 5, 6, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
                                }
                            ]
                        })
                        $('div.dataTables_wrapper div.dataTables_filter').css({
                            'float': 'left',
                            'position': 'relative',
                            'top': '-15px'
                        })
                        $('div.dataTables_wrapper div.dataTables_length label').css({
                            'position': 'relative',
                            'top': '20px'
                        })
                    }

                    var api = window.$('.dt-tbl').DataTable();
                    var total_qty = 0;
                    var total_amount = 0;
                    var total_qty_dis = 0
                    var total_amount_dis = 0
                    new window.$.fn.dataTable.FixedHeader(api);

                    var totalqty = api.column(7, { search: 'applied' }).data()
                    totalqty.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_qty += parseFloat(item) })

                    var totalamount = api.column(8, { search: 'applied' }).data()
                    totalamount.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_amount += parseFloat(item) })

                    var totalqtydis = api.column(9, { search: 'applied' }).data()
                    totalqtydis.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_qty_dis += parseFloat(item) })

                    var totalamountdis = api.column(10, { search: 'applied' }).data()
                    totalamountdis.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_amount_dis += parseFloat(item) })

                    $('#total_qty').html(`${commaNumber(total_qty.toFixed(3))} طن`);
                    $('#total_amount').html(`${commaNumber(total_amount.toFixed(3))} د.ع`);
                    $('#total_qty_dis').html(`${commaNumber(total_qty_dis.toFixed(3))} طن`);
                    $('#total_amount_dis').html(`${commaNumber(total_amount_dis.toFixed(3))} د.ع`);

                    window.$('.dt-tbl').DataTable().on('search.dt', function () {
                        total_qty = 0;
                        total_amount = 0;
                        total_qty_dis = 0
                        total_amount_dis = 0
                        var totalqty = api.column(7, { search: 'applied' }).data()
                        totalqty.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_qty += parseFloat(item) })
                        var totalamount = api.column(8, { search: 'applied' }).data()
                        totalamount.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_amount += parseFloat(item) })
                        var totalqtydis = api.column(9, { search: 'applied' }).data()
                        totalqtydis.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_qty_dis += parseFloat(item) })
                        var totalamountdis = api.column(10, { search: 'applied' }).data()
                        totalamountdis.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_amount_dis += parseFloat(item) })

                        $('#total_qty').html(`${commaNumber(total_qty.toFixed(3))} طن`);
                        $('#total_amount').html(`${commaNumber(total_amount.toFixed(3))} د.ع`);
                        $('#total_qty_dis').html(`${commaNumber(total_qty_dis.toFixed(3))} طن`);
                        $('#total_amount_dis').html(`${commaNumber(total_amount_dis.toFixed(3))} د.ع`);
                    });
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    };
    useEffect(() => {
        getReturnContent();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);


    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">دەستپێک</Link></li>
                    <li className="breadcrumb-item active" aria-curobj="page">تۆماری وەرگرتنەوە</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">تۆماری وەرگرتنەوەی بەرهەمەکان</h6>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>ژ.فاتورە</th>
                                        <th>بەرهەم</th>
                                        <th>بۆ</th>
                                        <th>گروپ</th>
                                        <th>جۆر</th>
                                        <th>دەرەجە</th>
                                        <th>نرخ</th>
                                        <th>بڕ</th>
                                        <th>داشکاندن</th>
                                        <th>بڕی دوای داشکاندن</th>
                                        <th>کۆی گشتی%</th>
                                        <th>کۆگا</th>
                                        <th>کەرت</th>
                                        <th>جوتیار</th>
                                        <th>شوفێر</th>
                                        <th>کرێی شوفێر</th>
                                        <th>هاوپێچ</th>
                                        <th>وێنەی فەحس</th>
                                        <th>ژ.مۆبایل</th>
                                        <th>ژ.تابلۆ</th>
                                        <th>جێبەجێکار</th>
                                        <th>کۆد</th>
                                        <th>ڕێککەوت</th>
                                        <th>کات</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {returnContent.map((item, index) => (
                                        <tr key={index}>
                                            <td> <a href={`/returns/log/${item.id}`} target="_blank" rel="noopener noreferrer" > {`ژم.${item.invoice_no}`}</a>  </td>
                                            <td>{item.item_name}</td>
                                            <td>{item.to_farmer == 0 ? 'کۆمپانیا' : 'جوتیار'}</td>
                                            <td>{item.industrial == 0 ? 'استهلاکی' : 'صناعی'}</td>
                                            <td>{item.item_type}</td>
                                            <td>{item.item_point == 0 ? '-' : item.item_point}</td>
                                            <td>{commaNumber(item.price)} د.ع</td>
                                            <td>{commaNumber(item.qty)} طن</td>
                                            <td>{commaNumber(item.discount)}%</td>
                                            <td>{commaNumber(item.disc_qty.toFixed(3))} طن</td>
                                            <td>{commaNumber(item.disc_total.toFixed(3))} د.ع</td>
                                            <td>{item.store_name}</td>
                                            <td>{item.section_name}</td>
                                            <td>{item.farmer_name}</td>
                                            <td>{item.driver_name}</td>
                                            <td>{commaNumber(item.driver_rent)} د.ع</td>
                                            <td>{
                                                item.attachment ? (
                                                    <a href={`../../../${item.attachment}`} target="_blank">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="text-dark feather feather-paperclip"><path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" /></svg>
                                                    </a>
                                                ) : (
                                                    <span className="badge bg-danger">نییە</span>
                                                )
                                            }
                                            </td>
                                            <td>{
                                                item.attachment_scan ? (
                                                    <a href={`../../../${item.attachment_scan}`} target="_blank">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="text-dark feather feather-paperclip"><path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" /></svg>
                                                    </a>
                                                ) : (
                                                    <span className="badge bg-danger">نییە</span>
                                                )
                                            }
                                            </td>
                                            <td>{item.driver_phone}</td>
                                            <td>{item.plate_number}</td>
                                            <td>{item.accountant_name}</td>
                                            <td>{item.code_chawder}</td>
                                            <td>{new Date(item.created).toLocaleDateString()}</td>
                                            <td>{item.submit_date}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="row mt-4">
                                <div className="col-md-4">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">بڕی گشتی</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="total_qty"> 0 طن </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">بڕی دوای داشکاندن</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="total_qty_dis"> 0 طن </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">کۆی گشتی پارە دوای داشکاندن</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="total_amount_dis"> 0 د.ع </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 text-end mt-4">
                                    <button className="btn btn-primary" onClick={() => { window.print() }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-printer"><polyline points="6 9 6 2 18 2 18 9" /><path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2" /><rect x={6} y={14} width={12} height={8} /></svg>
                                        <span className="mx-2">چاپکردن</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReturnLogs;