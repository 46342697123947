import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import commaNumber from "comma-number";
import { toast } from "react-toastify";

const ReturnList = () => {
    const navigate = useNavigate();
    const [returnHeader, setReturnHeader] = useState([]);
    const [returnContent, setReturnContent] = useState([]);
    const getReturnContent = async () => {
        const { data } = await axios.get(`/return/list`);
        setReturnContent(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [0]
                                },
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [1]
                                },
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [2]
                                },
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [3]
                                },
                                {
                                    searchPanes: {
                                        show: false
                                    },
                                    targets: [7]
                                },
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [8]
                                },
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [9]
                                },
                            ]
                        })
                        $('div.dataTables_wrapper div.dataTables_filter').css({
                            'float': 'left',
                            'position': 'relative',
                            'top': '-15px'
                        })
                        $('div.dataTables_wrapper div.dataTables_length label').css({
                            'position': 'relative',
                            'top': '20px'
                        })
                    }
                    var api = window.$('.dt-tbl').DataTable();
                    let qty = 0;
                    new window.$.fn.dataTable.FixedHeader(api);
                    var dataQty = api.column(5, { search: 'applied' }).data();
                    dataQty.map((item) => {
                        if (isNaN(item)) {
                            item = item.replace(/,/g, '');
                        }
                        qty += parseFloat(item)
                    })
                    $('#total_qty').html(`${commaNumber(qty.toFixed(5))} طن`);
                    window.$('.dt-tbl').DataTable().on('search.dt', function () {
                        qty = 0;
                        var dataQty = api.column(5, { search: 'applied' }).data();
                        dataQty.map((item) => {
                            if (isNaN(item)) {
                                item = item.replace(/,/g, '');
                            }
                            qty += parseFloat(item)
                        })
                        $('#total_qty').html(`${commaNumber(qty.toFixed(3))} طن`);
                    });
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    };

    const [auth, setAuth] = useState(false);
    useEffect(() => {
        axios.get("/auth").then((res) => {
            if (res.data !== "err") {
                if (res.data == "چاودێر") {
                    $(".auth").remove();
                    setAuth(false);
                } else {
                    setAuth(true);
                }
            }
        });
        getReturnContent();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    const upload = (id) => {
        $('<input type="file" accept="image/*" />').click().on('change', async (e) => {
            var formData = new FormData();
            formData.append('file', e.target.files[0]);
            await axios.post('/upload', formData).then(res => {
                if (res.data !== "err") {
                    $(`#attachment${id}`).val(res.data);
                    $(`#upattachment${id}`).append(`<img src="../../../${res.data}" class="img-thumbnail viewImg${id}" alt=${res.data} />`)
                }
                if (res.data === "limited") {
                    toast.error('قەبارەی رسمەکە زۆر گەورەیە پێویستە لە 700 کێلۆ بایت کەمتربێت!')
                    return
                }
                $(`.viewImg${id}`).click(function () {
                    $(this).remove()
                    axios.delete(`/upload/${$(this).attr('alt')}`).then(res => {
                        if (res.data !== "err") {
                            $(`#attachment${id}`).val('');
                        } else {
                            toast.error('هەڵەیەک هەیە');
                        }
                    })
                })
            })
        })
    }

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">دەستپێک</Link></li>
                    <li className="breadcrumb-item active" aria-curobj="page">لیستی بەرهەمەکان</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">لیستی بەرهەمەکان</h6>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>ژ.فاتورە</th>
                                        <th>جوتیار</th>
                                        <th>بەرهەم</th>
                                        <th>جۆر</th>
                                        <th>بۆ</th>
                                        <th>بڕ</th>
                                        <th>چاودێر</th>
                                        <th>ژمێریار</th>
                                        <th>وێنەی فەحس</th>
                                        <th>ڕێککەوت</th>
                                        <th>کات</th>
                                        <th className="auth">بژاردە</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {returnContent.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.invoice_no ? `ژ.${item.invoice_no}` : '-'}</td>
                                            <td>{item.farmer_name}</td>
                                            <td>{item.item_name}</td>
                                            <td>{item.industrial == 0 ? 'استهلاکی' : 'صناعی'}</td>
                                            <td>{item.to_farmer == 0 ? 'کۆمپانیا' : 'جوتیار'}</td>
                                            <td>{commaNumber(item.qty)} طن</td>
                                            <td>{item.chawder_name ? item.chawder_name : '-'}</td>
                                            <td>{item.accountant_name}</td>
                                            <td>{
                                                item.attachment_scan ? (
                                                    <a href={`../../../${item.attachment_scan}`} target="_blank">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="text-dark feather feather-paperclip"><path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" /></svg>
                                                    </a>
                                                ) : (
                                                    <span className="badge bg-danger">نییە</span>
                                                )
                                            }
                                            </td>
                                            <td>{new Date(item.created).toLocaleDateString()} </td>
                                            <td>{item.submit_date} </td>
                                            {auth &&
                                                <>
                                                    <td className="auth">
                                                        <Link to={`/returns/log/${item.id}`} className="badge bg-primary mx-1">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" /><circle cx={12} cy={12} r={3} /></svg>
                                                        </Link>
                                                        <Link to={`/returns/list/${item.id}`} className="badge bg-success mx-1">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-percent"><line x1={19} y1={5} x2={5} y2={19} /><circle cx="6.5" cy="6.5" r="2.5" /><circle cx="17.5" cy="17.5" r="2.5" /></svg>
                                                        </Link>
                                                        <span type="button" className="badge bg-warning mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`} onClick={() => {
                                                            axios.get(`/return/header/${item.id}`).then(res => {
                                                                setReturnHeader(res.data[0])
                                                            })
                                                        }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                                                        </span>
                                                    </td>
                                                    <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                        <div className="modal-dialog modal-lg">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title"> دەستکاریکردن </h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                                </div>
                                                                <div className="modal-body">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="industrial" className="col-form-label">بۆ: کۆمپانیا/جوتیار؟</label>
                                                                        <select name="to_farmer" className="form-select" defaultValue={item.to_farmer} required>
                                                                            <option value={0} selected>کۆمپانیا</option>
                                                                            <option value={1} selected>جوتیار</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="item_type" className="col-form-label">خشن/ناعم؟</label>
                                                                        <select name="item_type" className="form-select" defaultValue={item.item_type} required >
                                                                            <option value="خشن">خشن</option>
                                                                            <option value="ناعم">ناعم</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="industrial" className="col-form-label">جۆری بەرهەم</label>
                                                                        <select className="form-select" name="industrial" id={`industrial${item.id}`} defaultValue={returnHeader.industrial} required>
                                                                            <option value={returnHeader.industrial}>{item.industrial == 0 ? 'استهلاکی' : 'صناعی'}</option>
                                                                            <option value="0">استهلاکی</option>
                                                                            <option value="1">صناعی</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="sack">ژمارەی کیس ؟</label>
                                                                        <input type="number" className="form-control" name="sack" id={`sack${item.id}`} defaultValue={returnHeader.sack} />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="invoice_no">ژ. فاتورە</label>
                                                                        <input className="form-control" name="invoice_no" id={`invoice_no${item.id}`} defaultValue={returnHeader.invoice_no} />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="vehicle_type">جۆری بارهەلگر</label>
                                                                        <input className="form-control" name="vehicle_type" id={`vehicle_type${item.id}`} defaultValue={returnHeader.vehicle_type} />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="plate_number">ژ. بارهەلگر</label>
                                                                        <input className="form-control" name="plate_number" id={`plate_number${item.id}`} defaultValue={returnHeader.plate_number} />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="driver_name">ناوی شوفێر</label>
                                                                        <input className="form-control" name="driver_name" id={`driver_name${item.id}`} defaultValue={returnHeader.driver_name} />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="driver_phone">ژ. شوفێر</label>
                                                                        <input className="form-control" name="driver_phone" id={`driver_phone${item.id}`} defaultValue={returnHeader.driver_phone} />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label> هاوپێچ</label>
                                                                        <input type="hidden" name="attachment" id={`attachment${item.id}`} defaultValue={returnHeader.attachment} />
                                                                        <div className="form-control text-center" onClick={() => upload(item.id)}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                                                                        </div>
                                                                        <br />
                                                                        <div align="center" id={`upattachment${item.id}`}>
                                                                            {returnHeader.attachment && <img src={`../../../${returnHeader.attachment}`} className={`img-thumbnail viewImg${item.id}`} alt={returnHeader.attachment} />}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                                    <button type="submit" className="btn btn-primary" onClick={() => {
                                                                        axios.put(`/return/header/${item.id}`, {
                                                                            to_farmer: $(`#to_farmer${item.id}`).val(),
                                                                            item_type: $(`#item_type${item.id}`).val(),
                                                                            industrial: $(`#industrial${item.id}`).val(),
                                                                            sack: $(`#sack${item.id}`).val(),
                                                                            invoice_no: $(`#invoice_no${item.id}`).val(),
                                                                            vehicle_type: $(`#vehicle_type${item.id}`).val(),
                                                                            plate_number: $(`#plate_number${item.id}`).val(),
                                                                            driver_name: $(`#driver_name${item.id}`).val(),
                                                                            driver_phone: $(`#driver_phone${item.id}`).val(),
                                                                            attachment: $(`#attachment${item.id}`).val(),
                                                                        }).then(res => {
                                                                            if (res.data !== "done") {
                                                                                toast.error(res.data)
                                                                            } else {
                                                                                getReturnContent();
                                                                                window.$(`#edit${item.id}`).modal('hide');
                                                                                setTimeout(() => {
                                                                                    navigate(`/return/edit/${item.id}`);
                                                                                }, 300);
                                                                            }
                                                                        });
                                                                    }}>هەنگاوی دواتر</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="row mt-4">
                                <div className="col-md-4">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">بڕی گشتی</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="total_qty"> 0 طن </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-md-4">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">پشی کۆمپانیا</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="company_data"> 0 طن (0%)</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">پشکی جوتیار</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="farmer_data"> 0 طن (0%)</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReturnList;