import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import $ from "jquery";

const Siebar = () => {
    const navigate = useNavigate();
    useEffect(() => {
        axios.get("/auth").then((res) => {
            if (res.data !== "err") {
                if (res.data == "چاودێر") {
                    $(".auth").remove();
                } else {
                    $(".chawder").remove();
                }
            } else {
                navigate("/");
            }
        });
    }, []);

    return (
        <nav className="sidebar">
            <div className="sidebar-header">
                <Link to="/dashboard" className="sidebar-brand">
                    K.H<span> Company</span>
                </Link>
                <div className="sidebar-toggler not-active" onClick={() => {
                    $('.sidebar-header .sidebar-toggler').toggleClass('active not-active');
                    if (window.matchMedia('(min-width: 992px)').matches) {
                        $('body').toggleClass('sidebar-folded');
                    } else if (window.matchMedia('(max-width: 991px)').matches) {
                        $('body').toggleClass('sidebar-open');
                    }
                    $(".sidebar .sidebar-body").hover(function () {
                        if ($('body').hasClass('sidebar-folded')) {
                            $('body').addClass("open-sidebar-folded");
                        }
                    }, function () {
                        if ($('body').hasClass('sidebar-folded')) {
                            $('body').removeClass("open-sidebar-folded");
                        }
                    });
                }}>
                    <span />
                    <span />
                    <span />
                </div>
            </div>
            <div className="sidebar-body">
                <ul className="nav">
                    <li className="nav-item nav-category">سەرەکی</li>
                    <li className="nav-item auth">
                        <Link to="/dashboard" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-hash"><line x1={4} y1={9} x2={20} y2={9} /><line x1={4} y1={15} x2={20} y2={15} /><line x1={10} y1={3} x2={8} y2={21} /><line x1={16} y1={3} x2={14} y2={21} /></svg>
                            <span className="link-title">دەستپێک</span>
                        </Link>
                    </li>
                    <li className="nav-item auth">
                        <Link to="/order/list" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" /><polyline points="7 10 12 15 17 10" /><line x1={12} y1={15} x2={12} y2={3} /></svg>
                            <span className="link-title">لیستی هاوردە</span>
                        </Link>
                    </li>
                    <li className="nav-item auth">
                        <Link to="/sale/list" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" /><polyline points="22 4 12 14.01 9 11.01" /></svg>
                            <span className="link-title">لیستی فرۆشتن</span>
                        </Link>
                    </li>
                    <li className="nav-item auth">
                        <Link to="/production/list" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-anchor"><circle cx={12} cy={5} r={3} /><line x1={12} y1={22} x2={12} y2={8} /><path d="M5 12H2a10 10 0 0 0 20 0h-3" /></svg>
                            <span className="link-title">لیستی چاندن</span>
                        </Link>
                    </li>
                    <li className="nav-item chawder">
                        <Link to="/accounts/farmers" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-anchor"><circle cx={12} cy={5} r={3} /><line x1={12} y1={22} x2={12} y2={8} /><path d="M5 12H2a10 10 0 0 0 20 0h-3" /></svg>
                            <span className="link-title">لیستی جوتیار</span>
                        </Link>
                    </li>
                    <li className="nav-item auth">
                        <Link to="/returns/list" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-refresh-ccw"><polyline points="1 4 1 10 7 10" /><polyline points="23 20 23 14 17 14" /><path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15" /></svg>
                            <span className="link-title">لیستی بەرهەم</span>
                        </Link>
                    </li>
                    <li className="nav-item nav-category">ڕاپۆرتەکان</li>
                    <li className="nav-item auth">
                        <Link to="/report/items/balance" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-git-branch"><line x1={6} y1={3} x2={6} y2={15} /><circle cx={18} cy={6} r={3} /><circle cx={6} cy={18} r={3} /><path d="M18 9a9 9 0 0 1-9 9" /></svg>
                            <span className="link-title">تۆی بەردەست</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/onhold" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-truck"><rect x={1} y={3} width={15} height={13} /><polygon points="16 8 20 8 23 11 23 16 16 16 16 8" /><circle cx="5.5" cy="18.5" r="2.5" /><circle cx="18.5" cy="18.5" r="2.5" /></svg>
                            <span className="link-title">لیستی بارەکان</span>
                        </Link>
                    </li>
                    <li className="nav-item auth">
                        <Link to="/returns/log" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2" /><polyline points="2 17 12 22 22 17" /><polyline points="2 12 12 17 22 12" /></svg>
                            <span className="link-title">بەرهەم بەگشتی</span>
                        </Link>
                    </li>
                    <li className="nav-item auth">
                        <a className="nav-link" data-bs-toggle="collapse" href="#payments" role="button" aria-expanded="false" aria-controls="payments">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-credit-card"><rect x={1} y={4} width={22} height={16} rx={2} ry={2} /><line x1={1} y1={10} x2={23} y2={10} /></svg>
                            <span className="link-title">تۆماری پارەدان</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-arrow feather feather-chevron-down"><polyline points="6 9 12 15 18 9" /></svg>
                        </a>
                        <div className="collapse" id="payments">
                            <ul className="nav sub-menu">
                                <li className="nav-item">
                                    <Link to="/payments/order" className="nav-link">
                                        پارەدانی کڕین
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/payments/sale" className="nav-link">
                                        پارەدانی فرۆشتن
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/payments/production" className="nav-link">
                                        پارەدانی چاندن
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item auth">
                        <a className="nav-link" data-bs-toggle="collapse" href="#debts" role="button" aria-expanded="false" aria-controls="debts">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-credit-card"><rect x={1} y={4} width={22} height={16} rx={2} ry={2} /><line x1={1} y1={10} x2={23} y2={10} /></svg>
                            <span className="link-title">تۆماری قەرزەکان</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-arrow feather feather-chevron-down"><polyline points="6 9 12 15 18 9" /></svg>
                        </a>
                        <div className="collapse" id="debts">
                            <ul className="nav sub-menu">
                                <li className="nav-item">
                                    <Link to="/debt/order" className="nav-link">
                                        قەرزی کڕین
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/debt/sale" className="nav-link">
                                        قەرزی فرۆشتن
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/debt/production" className="nav-link">
                                        قەرزی چاندن
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="collapse" href="#logs" role="button" aria-expanded="false" aria-controls="logs">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" /><polyline points="14 2 14 8 20 8" /><line x1={16} y1={13} x2={8} y2={13} /><line x1={16} y1={17} x2={8} y2={17} /><polyline points="10 9 9 9 8 9" /></svg>
                            <span className="link-title">تۆماری گشتی</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-arrow feather feather-chevron-down"><polyline points="6 9 12 15 18 9" /></svg>
                        </a>
                        <div className="collapse" id="logs">
                            <ul className="nav sub-menu">
                                <li className="nav-item auth">
                                    <Link to="/logs/order" className="nav-link">
                                        تۆماری کڕین
                                    </Link>
                                </li>
                                <li className="nav-item auth">
                                    <Link to="/logs/sale" className="nav-link">
                                        تۆماری فرۆشتن
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/logs/production" className="nav-link">
                                        تۆماری چاندن
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/production/expense" className="nav-link">
                                        خەرجیی چاندن
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item auth">
                        <Link to="/product/log" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-trending-up"><polyline points="23 6 13.5 15.5 8.5 10.5 1 18" /><polyline points="17 6 23 6 23 12" /></svg>
                            <span className="link-title">پشکی کۆمپانیا</span>
                        </Link>
                    </li>
                    <li className="nav-item auth">
                        <Link to="/stores/log" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg>
                            <span className="link-title">کۆگا و کەرت</span>
                        </Link>
                    </li>
                    <li className="nav-item nav-category auth">ڕێکخستن</li>
                    <li className="nav-item auth">
                        <Link to="/items/list" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-flag"><path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z" /><line x1={4} y1={22} x2={4} y2={15} /></svg>
                            <span className="link-title">جۆرەکان</span>
                        </Link>
                    </li>
                    <li className="nav-item auth">
                        <Link to="/items/price" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-percent"><line x1={19} y1={5} x2={5} y2={19} /><circle cx="6.5" cy="6.5" r="2.5" /><circle cx="17.5" cy="17.5" r="2.5" /></svg>
                            <span className="link-title">نرخاندن</span>
                        </Link>
                    </li>
                    <li className="nav-item auth">
                        <Link to="/sections/list" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-server"><rect x={2} y={2} width={20} height={8} rx={2} ry={2} /><rect x={2} y={14} width={20} height={8} rx={2} ry={2} /><line x1={6} y1={6} x2="6.01" y2={6} /><line x1={6} y1={18} x2="6.01" y2={18} /></svg>
                            <span className="link-title">کەرت</span>
                        </Link>
                    </li>
                    <li className="nav-item auth">
                        <Link to="/stores/list" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-database"><ellipse cx={12} cy={5} rx={9} ry={3} /><path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3" /><path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5" /></svg>
                            <span className="link-title">کۆگا</span>
                        </Link>
                    </li>
                    <li className="nav-item auth">
                        <Link to="/currencies/list" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-dollar-sign"><line x1={12} y1={1} x2={12} y2={23} /><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" /></svg>
                            <span className="link-title">دراو</span>
                        </Link>
                    </li>
                    <li className="nav-item auth">
                        <Link to="/accounts/accountants" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg>
                            <span className="link-title"> ژمێریار</span>
                        </Link>
                    </li>
                    {/* <li className="nav-item nav-category auth">ژمێریاریی </li>
                    <li className="nav-item auth">
                        <Link to="/accounts/accountants" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg>
                            <span className="link-title"> ژمێریار</span>
                        </Link>
                    </li>
                    <li className="nav-item auth">
                        <Link to="/finance/accounts" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-key"><path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4" /></svg>
                            <span className="link-title"> هەژمار</span>
                        </Link>
                    </li>
                    <li className="nav-item auth">
                        <Link to="/finance/voucher" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-arrow-up-right"><line x1={7} y1={17} x2={17} y2={7} /><polyline points="7 7 17 7 17 17" /></svg>
                            <span className="link-title"> ڤاوچەر</span>
                        </Link>
                    </li>
                    <li className="nav-item auth">
                        <Link to="/finance/expenses" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-corner-up-right"><polyline points="15 14 20 9 15 4" /><path d="M4 20v-7a4 4 0 0 1 4-4h12" /></svg>
                            <span className="link-title"> خەرجی</span>
                        </Link>
                    </li>
                    <li className="nav-item auth">
                        <Link to="/report/vouchers" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-activity"><polyline points="22 12 18 12 15 21 9 3 6 12 2 12" /></svg>
                            <span className="link-title">بارودۆخ</span>
                        </Link>
                    </li> */}
                </ul>
            </div>
        </nav>

    );
}

export default Siebar;