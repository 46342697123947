import React, { useEffect, useState } from "react";
import axios from "axios";
import commaNumber from 'comma-number';
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Select from 'react-select'

const NewOrder = () => {
    const navigate = useNavigate();
    const params = useState(localStorage.getItem("order") ? JSON.parse(localStorage.getItem("order")) : []);

    const [orderHeader, setOrderHeader] = useState(0);
    const [sections, setSections] = useState([]);
    const getOrderHeader = async () => {
        const { data } = await axios.get(`/order/header/${params[0].order_id}`);
        setOrderHeader(data[0]);
        axios.get(`/section/${data[0].store_id}`).then((res) => {
            setSections(res.data);
        });
    };

    const [orderContent, setOrderContent] = useState([]);
    const getOrderContent = async () => {
        const res = await axios.get(`/order/content/${params[0].order_id}`);
        setOrderContent(res.data);
    };

    const [orderTotal, setOrderTotal] = useState(0);
    const getOrderTotal = async () => {
        const res = await axios.get(`/order/total/${params[0].order_id}`);
        setOrderTotal(res.data[0]);
    };

    const [items, setItems] = useState([]);
    const getItems = async () => {
        const res = await axios.get(`/item`);
        setItems(res.data);
    };

    const payment = (discount, paid) => {
        let disc = parseFloat(discount);
        let pay = parseFloat(paid);
        let total = parseFloat(orderTotal.total);
        if (disc > total) {
            $("#amount_discount").val(total)
            $("#amount_pay").val(0)
            $("#amount_unpay").val(0)
        } else {
            if ($("#payment_method").val() == "pay") {
                $("#amount_pay").val() > total ? $("#amount_pay").val(total) : $("#amount_pay").val((total - disc).toFixed(3));
            } else {
                $("#amount_unpay").val() > total ? $("#amount_unpay").val(total) : $("#amount_unpay").val(total - (pay + disc).toFixed(3));
            }
        }
        $("#total_amount_discount").text(commaNumber(total ? total - disc.toFixed(3) : 0));
    }

    useEffect(() => {
        getItems();
        getOrderTotal();
        getOrderHeader();
        getOrderContent();
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">دەستپێک</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">هاوردە</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="container-fluid mt-3">
                                <div className="row">
                                    <div className="col-xl-7">
                                        <div className="form-group">
                                            <label htmlFor="customer_name"><strong>کۆمپانیا/بازرگان: </strong>{orderHeader.vendor_name}</label> <br />
                                            <label htmlFor="customer_address"><strong>ژ.مۆبایل: </strong> {orderHeader.vendor_phone}</label> <br />
                                            <label htmlFor="customer_address"><strong>بۆ کۆگای: </strong> {orderHeader.store_name}، لە {orderHeader.store_address}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid mt-4">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="table-responsive">
                                            <table className="table table-striped table-sm text-center">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>جۆر</th>
                                                        <th>قەبارە</th>
                                                        <th>نرخ</th>
                                                        <th>پێوانە</th>
                                                        <th>بڕ</th>
                                                        <th>سەرجەم</th>
                                                        <th>بژاردە</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {orderContent.map((item, index) => (
                                                        <tr key={index}>
                                                            <td> <p className="mt-2">{index + 1}</p></td>
                                                            <td> <p className="mt-2">{item.item_name}</p> </td>
                                                            <td>
                                                                <input className="form-control mt-2" name="item_size" defaultValue={item.item_size} required onChange={(e) => {
                                                                    axios.put(`/order/content/${item.id}`, { item_size: e.target.value }).then(() => {
                                                                        getOrderContent();
                                                                        getOrderTotal();
                                                                    });
                                                                }} />
                                                            </td>
                                                            <td>
                                                                <div className="input-group flex-nowrap mt-2">
                                                                    <input type="number" className="form-control" name="price" min={0} step="any" defaultValue={item.price} required onChange={(e) => {
                                                                        if (e.target.value == "" || e.target.value < 0) {
                                                                            e.target.value = 0;
                                                                        }
                                                                        axios.put(`/order/content/${item.id}`, { price: e.target.value }).then(() => {
                                                                            getOrderContent();
                                                                            getOrderTotal();
                                                                        });
                                                                    }} />
                                                                    <span className="input-group-text" id="addon-wrapping"><span className="mx-1">{orderHeader.currency}</span></span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <select className="form-select form-select-sm wpx-85 " name="weight_type" onChange={(e) => {
                                                                    axios.put(`/order/content/${item.id}`, { weight_type: e.target.value }).then(() => {
                                                                        getOrderContent();
                                                                        getOrderTotal();
                                                                    });
                                                                }} required>
                                                                    <option value="ton">طن</option>
                                                                    <option value="kg">کیلۆ</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <div className="input-group form-control-sm flex-nowrap">
                                                                    <input type="number" className="form-control" name="qty" defaultValue={item.qty} onChange={(e) => {
                                                                        if (e.target.value == "" || e.target.value < 0) {
                                                                            e.target.value = 0;
                                                                        }
                                                                        axios.put(`/order/content/${item.id}`, { qty: e.target.value }).then(() => {
                                                                            getOrderContent();
                                                                            getOrderTotal();
                                                                        });
                                                                    }} />
                                                                    <span className="input-group-text" id="addon-wrapping"><span className="mx-1">طن</span></span>
                                                                </div>
                                                            </td>
                                                            <td> <p className="mt-3">{commaNumber(item.total)} {orderHeader.currency}</p></td>
                                                            <td>
                                                                <span type="button" className="badge bg-danger mt-2" onClick={() => {
                                                                    Swal.fire({
                                                                        title: 'ئاگاداری',
                                                                        text: "ئایا دڵنیایت لە سڕینەوە ؟",
                                                                        icon: 'warning',
                                                                        showCancelButton: true,
                                                                        confirmButtonColor: '#3085d6',
                                                                        cancelButtonColor: '#d33',
                                                                        confirmButtonText: 'بەڵێ',
                                                                        cancelButtonText: 'نەخێر'
                                                                    }).then((result) => {
                                                                        if (result.isConfirmed) {
                                                                            axios.delete(`/order/content/${item.id}`).then(() => {
                                                                                Swal.fire({
                                                                                    title: 'سڕایەوە',
                                                                                    text: "بەسەرکەوتویی سڕایەوە",
                                                                                    icon: 'success',
                                                                                    showConfirmButton: false,
                                                                                    timer: 1000
                                                                                }).then(() => {
                                                                                    getOrderContent();
                                                                                    getOrderTotal();
                                                                                })
                                                                            })
                                                                        }
                                                                    })
                                                                }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash"><polyline points="3 6 5 6 21 6" /><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" /></svg>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <h5 className="card-title">دیارکردنی پەتاتە</h5>
                                        <div className="input-group mb-3">
                                            <div className="col-12">
                                                <select className="form-select" id="section">
                                                    <option value={0}>کەرت دیاری بکە</option>
                                                    {sections.map(section => (
                                                        <option key={section.id} value={section.id}>{section.section_name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="input-group mb-3">
                                            <div className="col-12">
                                                <Select options={items && items.map((item) => ({ value: item.id, label: item.item_name }))}
                                                    onChange={(e) => {
                                                        $('#section').val() == 0 ? toast.warning('کەرت دیاری بکە', { position: "top-center" }) :
                                                            axios.post('/order/content', {
                                                                order_header_id: params[0].order_id,
                                                                section_id: $('#section').val(),
                                                                item_id: e.value
                                                            }).then((res) => {
                                                                if (res.data !== 'done') {
                                                                    res.data !== "exist" ? toast.error(res.data) : toast.warning('پێشتر هاوردەکراوە');
                                                                } else {
                                                                    getOrderContent();
                                                                    getOrderTotal();
                                                                }
                                                            });
                                                    }} placeholder="لیست هەڵبژێرە" required />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid mt-5">
                                <form className="form-sample" onSubmit={(e) => {
                                    e.preventDefault();
                                    const data = {
                                        order_header_id: params[0].order_id,
                                        amount_paid: e.target.amount_pay.value,
                                        note: e.target.note.value,
                                        discount: e.target.discount.value,
                                    }
                                    axios.post(`/order/submit/${params[0].order_id}`, data).then(res => {
                                        localStorage.removeItem("order");
                                        if (res.data !== 'done') {
                                            toast.error(res.data, {
                                                position: "top-left",
                                                autoClose: 3000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                            });
                                        } else {
                                            navigate('/order/list');
                                        }
                                    });
                                }}>
                                    <div className="row">
                                        <div className="col-xl-6 ms-auto">
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="payment_method">شێوازی پارەدان</label>
                                                <select className="form-select" id="payment_method" name="payment_method" required onChange={(e) => {
                                                    if (e.target.value == "pay") {
                                                        $("#amount_pay").val(orderTotal.total);
                                                        $("#amount_unpay").val(0);
                                                        $("#amount_discount").val(0);
                                                        $("#amount_pay").prop("disabled", true);
                                                        $("#amount_unpay").prop("disabled", true);
                                                    } else {
                                                        $("#amount_pay").val(0);
                                                        $("#amount_unpay").val(orderTotal.total);
                                                        $("#amount_discount").val(0);
                                                        $("#amount_pay").prop("disabled", false);
                                                        $("#amount_unpay").prop("disabled", true);
                                                    }
                                                }}>
                                                    <option value="">.......</option>
                                                    <option value="unpay">قەرز/بڕێک واسل</option>
                                                    <option value="pay">نەقدی</option>
                                                </select>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="discount">داشکاندن</label>
                                                <div className="input-group flex-nowrap">
                                                    <span className="input-group-text" id="addon-wrapping"><span className="mx-1">-</span></span>
                                                    <input type="number" className="form-control" id="amount_discount" name="discount" min={0} step="any" defaultValue={0} onChange={(e) => {
                                                        e.target.value < 0 ? e.target.value = 0 : payment(e.target.value, $('#amount_pay').val())
                                                    }} />
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="amount_pay">بڕی واسل</label>
                                                <div className="input-group flex-nowrap">
                                                    <input type="number" className="form-control" id="amount_pay" name="amount_pay" min={0} step="any" defaultValue={0} onChange={(e) => {
                                                        e.target.value < 0 ? e.target.value = 0 : payment($('#amount_discount').val(), e.target.value)
                                                    }} />
                                                    <span className="input-group-text" id="addon-wrapping"><span className="mx-1">{orderHeader.currency}</span></span>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="amount_unpay">بڕی ماوە</label>
                                                <div className="input-group flex-nowrap">
                                                    <input type="number" className="form-control" id="amount_unpay" name="amount_unpay" min={0} step="any" defaultValue={0} onChange={(e) => {
                                                        e.target.value < 0 ? e.target.value = 0 : payment($('#amount_discount').val(), $('#amount_pay').val())
                                                    }} />
                                                    <span className="input-group-text" id="addon-wrapping"> <span className="mx-1">{orderHeader.currency}</span></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 ms-auto px-5 mb-4 mt-3">
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="note">تێبینی</label>
                                                <textarea className="form-control" id="note" name="note" rows={3} defaultValue={""} placeholder="تێبینی ..." />
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td>بڕ</td>
                                                            <td className="text-end">{orderTotal ? commaNumber(orderTotal.qty) : 0} طن</td>
                                                        </tr>
                                                        <tr>
                                                            <td>نرخی مامناوەند</td>
                                                            <td className="text-end">
                                                                <span className="mx-1">{orderTotal ? orderTotal.price : 0} {orderHeader.currency}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-bold-800">سەرجەم</td>
                                                            <td className="text-bold-800 text-end">
                                                                {orderTotal ? commaNumber(orderTotal.total) : 0}  <span className="mx-1">{orderHeader.currency}</span>
                                                            </td>
                                                        </tr>
                                                        <tr className="bg-dark text-white">
                                                            <td className="text-bold-800">کۆی گشتی (داشکاندن)</td>
                                                            <td className="text-bold-800 text-end">
                                                                <span className="mx-1">{orderHeader.currency}</span>
                                                                <span id="total_amount_discount">{orderTotal ? commaNumber(orderTotal.total) : 0}</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-xl-12">
                                            <div className="mb-3 float-end">
                                                <button type="button" className="btn btn-danger"
                                                    onClick={() => {
                                                        Swal.fire({
                                                            title: 'ئایا دڵنیایت؟',
                                                            text: "بەیەکجاری دەسڕێتەوە و ناتوانرێت بگەڕێندرێتەوە !",
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#3085d6',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: 'بەڵێ',
                                                            cancelButtonText: 'نەخێر'
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                axios.delete(`/order/submit/${params[0].order_id}`).then(res => {
                                                                    localStorage.removeItem("order");
                                                                    if (res.data !== 'done') {
                                                                        toast.error(res.data, {
                                                                            position: "top-left",
                                                                            autoClose: 3000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                        });
                                                                    } else {
                                                                        navigate('/order/list');
                                                                    }
                                                                });
                                                            }
                                                        })
                                                    }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1={18} y1={6} x2={6} y2={18} /><line x1={6} y1={6} x2={18} y2={18} /></svg>
                                                    <span className="mx-2">سڕینەوە</span>
                                                </button>
                                                <button type="submit" className="btn btn-primary mx-2" >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" /><polyline points="7 10 12 15 17 10" /><line x1={12} y1={15} x2={12} y2={3} /></svg>
                                                    <span className="mx-2">کڕین</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div >
            </div>
        </div>
    );
}

export default NewOrder;