import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link, useParams } from "react-router-dom";
import commaNumber from "comma-number";
import { toast } from "react-toastify";

const ProfileContract = () => {
    const farmer_id = useParams().id

    const [farmer, setFarmer] = useState([]);
    const [farmerItemContent, setFarmerItemContent] = useState([]);
    const getFarmer = async () => {
        const { data } = await axios.get(`/profile/farmer/${farmer_id}`);
        setFarmer(data[0]);
        axios.get(`/production/contract-content/${farmer_id}`).then(res => {
            setFarmerItemContent(res.data);
        })
    };

    const [total, setotal] = useState([]);
    const getTotal = async () => {
        const { data } = await axios.get(`/profile/farming-total/${farmer_id}`);
        setotal(data)
    }

    const [detail, setDetail] = useState([]);
    const getDetails = async () => {
        const { data } = await axios.get(`/profile/farming-detail/${farmer_id}`);
        setDetail(data)
        if (data) {
            setTimeout(() => {
                if (window.$.fn.dataTable.isDataTable('.sameer')) {
                    window.$('.sameer').DataTable();
                } else {
                    window.$('.sameer').DataTable({
                        responsive: true,
                    })
                }
            }, 2000);
        }
    }

    const [returnProduct, setReturnProduct] = useState([]);
    const getReturnProduct = async () => {
        const { data } = await axios.get(`/profile/farming-return/${farmer_id}`);
        setReturnProduct(data);
    }

    const [production, setProduction] = useState([]);
    const getLogProduction = async () => {
        const { data } = await axios.get(`/profile-production/${farmer_id}`);
        setProduction(data);
    }

    const [production_payment, setProductionPayment] = useState([]);
    const getProductionPayment = async () => {
        const { data } = await axios.get(`/profile-production_payment/${farmer_id}`);
        setProductionPayment(data);
    }


    const [production_debt, setProductionDebt] = useState([]);
    const getProductionDebt = async () => {
        const { data } = await axios.get(`/profile-production_debt/${farmer_id}`);
        setProductionDebt(data);
    }


    const [profileTotal, setProfileTotal] = useState([]);
    const getProfileTotal = async () => {
        const { data } = await axios.get(`/profile-total/${farmer_id}`);
        setProfileTotal(data);
    }

    const [pacc, setpacc] = useState("");
    useEffect(() => {
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
        axios.get('/profile-access').then((res) => {
            setpacc(res.data)
        })
        getFarmer();
        getDetails();
        getTotal();
        getReturnProduct();
        getLogProduction();
        getProductionPayment();
        getProductionDebt();
        getProfileTotal();
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">دەستپێک</Link></li>
                    <li className="breadcrumb-item active" aria-curobj="page">پرۆفایلی جوتیار</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body dt-tbl">
                            <p className="text-muted text-center mb-4 pb-2">سەرژمێریی گشتی لەنێوان جوتیار {farmer.farmer_name} و کۆمپانیا.</p>
                            <div className="text-center">
                                <img src={`../../../${farmer.image ? farmer.image : 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22200%22%20height%3D%22200%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20200%20200%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_187c93e4aea%20text%20%7B%20fill%3Argba(255%2C255%2C255%2C.75)%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_187c93e4aea%22%3E%3Crect%20width%3D%22200%22%20height%3D%22200%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2274.4296875%22%20y%3D%22104.5%22%3E200x200%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E'}`} className="img-thumbnail mx-auto d-block mb-4" alt="profile" style={{ height: '205px' }} />
                                <h4 className="mb-2 mt-4">{farmer.full_name}</h4>
                                <h4 className="mb-3">{farmer.phone}</h4>
                            </div>
                            <div className="row mt-5 mt-4">
                                <h4 className="mt-3 mb-4">پوختە گرێبەستی تایبەت</h4>
                                <div className="col-md-12 mb-4">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-sm sameer" style={{ width: "100%" }}>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>بەرهەم</th>
                                                    <th>بڕی گرێبەست</th>
                                                    <th>بڕی وەرگیراو</th>
                                                    <th>ئەنجام</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {farmerItemContent.map((obj, x) => {
                                                    return (
                                                        <tr key={obj.id}>
                                                            <td>{x + 1}</td>
                                                            <td>{obj.item_name}</td>
                                                            <td>{commaNumber(obj.expect_qty)} طن</td>
                                                            <td>{commaNumber(obj.retur2company)} طن</td>
                                                            <td>{commaNumber(obj.balance)} طن</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-2">کۆی بڕی بەرهەمهاتوو</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="total_qty"> {commaNumber(farmerItemContent.reduce((total, item) => total + item.retur2company, 0).toFixed(3))} طن </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-2"> گرێبەست کراوە لەسەر بڕی</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="total_amount"> {commaNumber(farmerItemContent.reduce((total, item) => total + item.expect_qty, 0).toFixed(3))} طن </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-2">ئەنجامی گرێبەست</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="total_amount"> {commaNumber((farmerItemContent.reduce((total, item) => total + item.retur2company, 0) - farmerItemContent.reduce((total, item) => total + item.expect_qty, 0)).toFixed(3))} طن </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-5 mt-4">
                                <div className="col-md-12 mb-3 stretch-card grid-margin grid-margin-md-0">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="mt-3 mb-4">لیستی بەرهەمهاتووەکان</h4>
                                            <table className="table table-striped table-sm sameer" style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th>ژ.فاتورە</th>
                                                        <th>جوتیار</th>
                                                        <th>بەرهەم</th>
                                                        <th>جۆر</th>
                                                        <th>بۆ</th>
                                                        <th>بڕ</th>
                                                        <th>چاودێر</th>
                                                        <th>ژمێریار</th>
                                                        <th>وێنەی فەحس</th>
                                                        <th>ڕێککەوت</th>
                                                        <th>کات</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {returnProduct.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.invoice_no ? `ژ.${item.invoice_no}` : '-'}</td>
                                                            <td>{item.farmer_name}</td>
                                                            <td>{item.item_name}</td>
                                                            <td>{item.industrial == 0 ? 'استهلاکی' : 'صناعی'}</td>
                                                            <td>{item.to_farmer == 0 ? 'کۆمپانیا' : 'جوتیار'}</td>
                                                            <td>{commaNumber(item.qty)} طن</td>
                                                            <td>{item.chawder_name ? item.chawder_name : '-'}</td>
                                                            <td>{item.accountant_name}</td>
                                                            <td>{
                                                                item.attachment_scan ? (
                                                                    <a href={`../../../${item.attachment_scan}`} target="_blank">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="text-dark feather feather-paperclip"><path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" /></svg>
                                                                    </a>
                                                                ) : (
                                                                    <span className="badge bg-danger">نییە</span>
                                                                )
                                                            }
                                                            </td>
                                                            <td>{new Date(item.created).toLocaleDateString()} </td>
                                                            <td>{item.submit_date} </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-3 stretch-card grid-margin grid-margin-md-0">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="mt-3 mb-4">لیستی وەرگرتنی تۆو</h4>
                                            <table className="table table-striped table-sm sameer" style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>جۆر</th>
                                                        <th>قەبارە</th>
                                                        <th>بڕ</th>
                                                        <th>نرخی(کیلۆ)</th>
                                                        <th>کۆی گشتی</th>
                                                        <th>داشکاندن</th>
                                                        <th>پارەی واسل</th>
                                                        <th>پارەی ماوە</th>
                                                        <th>پشکی کۆمپانیا</th>
                                                        <th>پشکی جوتیار</th>
                                                        <th>چاودێر</th>
                                                        <th>دەستپێکردن</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {production && production.map((item) => (
                                                        <tr key={item.id}>
                                                            <td>{`ژ.${item.id}`}</td>
                                                            <td>{item.item_name}</td>
                                                            <td>{item.item_size}</td>
                                                            <td>{commaNumber(item.qty)} طن</td>
                                                            <td>{commaNumber((item.price))} {item.currency}</td>
                                                            <td>{commaNumber(item.total)} {item.currency}</td>
                                                            <td>{item.discount}</td>
                                                            <td>{commaNumber(item.amount_paid)} {item.currency}</td>
                                                            <td>{commaNumber(item.amount_unpaid)} {item.currency}</td>
                                                            <td>{item.company_rate}%</td>
                                                            <td>{item.farmer_rate}%</td>
                                                            <td>{item.chawder_name ? item.chawder_name : '-'}</td>
                                                            <td>{new Date(item.started_date).toLocaleDateString()}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-7 mb-3 stretch-card grid-margin grid-margin-md-0">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="mt-3 mb-4">لیستی پارەدانی وەرگرتنی تۆو</h4>
                                            <table className="table table-striped table-sm sameer" style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>بڕی واسل</th>
                                                        <th>لەلایەن</th>
                                                        <th>لە فاتورەی</th>
                                                        <th>جێبەجێکار</th>
                                                        <th>لەڕێککەوتی</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {production_payment.map((item, index) => (
                                                        <tr key={item.id}>
                                                            <td>{index + 1}</td>
                                                            <td>{commaNumber(item.amount_paid)} $</td>
                                                            <td>{item.farmer_name} </td>
                                                            <td>{`ژ.${item.production_header_id}`}</td>
                                                            <td>{item.accountant_name}</td>
                                                            <td>{new Date(item.created).toLocaleDateString()}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 mb-3 stretch-card grid-margin grid-margin-md-0">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="mt-3 mb-4">لیستی قەرزەکانی وەرگرتنی تۆو</h4>
                                            <table className="table table-striped table-sm sameer" style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th>فاتورە</th>
                                                        <th>بڕی قەرز</th>
                                                        <th>لە کۆی</th>
                                                        <th>واسل کراوە</th>
                                                        <th>جێبەجێکار</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {production_debt.map((item) => (
                                                        <tr key={item.id}>
                                                            <td>{`ژ.${item.production_header_id}`}</td>
                                                            <td>{commaNumber(item.amount_unpaid)} $</td>
                                                            <td>{commaNumber(item.total)} $</td>
                                                            <td>{commaNumber(item.amount_paid)} $</td>
                                                            <td>{item.accountant_name}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-5 mt-4">
                                <div className="col-md-3">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">کۆی گشتی </h5>
                                                    <span className="h4 font-weight-bold mb-0" id="total_amount"> {commaNumber(profileTotal.total_sale + profileTotal.total_production - farmer.forgiveness)} $ </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">واصل</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="total_amount"> {commaNumber(profileTotal.total_sale_paid + profileTotal.total_production_paid)} $ </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">ماوە (باقی)</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="amount_paid"> {commaNumber(profileTotal.total_sale_debt + profileTotal.total_production_debt - farmer.forgiveness)} $ </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">پارەی پێشوەختە</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="amount_unpaid"> {commaNumber(profileTotal.prepaid)} $ </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {pacc.role === "ژمێریار" &&
                                    <div className="col-md-6 mt-5">
                                        <div className="card card-stats mb-4 mb-xl-0">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="mb-3">
                                                            <label htmlFor="forgived_by" className="form-label">لەلایەن</label>
                                                            <input type="text" className="form-control" id="forgived_by" name="forgived_by" defaultValue={farmer.forgived_by} />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="forgiveness" className="form-label">بڕی لێخۆشبوون</label>
                                                            <input type="text" className="form-control" id="forgiveness" name="forgiveness" defaultValue={farmer.forgiveness} />
                                                        </div>
                                                        <div className="mb-3">
                                                            <button type="submit" className="btn btn-primary"
                                                                onClick={() => {
                                                                    axios.put(`/forgive/farmer/${farmer.id}`, {
                                                                        forgiveness: document.getElementById('forgiveness').value,
                                                                        forgived_by: document.getElementById('forgived_by').value
                                                                    }).then((res) => {
                                                                        if (res.data !== 'done') {
                                                                            toast.error('هەڵەیەک هەیە');
                                                                        } else {
                                                                            window.location.reload()
                                                                        }
                                                                    })
                                                                }} >پاشەکەوتکردن</button>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="col text-center">
                                                            <h5 className="card-title text-uppercase text-muted mb-1">کۆی گشتی</h5>
                                                            <span className="h4 font-weight-bold mb-0" id="total_qty"> {commaNumber(profileTotal.total_sale + profileTotal.total_production)}$ </span>
                                                        </div>
                                                        <div className="col text-center mt-4">
                                                            <h5 className="card-title text-uppercase text-muted mb-1">لێخۆشبوون</h5>
                                                            <span className="h4 font-weight-bold mb-0" id="total_qty"> {commaNumber(farmer.forgiveness)}$ </span>
                                                            <h6 className="card-title text-uppercase text-muted mb-1">لەلایەن: {farmer.forgived_by ? farmer.forgived_by : '-'}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                        <div className="container-fluid mt-5 mb-5 w-100 text-end">
                            <button className="btn btn-primary" onClick={() => { window.print() }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-printer"><polyline points="6 9 6 2 18 2 18 9" /><path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2" /><rect x={6} y={14} width={12} height={8} /></svg>
                                <span className="mx-2">چاپکردن</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ProfileContract;