import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link, useParams } from "react-router-dom";
import commaNumber from "comma-number";

const ReturnListBy = () => {
    const returnHeader_id = useParams().id


    const [returnHeader, setReturnHeader] = useState([])
    const getReturnHeader = async () => {
        const { data } = await axios.get(`/return/headerHiD/${returnHeader_id}`)
        setReturnHeader(data[0])
    }

    const [returnContent, setReturnContent] = useState([]);
    const getReturnContent = async () => {
        const { data } = await axios.get(`/return/listHid/${returnHeader_id}`);
        setReturnContent(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [0]
                                },
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [2]
                                },
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [3]
                                },

                            ]
                        })
                        $('div.dataTables_wrapper div.dataTables_filter').css({
                            'float': 'left',
                            'position': 'relative',
                            'top': '-15px'
                        })
                        $('div.dataTables_wrapper div.dataTables_length label').css({
                            'position': 'relative',
                            'top': '20px'
                        })
                    }

                    var api = window.$('.dt-tbl').DataTable();
                    new window.$.fn.dataTable.FixedHeader(api);
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    };

    useEffect(() => {
        getReturnHeader();
        getReturnContent();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">دەستپێک</Link></li>
                    <li className="breadcrumb-item active" aria-curobj="page">گرێبەست</li>
                </ol>
            </nav>
            <div className="row inbox-wrapper">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-3 border-end-lg">
                                    <div className="aside-content">
                                        <div className="email-aside-nav collapse">
                                            <p className="text-muted tx-12 fw-bolder text-uppercase mb-2 mt-4"> گرێبەستی بەرهەمهێنان</p>
                                            <ul className="nav flex-column">
                                                <li className="nav-item">
                                                    <p className="nav-link d-flex align-items-center"> <strong>بەرهەم:</strong> <span>&nbsp; {returnHeader.item_name}</span>
                                                    </p>
                                                </li>
                                                <li className="nav-item">
                                                    <p className="nav-link d-flex align-items-center"> <strong>ژ.فاتورە:</strong> <span>&nbsp; {returnHeader.invoice_no}</span>
                                                    </p>
                                                </li>
                                                <li className="nav-item">
                                                    <p className="nav-link d-flex align-items-center"> <strong>جۆر:</strong> <span>&nbsp; {returnHeader.industrial == '0' ? 'استهلاکی' : 'صناعی'}</span>
                                                    </p>
                                                </li>
                                                <li className="nav-item">
                                                    <p className="nav-link d-flex align-items-center"> <strong>جوتیار:</strong> <span>&nbsp; {returnHeader.farmer_name}</span>
                                                    </p>
                                                </li>
                                                <li className="nav-item">
                                                    <p className="nav-link d-flex align-items-center"> <strong>بڕی بەرهەمهاتوو:</strong> <span>&nbsp; {commaNumber(returnHeader.qty)} طن</span>
                                                    </p>
                                                </li>
                                                <li className="nav-item">
                                                    <p className="nav-link d-flex align-items-center"> <strong>پشکی کۆمپانیا:</strong> <span>&nbsp; {commaNumber(returnHeader.company_qty)} طن - ({returnHeader.company_rate}%)</span>
                                                    </p>
                                                </li>
                                                <li className="nav-item">
                                                    <p className="nav-link d-flex align-items-center"> <strong>پشکی جوتیار:</strong> <span>&nbsp; {commaNumber(returnHeader.farmer_qty)} طن - ({returnHeader.farmer_rate}%)</span>
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <div className="container py-4">
                                        <table className="table">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>
                                                        <div className="skeleton_wave" />
                                                    </th>
                                                    <th>
                                                        <div className="skeleton_wave" /> </th>
                                                    <th><div className="skeleton_wave" /></th>
                                                    <th><div className="skeleton_wave" /></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row"><div className="skeleton_wave rounded" /></th>
                                                    <td><div className="skeleton_wave rounded" /></td>
                                                    <td><div className="skeleton_wave rounded" /></td>
                                                    <td><div className="skeleton_wave rounded" /></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"><div className="skeleton_wave rounded" /></th>
                                                    <td><div className="skeleton_wave rounded" /></td>
                                                    <td><div className="skeleton_wave rounded" /></td>
                                                    <td><div className="skeleton_wave rounded" /></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"><div className="skeleton_wave rounded" /></th>
                                                    <td><div className="skeleton_wave rounded" /></td>
                                                    <td><div className="skeleton_wave rounded" /></td>
                                                    <td><div className="skeleton_wave rounded" /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <table className="table table-striped table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                        <thead>
                                            <tr>
                                                <th>پشک</th>
                                                <th>بڕ</th>
                                                <th>جۆر</th>
                                                <th>دەرەجە</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {returnContent.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.to_farmer == 0 ? 'کۆمپانیا' : 'جوتیار'}</td>
                                                    <td>{commaNumber(item.qty)} طن</td>
                                                    <td>{item.item_type}</td>
                                                    <td>{item.item_point == 0 ? '-' : item.item_point}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReturnListBy;