import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link, useParams } from "react-router-dom";
import commaNumber from "comma-number";

const ExpenseByFarmer = () => {
    const production_id = useParams().id

    const [Expenses, setExpenses] = useState([]);
    const getExpenses = async () => {
        const { data } = await axios.get(`/production/expenses/${production_id}`);
        setExpenses(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true
                        })
                    }
                    var api = window.$('.dt-tbl').DataTable();
                    new window.$.fn.dataTable.FixedHeader(api);
                }
            });
        }

    };

    const [farmerDetails, setFarmerDetails] = useState([]);
    const [amount, setAmount] = useState(0);
    const getFatmerDetails = async () => {
        const res = await axios.get(`/production/expenses/total/${production_id}`);
        setFarmerDetails(
            <div className="py-3 px-3">
                <span className="card-title text-uppercase text-muted mb-1">جوتیار:</span><span className="h5 font-weight-bold"> {res.data[0].farmer_name}</span><br />
                <span className="card-title text-uppercase text-muted mb-1">ژ. مۆبایل:</span><span className="h5 font-weight-bold"> {res.data[0].farmer_phone}</span><br />
                <span className="card-title text-uppercase text-muted mb-1">ناونیشان:</span><span className="h5 font-weight-bold"> {res.data[0].farmer_address}</span>

            </div>
        )
        var usd = 0, iqd = 0;
        res.data.forEach(i => { if (i.currency.includes('$')) { usd += i.amount; } else { iqd += i.amount; } })
        setAmount({ usd, iqd })
    };

    useEffect(() => {
        getExpenses();
        getFatmerDetails();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
        $('.py-4').hide();
        $('.dt-tbl').show();
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">دەستپێک</Link></li>
                    <li className="breadcrumb-item active" aria-curobj="page">خەرجی</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-header">
                            {farmerDetails}
                        </div>
                        <div className="card-body">
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>ناونیشان</th>
                                        <th>وردەکاریی</th>
                                        <th>بڕی خەرجی</th>
                                        <th>هاوپێچ</th>
                                        <th>جێبەجێکار</th>
                                        <th>تۆماربوون</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Expenses.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.title}</td>
                                            <td>{item.description ? <div className="text-wrap" style={{ maxWidth: "300px" }}>{item.description}</div> : '-'}</td>
                                            <td>{commaNumber(item.amount)} {item.currency}</td>
                                            <td>{
                                                item.attachment ? (
                                                    <a href={`../../../${item.attachment}`} target="_blank">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="text-dark feather feather-paperclip"><path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" /></svg>
                                                    </a>
                                                ) : (
                                                    <span className="badge bg-danger">نییە</span>
                                                )
                                            }
                                            </td>
                                            <td>{item.accountant_name}</td>
                                            <td>{new Date(item.updated).toLocaleDateString()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className='card-footer'>
                            <div className="row d-flex justify-content-center py-4">
                                <div className="col-md-4 text-center">
                                    <h5 className="card-title text-uppercase text-muted mb-1">دیناری عێراقی</h5>
                                    <span className="h4 font-weight-bold">{commaNumber(amount.iqd)} د،ع</span>
                                </div>
                                <div className="col-md-4 text-center">
                                    <h5 className="card-title text-uppercase text-muted mb-1">دۆلاری ئەمریکی</h5>
                                    <span className="h4 font-weight-bold">{commaNumber(amount.usd)} $ </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExpenseByFarmer;