import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from 'react-select'
import commaNumber from "comma-number";

const ProductionList = () => {
    const navigate = useNavigate();
    const [stores, setStores] = useState([]);
    const getStores = async () => {
        const { data } = await axios.get("/store");
        setStores(data);
    };
    const [currencies, setCurrencies] = useState([]);
    const getCurrencies = async () => {
        const { data } = await axios.get("/currency");
        setCurrencies(data);
    };
    const [farmers, setFarmers] = useState([]);
    const getFarmers = async () => {
        const res = await axios.get("/farmer");
        setFarmers(res.data);
    };

    const upload = () => {
        $('<input type="file" accept="image/*" />').click().on('change', async (e) => {
            var formData = new FormData();
            formData.append('file', e.target.files[0]);
            await axios.post('/upload', formData).then(res => {
                if (res.data !== "err") {
                    $("#attachment").val(res.data);
                    $(`#upattachment`).append(`<img src="../../../${res.data}" class="img-thumbnail viewImg" alt=${res.data} />`)
                }
                if (res.data === "limited") {
                    toast.error('قەبارەی رسمەکە زۆر گەورەیە پێویستە لە 700 کێلۆ بایت کەمتربێت!')
                    return
                }
                $('.viewImg').click(function () {
                    $(this).remove()
                    axios.delete(`/upload/${$(this).attr('alt')}`).then(res => {
                        if (res.data !== "err") {
                            $("#attachment").val('');
                        } else {
                            toast.error('هەڵەیەک هەیە');
                        }
                    })
                })
            })
        })
    }

    const upload2 = () => {
        $('<input type="file" accept="image/*" />').click().on('change', async (e) => {
            var formData = new FormData();
            formData.append('file', e.target.files[0]);
            await axios.post('/upload', formData).then(res => {
                if (res.data !== "err") {
                    $("#attachment2").val(res.data);
                    $(`#upattachment2`).append(`<img src="../../../${res.data}" class="img-thumbnail viewImg2" alt=${res.data} />`)
                }
                if (res.data === "limited") {
                    toast.error('قەبارەی رسمەکە زۆر گەورەیە پێویستە لە 700 کێلۆ بایت کەمتربێت!')
                    return
                }
                $('.viewImg2').click(function () {
                    $(this).remove()
                    axios.delete(`/upload/${$(this).attr('alt')}`).then(res => {
                        if (res.data !== "err") {
                            $("#attachment2").val('');
                        } else {
                            toast.error('هەڵەیەک هەیە');
                        }
                    })
                })
            })
        })
    }

    const uploadx = () => {
        $('<input type="file" accept="image/*" />').click().on('change', async (e) => {
            var formData = new FormData();
            formData.append('file', e.target.files[0]);
            await axios.post('/upload', formData).then(res => {
                console.log(res.data);
                if (res.data !== "err") {
                    $("#attachmentx").val(res.data);
                    $(`#upattachmentx`).append(`<img src="../../../${res.data}" class="viewImgx" alt=${res.data} />`)
                }
                if (res.data === "limited") {
                    toast.error('قەبارەی رسمەکە زۆر گەورەیە پێویستە لە 700 کێلۆ بایت کەمتربێت!')
                    return
                }
                $('.viewImgx').click(function () {
                    $(this).remove()
                    axios.delete(`/upload/${$(this).attr('alt')}`).then(res => {
                        if (res.data !== "err") {
                            $("#attachment").val('');
                        } else {
                            toast.error('هەڵەیەک هەیە');
                        }
                    })
                })
            })
        })
    }

    const [productions, setProductions] = useState([]);
    const getProductions = async () => {
        const { data } = await axios.get("/productions/list");
        setProductions(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [1]
                                },
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [2]
                                },
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [3]
                                },
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [10]
                                },
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [11]
                                },
                                {
                                    searchPanes: {
                                        show: false
                                    },
                                    targets: [12]
                                },
                                {
                                    searchPanes: {
                                        show: false
                                    },
                                    targets: [13]
                                },
                                {
                                    searchPanes: {
                                        show: false
                                    },
                                    targets: [14]
                                },
                            ]
                        })
                        $('div.dataTables_wrapper div.dataTables_filter').css({
                            'float': 'left',
                            'position': 'relative',
                            'top': '-15px'
                        })
                        $('div.dataTables_wrapper div.dataTables_length label').css({
                            'position': 'relative',
                            'top': '20px'
                        })
                    }
                    axios.get("/auth").then((res) => {
                        if (res.data !== "err") {
                            if (res.data == "چاودێر") {
                                $(".auth").remove();
                            }
                        }
                    });
                    var api = window.$('.dt-tbl').DataTable();
                    var total_qty = 0;
                    var total_amount = 0;
                    var amount_paid = 0;
                    var amount_unpaid = 0;
                    new window.$.fn.dataTable.FixedHeader(api);

                    var totalqty = api.column(4, { search: 'applied' }).data()
                    totalqty.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_qty += parseFloat(item) })

                    var totalamount = api.column(6, { search: 'applied' }).data()
                    totalamount.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_amount += parseFloat(item) })

                    var amountpaid = api.column(8, { search: 'applied' }).data()
                    amountpaid.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } amount_paid += parseFloat(item) })

                    var amountunpaid = api.column(9, { search: 'applied' }).data()
                    amountunpaid.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } amount_unpaid += parseFloat(item) })

                    $('#total_qty').html(`${commaNumber(total_qty.toFixed(3))} طن`);
                    $('#total_amount').html(`${commaNumber(total_amount.toFixed(3))} $`);
                    $('#amount_paid').html(`${commaNumber(amount_paid.toFixed(3))} $`);
                    $('#amount_unpaid').html(`${commaNumber(amount_unpaid.toFixed(3))} $`);

                    window.$('.dt-tbl').DataTable().on('search.dt', function () {
                        total_qty = 0;
                        total_amount = 0;
                        amount_paid = 0;
                        amount_unpaid = 0;

                        var totalqty = api.column(4, { search: 'applied' }).data()
                        totalqty.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_qty += parseFloat(item) })

                        var totalamount = api.column(6, { search: 'applied' }).data()
                        totalamount.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } total_amount += parseFloat(item) })

                        var amountpaid = api.column(8, { search: 'applied' }).data()
                        amountpaid.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } amount_paid += parseFloat(item) })

                        var amountunpaid = api.column(9, { search: 'applied' }).data()
                        amountunpaid.map((item) => { if (isNaN(item)) { item = item.replace(/,/g, ''); } amount_unpaid += parseFloat(item) })

                        $('#total_qty').html(`${commaNumber(total_qty.toFixed(3))} طن`);
                        $('#total_amount').html(`${commaNumber(total_amount.toFixed(3))} $`);
                        $('#amount_paid').html(`${commaNumber(amount_paid.toFixed(3))} $`);
                        $('#amount_unpaid').html(`${commaNumber(amount_unpaid.toFixed(3))} $`);
                    });
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    };
    const [auth, setAuth] = useState(false);
    useEffect(() => {
        axios.get("/auth").then((res) => {
            if (res.data !== "err") {
                if (res.data == "چاودێر") {
                    $(".auth").remove();
                    setAuth(false);
                } else {
                    setAuth(true);
                }
            }
        });
        getStores();
        getFarmers();
        getProductions();
        getCurrencies();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);
    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">دەستپێک</Link></li>
                    <li className="breadcrumb-item active" aria-curobj="page">چاندن</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">لیستی چاندن</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end auth">
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#production">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus"><line x1={12} y1={5} x2={12} y2={19} /><line x1={5} y1={12} x2={19} y2={12} /></svg>
                                        <span className="ms-2">پێدانی پەتاتە</span>
                                    </button>
                                    <div className="modal fade" id="production" tabIndex={-1} aria-labelledby="production" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title"> پێدانی پەتاتە </h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                </div>
                                                <form className="form-sample" onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post("/production/header", data).then(res => {
                                                        if (res.data !== 'err') {
                                                            window.$('#production').modal('hide');
                                                            localStorage.setItem("production", JSON.stringify({
                                                                production_id: res.data[0],
                                                            }));
                                                            navigate("/production/new/");
                                                        }
                                                    }).catch(err => {
                                                        console.log(err);
                                                    });
                                                }}>
                                                    <div className="modal-body">
                                                        <div className="mb-3">
                                                            <div className="form-group row">
                                                                <label htmlFor="farmer_id" className="col-12 form-label">جوتیار <span className="text-danger">*</span></label>
                                                                <div className="col-12">
                                                                    <Select options={farmers && farmers.map((item) => (
                                                                        { value: item.id, label: item.full_name }
                                                                    ))
                                                                    } name="farmer_id" width="100%" placeholder="جوتیار دیار بکە" required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <div className="form-group row">
                                                                <label htmlFor="store_id" className="col-12 form-label">کۆگا دیار بکە <span className="text-danger">*</span></label>
                                                                <div className="col-12">
                                                                    <Select options={stores && stores.map((item) => (
                                                                        { value: item.id, label: item.store_name }
                                                                    ))
                                                                    } name="store_id" width="100%" placeholder="کۆگا دیار بکە" required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <div className="form-group row">
                                                                <label htmlFor="start_date" className="col-12 form-label">ڕێککەوتی دەستپێکردن <span className="text-danger">*</span></label>
                                                                <div className="col-12">
                                                                    <input type="date" className="form-control" name="started_date" defaultValue={new Date().toISOString().slice(0, 10)} required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label> هاوپێچ</label>
                                                            <input type="hidden" name="attachment" id="attachment" />
                                                            <div className="form-control text-center" onClick={upload}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                                                            </div>
                                                            <br />
                                                            <div align="center" id="upattachment"></div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                        <button type="submit" className="btn btn-sm btn-dark">
                                                            <span className="ms-2">بەردەوامبوون</span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-left"><line x1={19} y1={12} x2={5} y2={12} /><polyline points="12 19 5 12 12 5" /></svg>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>جوتیار</th>
                                        <th>جۆر</th>
                                        <th>قەبارە</th>
                                        <th>بڕ</th>
                                        <th>نرخی(کیلۆ)</th>
                                        <th>کۆی گشتی</th>
                                        <th>داشکاندن</th>
                                        <th>پارەی واسل</th>
                                        <th>پارەی ماوە</th>
                                        <th>پشکی کۆمپانیا</th>
                                        <th>پشکی جوتیار</th>
                                        <th>چاودێر</th>
                                        <th>ژمێریار</th>
                                        <th>دەستپێکردن</th>
                                        {auth &&
                                            <th className="auth">بژاردە</th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {productions && productions.map((item) => (
                                        <tr key={item.id}>
                                            <td>{`ژ.${item.id}`}</td>
                                            <td><Link to={`/profile/farmer/${item.farmer_id}`} >{item.farmer_name}</Link></td>
                                            <td>{item.item_name}</td>
                                            <td>{item.item_size}</td>
                                            <td>{commaNumber(item.qty)} طن</td>
                                            <td>{commaNumber((item.price))} {item.currency}</td>
                                            <td>{commaNumber(item.total)} {item.currency}</td>
                                            <td>{item.discount}</td>
                                            <td>{commaNumber(item.amount_paid)} {item.currency}</td>
                                            <td>{commaNumber(item.amount_unpaid)} {item.currency}</td>
                                            <td>{item.company_rate}%</td>
                                            <td>{item.farmer_rate}%</td>
                                            <td>{item.chawder_name ? item.chawder_name : '-'}</td>
                                            <td>{item.accountant_name}</td>
                                            <td>{new Date(item.started_date).toLocaleDateString()}</td>
                                            {auth &&
                                                <td className="auth">
                                                    <div className="dropdown">
                                                        <button className="btn btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={21} height={21} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-more-vertical"><circle cx={12} cy={12} r={1} /><circle cx={12} cy={5} r={1} /><circle cx={12} cy={19} r={1} /></svg>
                                                        </button>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <Link to={`/production/edit/${item.id}`} className="dropdown-item">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                                                                <span className="mx-2">دەستکاریکردن</span>
                                                            </Link>
                                                            {item.amount_unpaid > 0 &&
                                                                <span type="button" data-bs-toggle="modal" data-bs-target={`#paymentModal${item.id}`} className="dropdown-item">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-credit-card"><rect x={1} y={4} width={22} height={16} rx={2} ry={2} /><line x1={1} y1={10} x2={23} y2={10} /></svg>
                                                                    <span className="mx-2">پارەدان</span>
                                                                </span>
                                                            }
                                                            <Link to={`/production/invoice/${item.id}`} target={'_blank'} className="dropdown-item">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-printer"><polyline points="6 9 6 2 18 2 18 9" /><path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2" /><rect x={6} y={14} width={12} height={8} /></svg>
                                                                <span className="mx-3">چاپکردن</span>
                                                            </Link>
                                                            <span type="button" data-bs-toggle="modal" data-bs-target={`#expenses${item.id}`} className="dropdown-item">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-square"><polyline points="9 11 12 14 22 4" /><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11" /></svg>
                                                                <span className="mx-3">زیادکردنی خەرجی</span>
                                                            </span>
                                                            <Link to={`/production/expense/${item.id}`} className="dropdown-item">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" /><polyline points="14 2 14 8 20 8" /><line x1={16} y1={13} x2={8} y2={13} /><line x1={16} y1={17} x2={8} y2={17} /><polyline points="10 9 9 9 8 9" /></svg>
                                                                <span className="mx-2">لیستی خەرجیەکان</span>
                                                            </Link>
                                                            <span type="button" data-bs-toggle="modal" data-bs-target={`#return${item.id}`} className="dropdown-item">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" /><polyline points="7 10 12 15 17 10" /><line x1={12} y1={15} x2={12} y2={3} /></svg>
                                                                <span className="mx-2">وەرگرتنەوەی بەرهەم</span>
                                                            </span>
                                                            <Link to={`/return/status/${item.id}`} className="dropdown-item">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-percent"><line x1={19} y1={5} x2={5} y2={19} /><circle cx="6.5" cy="6.5" r="2.5" /><circle cx="17.5" cy="17.5" r="2.5" /></svg>
                                                                <span className="mx-2">بەرهەمە وەرگیراوەکان</span>
                                                            </Link>
                                                            <span type="button" data-bs-toggle="modal" data-bs-target={`#viewModal${item.id}`} className="dropdown-item">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-maximize-2"><polyline points="15 3 21 3 21 9" /><polyline points="9 21 3 21 3 15" /><line x1={21} y1={3} x2={14} y2={10} /><line x1={3} y1={21} x2={10} y2={14} /></svg>
                                                                <span className="mx-2">وردەکاریی زیارتر</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                            }
                                            {auth &&
                                                <>
                                                    <div className="modal fade auth" id={`paymentModal${item.id}`} tabIndex={-1} aria-labelledby="payment" aria-hidden="true">
                                                        <div className="modal-dialog modal-md">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title"> پارەدان </h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                                </div>
                                                                <form className="form-sample" onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    const formData = new FormData(e.target);
                                                                    formData.append('accountant_id', item.accountant_id);
                                                                    const data = Object.fromEntries(formData);
                                                                    axios.post(`/payment/production/${item.id}`, data).then(res => {
                                                                        if (res.data !== 'done') {
                                                                            toast.error(res.data, {
                                                                                position: "top-left",
                                                                                autoClose: 3000,
                                                                                hideProgressBar: false,
                                                                                closeOnClick: true,
                                                                                pauseOnHover: true,
                                                                                draggable: true,
                                                                                progress: undefined,
                                                                            });
                                                                        } else {
                                                                            window.$(`#paymentModal${item.id}`).modal('hide')
                                                                            e.target.reset();
                                                                            getProductions();
                                                                        }
                                                                    });
                                                                }}>
                                                                    <div className="modal-body">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="unpaid" className="col-form-label">پارەی ماوە:</label>
                                                                            <input type="number" className="form-control" id={`unpaid${item.id}`} defaultValue={item.amount_unpaid} disabled />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label htmlFor="amount_paid" className="col-form-label">بڕی پارەدان:</label>
                                                                            <input type="number" className="form-control" name="amount_paid" id={`paid${item.id}`} defaultValue={0} onChange={(e) => {
                                                                                if (e.target.value > item.amount_unpaid) {
                                                                                    $(`#paid${item.id}`).val(item.amount_unpaid);
                                                                                    $(`#unpaid${item.id}`).val(0);
                                                                                } else {
                                                                                    $(`#unpaid${item.id}`).val(item.amount_unpaid - e.target.value);
                                                                                }
                                                                            }} required />
                                                                        </div>
                                                                        <div className="modal-footer">
                                                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                                            <button type="submit" className="btn btn-primary">واسل کردن</button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal fade auth" id={`viewModal${item.id}`} tabIndex={-1} aria-labelledby="viewModal" aria-hidden="true">
                                                        <div className="modal-dialog modal-md">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title"> وردەکاریی بەرهەمهێنان </h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                                </div>
                                                                <div className="modal-body">
                                                                    <table className="table table-striped table-sm" style={{ width: "100%" }}>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>جوتیار</th>
                                                                                <td>{item.farmer_name}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>ژ.م. جوتیار</th>
                                                                                <td>{item.farmer_phone}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>بڕ</th>
                                                                                <td>{commaNumber(item.qty)} طن</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>نرخ</th>
                                                                                <td>{commaNumber((item.price))} {item.currency}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>کۆی گشتی</th>
                                                                                <td>{commaNumber(item.total)} {item.currency}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>داشکاندن</th>
                                                                                <td>{item.discount}%</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>پارەی واسل</th>
                                                                                <td>{commaNumber(item.amount_paid)} {item.currency}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>پارەی ماوە</th>
                                                                                <td>{commaNumber(item.amount_unpaid)} {item.currency}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>کۆگا</th>
                                                                                <td>{item.store_name}, {item.store_address}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>پشکی کۆمپانیا</th>
                                                                                <td>{item.company_rate}%</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>پشکی جوتیار</th>
                                                                                <td>{item.farmer_rate}%</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>تێبینی</th>
                                                                                <td>
                                                                                    <div className="text-wrap" style={{ maxWidth: "300px" }}>
                                                                                        {item.note ? item.note : '-'}
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>هاوپێچ</th>
                                                                                <td>{
                                                                                    item.attachment ? (
                                                                                        <a href={`../../../${item.attachment}`} target="_blank">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="text-dark feather feather-paperclip"><path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" /></svg>
                                                                                        </a>
                                                                                    ) : (
                                                                                        <span className="badge bg-danger">نییە</span>
                                                                                    )
                                                                                }
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>تۆماربوون</th>
                                                                                <td>{new Date(item.updated).toLocaleDateString()}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>ژمێریار</th>
                                                                                <td>{item.accountant_name}</td>
                                                                            </tr>
                                                                        </thead>
                                                                    </table>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal fade auth" id={`expenses${item.id}`} tabIndex={-1} aria-labelledby="expenses" aria-hidden="true">
                                                        <div className="modal-dialog modal-lg">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title"> زیادکردنی خەرجی </h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                                </div>
                                                                <div className="modal-body">
                                                                    <form className="form-sample" onSubmit={(e) => {
                                                                        e.preventDefault();
                                                                        const formData = new FormData(e.target);
                                                                        formData.append('farmer_id', item.farmer_id);
                                                                        formData.append('accountant_id', item.accountant_id);
                                                                        const data = Object.fromEntries(formData);
                                                                        axios.post(`/production/expenses/${item.id}`, data).then(res => {
                                                                            if (res.data !== 'done') {
                                                                                toast.error(res.data, {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            } else {
                                                                                window.$(`#expenses${item.id}`).modal('hide')
                                                                                e.target.reset();
                                                                            }
                                                                        });
                                                                    }}>
                                                                        <div className="mb-3">
                                                                            <label htmlFor="title" className="col-form-label">سەردێر:</label>
                                                                            <input type="text" className="form-control" name="title" id="title" required />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label htmlFor="description" className="col-form-label">وردەکاریی:</label>
                                                                            <textarea className="form-control" name="description" id="description" rows={3} required />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label htmlFor="amount" className="col-form-label">بڕی پارە:</label>
                                                                            <input type="number" className="form-control" name="amount" id="amount" required />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <div className="form-group row">
                                                                                <label htmlFor="currency_id" className="col-12 form-label">جۆری دراو</label>
                                                                                <div className="col-12">
                                                                                    <Select options={currencies && currencies.map((item) => (
                                                                                        { value: item.id, label: item.currency }
                                                                                    ))
                                                                                    } name="currency_id" id="currency" width="100%" placeholder="جۆری دراو دیار بکە" required />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label> هاوپێچ</label>
                                                                            <input type="hidden" name="attachment" id="attachmentx" />
                                                                            <div className="form-control text-center" onClick={uploadx}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                                                                            </div>
                                                                            <br />
                                                                            <div align="center" id="upattachmentx"></div>
                                                                        </div>
                                                                        <div className="modal-footer">
                                                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                                            <button type="submit" className="btn btn-primary">جێبەجێکردن</button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal fade auth" id={`return${item.id}`} tabIndex={-1} aria-labelledby="return" aria-hidden="true">
                                                        <div className="modal-dialog modal-lg">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title">  وەرگرتنەوەی بەرهەم </h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                                </div>
                                                                <div className="modal-body">
                                                                    <form className="form-sample" onSubmit={(e) => {
                                                                        e.preventDefault();
                                                                        const formData = new FormData(e.target);
                                                                        const data = Object.fromEntries(formData);
                                                                        axios.post(`/return/header/${item.id}`, data).then(res => {
                                                                            window.$(`#return${item.id}`).modal('hide')
                                                                            e.target.reset();
                                                                            navigate(`/return/new/${res.data}`);
                                                                        });
                                                                    }}>
                                                                        <div className="mb-3">
                                                                            <label htmlFor="industrial" className="col-form-label">استهلاکی/صناعی؟</label>
                                                                            <select className="form-select" name="industrial" required>
                                                                                <option value="0">استهلاکی</option>
                                                                                <option value="1">صناعی</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label htmlFor="industrial" className="col-form-label">بۆ: کۆمپانیا/جوتیار؟</label>
                                                                            <select name="to_farmer" className="form-select" required>
                                                                                <option value={0}>کۆمپانیا</option>
                                                                                <option value={1}>جوتیار</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label htmlFor="item_type" className="col-form-label">خشن/ناعم؟</label>
                                                                            <select name="item_type" className="form-select" required >
                                                                                <option value="خشن">خشن</option>
                                                                                <option value="ناعم">ناعم</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label htmlFor="sack">ژمارەی کیس ؟</label>
                                                                            <input type="number" className="form-control" name="sack" placeholder="چەند کیسە ؟" />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <input type="hidden" name="item_id" defaultValue={item.item_id} />
                                                                            <label htmlFor="invoice_no">ژ. فاتورە</label>
                                                                            <input className="form-control" name="invoice_no" placeholder="ژمارەی فاتورە ؟" />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label htmlFor="vehicle_type">جۆری بارهەلگر</label>
                                                                            <input className="form-control" name="vehicle_type" placeholder="جۆری بارهەلگر ؟" />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label htmlFor="plate_number">ژ. بارهەلگر</label>
                                                                            <input className="form-control" name="plate_number" placeholder="ژمارەی بارهەلگر ؟" />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label htmlFor="driver_name">ناوی شوفێر</label>
                                                                            <input className="form-control" name="driver_name" placeholder="ناوی شوفێر ؟" />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label htmlFor="driver_phone">ژ. شوفێر</label>
                                                                            <input className="form-control" name="driver_phone" placeholder="ژ.مۆبایلی شوفێر ؟" />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label> هاوپێچ</label>
                                                                            <input type="hidden" name="attachment" id="attachment2" />
                                                                            <div className="form-control text-center" onClick={upload2}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                                                                            </div>
                                                                            <br />
                                                                            <div align="center" id="upattachment2"></div>
                                                                        </div>
                                                                        <div className="modal-footer">
                                                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                                            <button type="submit" className="btn btn-primary">جێبەجێکردن</button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="row mt-4">
                                <div className="col-md-3">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">بڕی گشتی</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="total_qty"> 0 طن </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">کۆی گشتی</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="total_amount"> 0 $ </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">واسلەکان</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="amount_paid"> 0 $ </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card card-stats mb-4 mb-xl-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h5 className="card-title text-uppercase text-muted mb-1">قەرزەکان</h5>
                                                    <span className="h4 font-weight-bold mb-0" id="amount_unpaid"> 0 $ </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default ProductionList;
