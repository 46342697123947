import React, { useEffect, useState } from "react";
import axios from "axios";
import commaNumber from 'comma-number';
import $ from "jquery";
import { Link, useParams } from "react-router-dom";

const OrderInvoice = () => {
    const order_id = useParams().id

    const [orderHeader, setOrderHeader] = useState(0);
    const getOrderHeader = async () => {
        const { data } = await axios.get(`/orders/list/${order_id}`);
        setOrderHeader(data[0]);
    };

    const [orderContent, setOrderContent] = useState([]);
    const getOrderContent = async () => {
        const res = await axios.get(`/order/content/${order_id}`);
        setOrderContent(res.data);
    };

    const [orderTotal, setOrderTotal] = useState(0);
    const getOrderTotal = async () => {
        const res = await axios.get(`/order/total/${order_id}`);
        setOrderTotal(res.data[0]);
    };

    useEffect(() => {
        getOrderHeader();
        getOrderContent();
        getOrderTotal();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
        $('.py-4').hide();
        $('.dt-tbl').show();
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">دەستپێک</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">فاتورە</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body dt-tbl">
                            <div className="container-fluid d-flex justify-content-between">
                                <div className="col-lg-6 ps-0">
                                    <img src={`../../../assets/img/logo.png`} className="logo" alt="invoice" />
                                    <p className="mt-1 mb-1 tx-13"><b> بۆ بازرگانی و وەبەرهێنانی کشتوکاڵی</b></p>
                                    <p>ناونیشان: عێراق، دهۆک، بامەرزێ</p>
                                    <h5 className="mt-5 mb-2 text-muted">بازرگان/کۆمپانیا:</h5>
                                    <p>{orderHeader.vendor_name}،<br />{orderHeader.vendor_phone}.</p>
                                </div>
                                <div className="col-lg-6 pe-0">
                                    <h4 className="fw-bolder text-uppercase text-end mt-4 mb-2">فاتورە</h4>
                                    <h6 className="text-end mb-5 pb-4">ژم-{order_id}</h6>
                                    <p className="text-end mb-1">کۆی گشتی</p>
                                    <h4 className="text-end fw-normal">{orderTotal ? commaNumber(orderTotal.total) : 0} $</h4>
                                    <h6 className="mb-0 mt-3 text-end fw-normal mb-2"><span className="text-muted">تۆمارکراوە :</span> {new Date(orderHeader.updated).toLocaleDateString()}</h6>
                                </div>
                            </div>
                            <div className="container-fluid mt-5 d-flex justify-content-center w-100">
                                <div className="table-responsive w-100">
                                    <table className="table table-striped table-sm text-center">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>جۆر</th>
                                                <th>قەبارە</th>
                                                <th>نرخ</th>
                                                <th>پێوانە</th>
                                                <th>بڕ</th>
                                                <th>سەرجەم</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderContent.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.item_name}</td>
                                                    <td>{item.item_size}</td>
                                                    <td>{commaNumber(item.price)} $</td>
                                                    <td>{item.weight_type}</td>
                                                    <td>{commaNumber(item.qty)} طن</td>
                                                    <td>{commaNumber(item.total)} $</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="container-fluid mt-5 w-100">
                                <div className="row">
                                    <div className="col-md-6 ms-auto px-5 mt-4">
                                        <div className="table-responsive">
                                            <table className="table table-sm">
                                                <tbody>
                                                    <tr className="bg-dark text-white">
                                                        <td className="text-bold-800">سەرجەم</td>
                                                        <td className="text-bold-800 text-end"> {orderTotal ? commaNumber(orderTotal.total) : 0} $ </td>
                                                    </tr>
                                                    <tr>
                                                        <td> داشکاندن</td>
                                                        <td className="text-end">{orderHeader ? commaNumber(orderHeader.discount) : 0}%</td>
                                                    </tr>

                                                    <tr>
                                                        <td>بڕی واسل</td>
                                                        <td className="text-end">{orderHeader ? commaNumber(orderHeader.amount_paid) : 0} $</td>
                                                    </tr>
                                                    <tr>
                                                        <td>بڕی ماوە</td>
                                                        <td className="text-end">{orderHeader ? commaNumber(orderHeader.amount_unpaid) : 0} $</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid mt-5 mb-5 w-100 text-end">
                            <button className="btn btn-primary" onClick={() => { window.print() }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-printer"><polyline points="6 9 6 2 18 2 18 9" /><path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2" /><rect x={6} y={14} width={12} height={8} /></svg>
                                <span className="mx-2">چاپکردن</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default OrderInvoice;